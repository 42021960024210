import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb.component';
import { TranslationModule } from '../../../modules/translate.module';
import { ItemModule } from '../item/item.module';

@NgModule({
    declarations: [BreadcrumbComponent],
    imports: [CommonModule, RouterModule, TranslationModule, ItemModule],
    exports: [
        BreadcrumbComponent,
    ],
})
export class BreadcrumbModule {}
