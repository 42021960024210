import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { FormModule } from '../form/form.module';
import { TranslationModule } from '../../../modules/translate.module';
import { RouterModule } from '@angular/router';
import { ItemModule } from '../item/item.module';
import { PaginatorModule } from '../paginator/paginator.module';
import { FormsModule } from '@angular/forms';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';

@NgModule({
    declarations: [TableComponent],
    imports: [
        CommonModule,
        FormModule,
        FormsModule,
        TranslationModule,
        RouterModule,
        ItemModule,
        PaginatorModule,
        BreadcrumbModule,
    ],
    exports: [TableComponent],
})
export class TableModule {}
