import { Component } from '@angular/core';

@Component({
  selector: 'blocks-work-planner',
  templateUrl: './blocks-work-planner.component.html',
})
export class BlocksWorkPlannerComponent {

  constructor() { }
}
