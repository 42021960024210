import { Component } from '@angular/core';

@Component({
  selector: 'blocks-interaction',
  templateUrl: './blocks-interaction.component.html',
})
export class BlocksInteractionComponent {

  constructor() { }
}
