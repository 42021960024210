import { CmsService } from '../../services/cms.service';
import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.less'],
})
export class CmsComponent implements OnInit {
    // Set the groupId
    @Input('groupId') groupId;

    // Set the itemId
    @Input('itemId') itemId;

    public html: string;

    private loadTriggered: boolean = false;

    private cssLoadSub: Subscription = null;

    constructor(private cms: CmsService) {}

    async ngOnInit() {
        // first subscribe so the storage will be loaded when css is loaded
        this.cssLoadSub = this.cms.cssDoneLoading.subscribe(() => {
            if (!this.loadTriggered) this.loadStorage();
        });

        // make sure it will only be loaded 1 time
        if (!this.cms.cssLoading) {
            // first start loading in the stored CSS
            // this is nescessary coz u want all CSS to be loaded before any HTML wil be loaded. else html witout styling will be showed for a brief moment
            this.cms.cssLoading = true;
            await this.cms.loadStoredCss();
            this.cms.cssLoading = false;

            // notify the subscribed CMS components that the CSS is done loading. this wil trigger the loading of the js and html
            this.cms.cssDoneLoading.next(true);
        }
    }

    ngOnDestroy() {
        if (this.cssLoadSub) {
            this.cssLoadSub.unsubscribe();
        }
    }

    /**
     * loadStorage
     *
     * loads the JS and HTML that ar ein the storage of this group + item id combination
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      none
     * @returns    Promise<void>
     */
    private async loadStorage(): Promise<void> {
        // get the last response that whas received for thi groupId + itemId combination
        let storage = localStorage.getItem(btoa(this.groupId + '-' + this.itemId));

        this.loadTriggered = true;

        // if storage whas found, load in the js and html
        if (storage) {
            // stored like a encoded string, so parse back to object
            var res = JSON.parse(atob(storage));

            // Load JS files
            await this.cms.addJsLinks((<any>res).js, (<any>res).url);

            // load in the HTML
            this.html = (<any>res).html;
        }

        // now register the component so the latest version will be received and overwritten by de stored version
        this.cms.registerComponent(this.groupId, this.itemId, this);
    }
}
