import { Component } from '@angular/core';
import { SignatureService } from '../../../../core/services/signature.service';

@Component({
    selector: 'blocks-signature',
    templateUrl: './blocks-signature.component.html',
    styleUrls: ['./blocks-signature.component.less'],
})
export class BlocksSignatureComponent {
    signatureEnabled: boolean = false;

    constructor(public signatureService: SignatureService) {}
    public clickCanvas(): void {
        this.signatureEnabled = true;
    }
}
