import { Directive, HostListener } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UtilService } from 'src/app/core/services/util.service';

@Directive({
    selector: '[timeValidation]',
})
export class TimeValidationDirective {
    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $target.value;
        if (!this.timeValidation(value)) {
            this.util.addClass($target, 'error');
            this.validation.errors.timeValidation = true;
        } else if (this.timeValidation(value)) {
            this.util.removeClass($target, 'error');
            this.validation.errors.timeValidation = false;
        }
    }

    public constructor(private validation: ValidationService, private util: UtilService) {}

    /**
     * timeValidation
     *
     * checks if the input is a website
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    private timeValidation(value: any): boolean {
        return typeof value === 'string' && /^(10|11|12|0[1-9]):[0-5][0-9]$/.test(value) && value != '';
    }
}
