import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextOnlyValidationDirective } from './textOnlyValidation/text-only-validation.directive';
import { NumberValidationDirective } from './numberValidation/number-validation.directive';
import { EmailValidationDirective } from './emailValidation/email-validation.directive';
import { WebsiteValidationDirective } from './websiteValidation/website-validation.directive';
import { DateValidationDirective } from './dateValidation/date-validation.directive';
import { TimeValidationDirective } from './timeValidation/time-validation.directive';
import { PasswordValidationDirective } from './passwordValidation/password-validation.directive';
import { ZipcodeValidationDirective } from './zipcodeValidation/zipcode-validation.directive';
import { IbanValidationDirective } from './ibanValidation/iban-validation.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TextOnlyValidationDirective,
        NumberValidationDirective,
        EmailValidationDirective,
        WebsiteValidationDirective,
        DateValidationDirective,
        TimeValidationDirective,
        PasswordValidationDirective,
        ZipcodeValidationDirective,
        IbanValidationDirective,
    ],
    exports: [
        TextOnlyValidationDirective,
        NumberValidationDirective,
        EmailValidationDirective,
        WebsiteValidationDirective,
        DateValidationDirective,
        TimeValidationDirective,
        PasswordValidationDirective,
        ZipcodeValidationDirective,
        IbanValidationDirective,
    ],
})
export class ValidationModule {}
