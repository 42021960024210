import { Component, OnInit } from '@angular/core';
import { AjaxService } from '../../../../../core/services/ajax.service';
import { UploadService } from '../../../../../core/services/upload.service';

@Component({
    selector: 'excel-write',
    templateUrl: './excel-write.component.html',
    styleUrls: ['./excel-write.component.less'],
})
export class ExcelWriteComponent implements OnInit {
    public demoArray: string[] = [
        'Locatie; Provincie; Land',
        'Safira Geldermalsen; Gelderland; Nederland',
        'Zaltbommel; Gelderland; Nederland',
        'Walibi Holland; Flevoland; Nederland',
        'FC Utrecht; Utrecht; Nederland',
    ];

    constructor(private ajax: AjaxService, private uploadService: UploadService) {}

    ngOnInit(): void {}

    /**
     * exportToExcel
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    Promise<void>
     */
    public async exportToExcel(): Promise<void> {
        let data = {
            data: this.demoArray,
        };
        this.uploadService.downloadLargeUserFile('excel/writeExcel', data);
    }
}
