import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { HybridExampleComponent } from './hybrid-example/hybrid-example.component';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { UserComponent } from '../admin/user/user.component';
import { GeneralComponent } from '../admin/user/user-form/general/general.component';
import { AddressComponent } from '../admin/user/user-form/address/address.component';
import { UserFormComponent } from '../admin/user/user-form/user-form.component';

export const hybridRoutes: Routes = [
    {
        path: 'hybrid',
        component: HybridExampleComponent,
        canActivate: [AuthGuardService],
        children: [
            { path: '', redirectTo: 'account', pathMatch: 'full' },
            {
                path: 'account',
                component: UserComponent,
                children: [
                    {
                        path: '',
                        component: UserFormComponent,
                        children: [
                            { path: '', redirectTo: 'general', pathMatch: 'full' },
                            { path: 'general', component: GeneralComponent },
                            { path: 'address', component: AddressComponent },
                        ],
                    },
                ],
            },
        ],
    },
];

export const hybridRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(hybridRoutes);
