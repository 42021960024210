import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedactorDirective } from './redactor.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [RedactorDirective],
    exports: [RedactorDirective],
})
export class RedactorModule {}
