import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';

const SCROLL_AMOUNT: number = 10;
const INTERVAL_TIME: number = 10;
const LIST_ITEM_ATTRIBUTE: string = 'tabNumber';
const DIRECTION_ATTRIBUTE: string = 'direction';
const LIST_CLASS: string = 'tabs-list';
const SCROLL_CLASS: string = 'scroll';

@Directive({
    selector: '[TabScroll]',
})
export class TabScrollDirective implements OnInit {
    @Input() public direction: string;
    @Input() public tabNumber: number;

    private intervalElement: any = {};
    private _tabsList: Array<HTMLElement> = [];
    private _scrollButtons: Array<HTMLElement> = [];

    private scrollCounter: number = 0;
    private scrollAmount: number = 10;
    private widthArrow: number = 0;

    private disableScroll: boolean = false;

    @HostListener('window:resize', ['$event'])
    public onResize(event: UIEvent): void {
        this.checkTabArrows();
    }

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        const target: HTMLElement = <HTMLElement>event.target;
        if (target.classList.contains(SCROLL_CLASS)) {
            this.direction = target.getAttribute(DIRECTION_ATTRIBUTE);
            this.tabNumber = parseInt(target.getAttribute(LIST_ITEM_ATTRIBUTE), 10);
            this.widthArrow = target.offsetWidth;

            this.scroll(this.direction, this.tabNumber, this.widthArrow);
        } else if (target.parentElement.classList.contains(SCROLL_CLASS)) {
            this.direction = target.parentElement.getAttribute(DIRECTION_ATTRIBUTE);
            this.tabNumber = parseInt(target.parentElement.getAttribute(LIST_ITEM_ATTRIBUTE), 10);
            this.widthArrow = target.parentElement.offsetWidth;

            this.scroll(this.direction, this.tabNumber, this.widthArrow);
        }

        this.checkTabArrows();
    }

    public constructor(private util: UtilService) {}

    public ngOnInit(): void {
        this._tabsList = this.util.getElements(`.${LIST_CLASS}`);
        this._scrollButtons = this.util.getElements(`.${SCROLL_CLASS}`);

        this.checkTabArrows();
    }

    /**
     * scroll
     *
     * Makes the #tabs-list scroll to the left or right depending on the direction input
     */
    public scroll(direction: string, tabNumber: number, widthArrow: number): void {
        if (this.disableScroll) return;

        const scale: number = 10;
        const widthTabBar: number = this.util.findParentWithClass(
            this._tabsList[tabNumber],
            'tabs'
        ).offsetWidth;

        this.scrollAmount = widthTabBar - widthArrow * 2 - 100;
        this.scrollAmount = Math.ceil(this.scrollAmount / scale) * scale;
        this.disableScroll = true;
        this.scrollCounter = 0;

        this.intervalElement = setInterval(() => {
            this.scrollCounter += SCROLL_AMOUNT;

            if (direction === 'right') {
                this._tabsList[tabNumber].scrollLeft += SCROLL_AMOUNT;
            } else if (direction === 'left') {
                this._tabsList[tabNumber].scrollLeft -= SCROLL_AMOUNT;
            }

            this.checkTabArrows();

            if (this.scrollCounter === this.scrollAmount) {
                this.disableScroll = false;
                clearInterval(this.intervalElement);
            }
        }, INTERVAL_TIME);
    }

    /**
     * checkTabArrows
     *
     * If there are no tabButtons, create them
     * Else we check the width and hide or show them accordingly
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public checkTabArrows(): void {
        for (let i: number = 0; i < this._tabsList.length; i++) {
            if (!this.util.getChild(this._tabsList[i].parentElement, SCROLL_CLASS) === undefined) continue;

            const newDivLeft: HTMLElement = document.createElement('DIV');
            newDivLeft.className = `background-color-secondary ${SCROLL_CLASS} scroll-left`;
            newDivLeft.setAttribute(DIRECTION_ATTRIBUTE, 'left');
            newDivLeft.setAttribute(LIST_ITEM_ATTRIBUTE, i.toString());

            const newIconLeft: HTMLElement = document.createElement('I');
            newIconLeft.className = 'fal fa-angle-left';
            newDivLeft.appendChild(newIconLeft);

            const newDivRight: HTMLElement = document.createElement('DIV');
            newDivRight.className = `background-color-secondary ${SCROLL_CLASS} scroll-right`;
            newDivRight.setAttribute(DIRECTION_ATTRIBUTE, 'right');
            newDivRight.setAttribute(LIST_ITEM_ATTRIBUTE, i.toString());

            const newIconRight: HTMLElement = document.createElement('I');
            newIconRight.className = 'fal fa-angle-right';
            newDivRight.appendChild(newIconRight);

            this._tabsList[i].parentElement.appendChild(newDivLeft);
            this._tabsList[i].parentElement.appendChild(newDivRight);
        }

        for (let i: number = 0; i < this._tabsList.length; i++) {
            if (this._tabsList[i].offsetWidth < this._tabsList[i].scrollWidth) {
                for (let x: number = 0; x < this._scrollButtons.length; x++) {
                    if (
                        parseInt(this._scrollButtons[x].getAttribute(LIST_ITEM_ATTRIBUTE), 10) == i
                    ) {
                        this._scrollButtons[x].style.display = 'block';
                    }
                }
            } else if (this._tabsList[i].offsetWidth >= this._tabsList[i].scrollWidth) {
                for (let x: number = 0; x < this._scrollButtons.length; x++) {
                    if (
                        parseInt(this._scrollButtons[x].getAttribute(LIST_ITEM_ATTRIBUTE), 10) == i
                    ) {
                        this._scrollButtons[x].style.display = 'none';
                    }
                }
            }

            //no scroll, hide left arrow
            if (this._tabsList[i].scrollLeft === 0) {
                this.util.findChildrenWithClass(
                    this._tabsList[i].parentElement,
                    'scroll-left'
                )[0].style.display = 'none';
            }

            //no scroll, hide right arrow
            if (
                this._tabsList[i].offsetWidth + this._tabsList[i].scrollLeft >=
                this._tabsList[i].scrollWidth
            ) {
                this.util.findChildrenWithClass(
                    this._tabsList[i].parentElement,
                    'scroll-right'
                )[0].style.display = 'none';
            }
        }
    }
}
