import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageModel } from '../models/language.model';
import { AjaxService } from './ajax.service';
import { CrudServiceModel } from '../models/crud/crud-service.model';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageService extends CrudServiceModel<LanguageModel> {
    // Languages that can be switched to.
    public languages: LanguageModel[];
    public available: LanguageModel[];
    public current: LanguageModel;
    public loading: boolean = false;
    public languageChange: EventEmitter<LanguageModel> = new EventEmitter<LanguageModel>();

    protected readonly create = LanguageModel;
    protected readonly module: string = 'language';

    public constructor(protected ajax: AjaxService, public router: Router, public route: ActivatedRoute, private storage: StorageService) {
        super();
    }

    protected validation(): boolean {
        return true;
    }

    public async getAll(): Promise<LanguageModel[]> {
        return (this.languages = await super.getAll());
    }

    public async getAllActive(): Promise<LanguageModel[]> {
        const response = await this.getEndpoint('getAllActive');
        return (this.available = response.map((model) => {
            return new this.create(this.save.bind(this), model);
        }));
    }

    async setDefault(language: LanguageModel) {
        await this.getEndpoint('setDefault', { languageId: language.languageId });
        await this.getAllActive();
    }

    /**
     * Gets the default language from the already fetched languages
     */
    public getDefault(): LanguageModel {
        return this.available[
            this.available.findIndex((language: LanguageModel) => {
                return language.default;
            })
            ];
    }

    /**
     * If the new language is undefined then it will resort to automatic detection and then the default
     */
    public async setCurrentLanguage(language?: LanguageModel): Promise<LanguageModel> {
        if (language !== undefined && language !== null) {
            this.current = language;
            this.storage.setStorage('currentLanguage', this.current.iso)
            this.languageChange.next(this.current);
            return this.current;
        }

        if (this.available === undefined) {
            await this.getAllActive();
        }

        for (const availableLanguage of this.available) {
            if (availableLanguage.iso === navigator.language.substring(0, 2)) {
                this.current = availableLanguage;
                this.storage.setStorage('currentLanguage', this.current.iso)
                this.languageChange.next(this.current);
                return this.current;
            }
        }

        this.current = this.getDefault();
        this.storage.setStorage('currentLanguage', this.current.iso)
        this.languageChange.next(this.current);
        return this.current;
    }
}
