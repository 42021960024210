import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'sFileupload',
    templateUrl: './fileupload.component.html',
    styleUrls: ['./fileupload.component.less'],
})
export class FileuploadComponent {
    @Output() handleDrop: EventEmitter<any> = new EventEmitter();

    @Input('text') text: string = 'Sleep of klik hier je afbeeldingen om ze toe te voegen.';

    @Input('height') height: number = 200;

    @ViewChild('imageSelect', { read: ElementRef }) imageSelect: ElementRef;

    @HostListener('dragover', ['$event'])
    public onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }
    @HostListener('drag', ['$event'])
    public onDrag(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }
    @HostListener('drop', ['$event'])
    public onDragDragend(event: DragEvent): void {
        event.preventDefault();
        this.handleDrop.emit(event.dataTransfer.files);
    }

    constructor() {}

    /**
     * openFileSelector
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public openFileSelector(): void {
        this.imageSelect.nativeElement.querySelector('#input').click();
    }

    /**
     * emitEvent
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public emitEvent(event): void {
        event.preventDefault();
        this.handleDrop.emit(event.target.files);
    }
}
