import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'sCounter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CounterComponent),
            multi: true,
        },
    ],
})
export class CounterComponent implements ControlValueAccessor, OnInit {
    @Input() max = 9999;
    @Input() min = 0;

    public onChange: (val: number) => void = () => {};
    public onTouched: () => void = () => {};

    private _value: number;
    get value(): number {
        return this._value;
    }
    @Input() set value(val: number) {
        this._value = val;
    }

    constructor() {}

    /**
     * Registers the onChange function
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public registerOnChange(fn: (val: number) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers the onTouch function
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Writes the value
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public writeValue(value: number): void {
        this.value = value;
    }

    ngOnInit(): void {}

    /**
     * increases the value by 1
     *
     * @author    Mitchell Sterrenberg <mitchell@safira.nl>
     */
    increase() {
        if (this.value < this.max) {
            this.onChange((this.value += 1));
        }
    }

    /**
     * decreases the value by 1
     *
     * @author    Mitchell Sterrenberg <mitchell@safira.nl>
     */
    decrease() {
        if (this.value > this.min) {
            this.onChange((this.value -= 1));
        }
    }

    /**
     * changes the value to what is typed in the input
     *
     * @author    Mitchell Sterrenberg <mitchell@safira.nl>
     */
    onInput(data: number) {
        if (!data) {
            data = 0;
        }
        if (data >= this.max) {
            data = this.max;
        } else if (data <= this.min) {
            data = this.min;
        }
        this.onChange(data);
        this.value = +data;
    }
}
