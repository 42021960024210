import { Directive, HostListener } from '@angular/core';
import flatpickr from 'flatpickr';

@Directive({
    selector: '[datePickr]',
})
export class DatePickrDirective {
    constructor() {}

    @HostListener('click', ['$event.target']) onClick($input) {
        if ($input.classList.contains('flatpickr-input')) return;
        let datePickr = flatpickr($input, {
            dateFormat: 'Y-m-d',
            weekNumbers: true /*disable: [date => date.getDay() == 0, date => date.getDay() == 6]*/,
        });
        flatpickr.l10ns.default.firstDayOfWeek = 1;
        datePickr.open();
    }
}
