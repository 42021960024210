import { ExcelWriteComponent } from './excel-write/excel-write.component';
import { ExcelReadComponent } from './excel-read/excel-read.component';
import { ExcelComponent } from './excel/excel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormModule } from '../../../../core/components/form/form.module';
import { TranslationModule } from 'src/app/modules/translate.module';

@NgModule({
    declarations: [ExcelComponent, ExcelReadComponent, ExcelWriteComponent],
    imports: [CommonModule, FormModule, FormsModule, TranslationModule],
    exports: [ExcelComponent],
})
export class ExcelModule {}
