import { Directive, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

let dragSrcEl: HTMLElement;

@Directive({
    selector: '[dnd]',
})
export class DndDirective {
    @Output() public callback: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Input() public dndActive: number = 1;

    @HostListener('mousedown', ['$event'])
    public onMouseDown(event: MouseEvent): void {
        this.handleMouseDown(event);
    }
    @HostListener('mouseup', ['$event'])
    public onMouseUp(event: MouseEvent): void {
        this.handleMouseUp(event);
    }
    @HostListener('dragstart', ['$event'])
    public onDragStart(event: DragEvent): void {
        this.handleDragStart(event);
    }
    @HostListener('dragenter', ['$event'])
    public onDragEnter(event: DragEvent): void {
        this.handleDragEnter(event);
    }
    @HostListener('dragover', ['$event'])
    public onDragOver(event: DragEvent): void {
        this.handleDragOver(event);
    }
    @HostListener('drag', ['$event'])
    public onDrag(event: DragEvent): void {
        this.handleDrag(event);
    }
    @HostListener('dragend', ['$event'])
    public onDragDragend(event: DragEvent): void {
        this.handleDragEnd(event);
        this.handleMouseUp(event);
    }

    public constructor(el?: ElementRef) {
        el.nativeElement.setAttribute('draggable', 'true');
    }

    public handleMouseDown(e: MouseEvent): void {
        const $target: HTMLElement = <HTMLElement>e.target;
        $target.classList.add('active-element');
    }

    public handleMouseUp(e: MouseEvent): void {
        const $target: HTMLElement = <HTMLElement>e.target;
        $target.classList.remove('active-element');
    }

    public handleDrag(e: DragEvent): void {}

    /**
     * Start dragging
     * @param    e    Event
     */
    public handleDragStart(e: DragEvent): void {
        if (!this.dndActive) return;

        //set class to wrapper
        const $target: HTMLElement = <HTMLElement>e.target;
        const $wraptarget: HTMLElement = <HTMLElement>(
            ($target.nodeName == '#text' ? $target.parentNode : $target)
        );
        $wraptarget.closest('.dnd-outer-container').classList.add('dnd-dragging-active');

        // Save the target in a variable so we can access it later
        if ($wraptarget.classList.contains('dnd-item')) {
            dragSrcEl = $target;
        } else {
            dragSrcEl = $target.closest('.dnd-item');
        }

        if (dragSrcEl != null) {
            dragSrcEl.classList.add('dragging-element');
        }

        // Here we declared what we are allowed to do with to object and which data we send with it
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('html', $target.outerHTML);

        // Hides image drag on pointer
        var img = new Image();
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
        e.dataTransfer.setDragImage(img, 0, 0);
    }

    // This function could be unnecessary but check before you delete this function.
    public handleDragOver(e: DragEvent): void {
        if (this.dndActive) return;
        if (e.preventDefault) {
            // Necessary. Allows us to drop.
            e.preventDefault();
        }

        // See the section on the DataTransfer object.
        e.dataTransfer.dropEffect = 'move';
    }

    /**
     * Mouse entered container
     */
    public handleDragEnter(e: DragEvent): void {
        // insert the element before the current element
        if (!this.dndActive || dragSrcEl == null) return;

        const $target: HTMLElement = <HTMLElement>e.target;

        if (this.isbefore(dragSrcEl, $target) && e.target != $target.closest('.dnd-outer-container')) {
            $target.closest('.dnd-outer-container').insertBefore(dragSrcEl, $target.closest('.dnd-item'));
        } else if (
            $target.closest('.dnd-item') != null &&
            e.target != $target.closest('.dnd-outer-container')
        ) {
            $target
                .closest('.dnd-outer-container')
                .insertBefore(dragSrcEl, $target.closest('.dnd-item').nextElementSibling);
        }
    }

    /**
     * We stopped dragging
     */
    public handleDragEnd(e: DragEvent): void {
        if (!this.dndActive) return;

        const $target: HTMLElement = <HTMLElement>e.target;
        const newArray: Array<string> = [];

        // remove all style changes we made while dragging
        $target.removeAttribute('style');

        $target.classList.remove('dragging-element');

        //set class to wrapper
        const $wraptarget: HTMLElement = <HTMLElement>(
            ($target.nodeName === '#text' ? $target.parentNode : e.target)
        );
        $wraptarget.closest('.dnd-outer-container').classList.remove('dnd-dragging-active');

        // Here we adjust the order of the items. This needs to be checked/rewritten for every app.
        // update the order of the items
        const items: HTMLCollectionOf<Element> = document.getElementsByClassName('dnd-item');

        // Build new array with item ID and order number
        for (var i: number = 0; i < items.length; i++) {
            newArray.push(items[i].getAttribute('id'));
        }

        this.callback.next(newArray);
    }

    /**
     * Checks if element1 comes before element2
     * here we check if the item we're holding should be inserted before or after the item we are hovering above
     */
    public isbefore(a: Element, b: Element): boolean {
        this.resetElement(document.activeElement);
        if (a == null || b == null) {
            return false;
        }
        if (a.parentNode === b.parentNode) {
            for (var cur: Element = a; cur; cur = cur.previousElementSibling) {
                if (cur === b) return true;
            }
        }
        return false;
    }

    private resetElement(e) {
        let $e = e;
        let $original = $e;
        $e.replaceWith($original);
    }
}
