import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { UtilService } from 'src/app/core/services/util.service';
import { ValidationService } from 'src/app/core/services/validation.service';

@Directive({
    selector: '[emailValidation]',
})
export class EmailValidationDirective {
    @Output() callback = new EventEmitter<object>();

    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $target.value;
        if (!this.emailValidation(value)) {
            this.util.addClass($target, 'error');
            this.validation.errors.emailValidation = true;
        } else if (this.emailValidation(value)) {
            this.util.removeClass($target, 'error');
            this.validation.errors.emailValidation = false;
        }
    }

    public constructor(private util: UtilService, private validation: ValidationService) {}

    /**
     * emailValidation
     *
     * checks if the input is an email
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private emailValidation(value: any): boolean {
        return (
            typeof value === 'string' &&
            /^([\w+-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.exec(
                value
            ) &&
            value != ''
        );
    }
}
