import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { demoTableRouting } from './demo-table.routes';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from '../../../translate.module';
import { TableModule } from '../../../../core/components/table/table.module';
import { ItemModule } from '../../../../core/components/item/item.module';
import { PopupModule } from '../../../../core/components/popup/popup.module';
import { FormModule } from '../../../../core/components/form/form.module';
import { DemoTableListComponent } from './demo-table-list/demo-table-list.component';
import { DashboardModule } from '../../../dashboard/dashboard.module';

@NgModule({
    declarations: [DemoTableListComponent],
    imports: [
        demoTableRouting,
        CommonModule,
        FormModule,
        FormsModule,
        TranslationModule,
        TableModule,
        PopupModule,
        ItemModule,
        DashboardModule,
    ],
})
export class DemoTableModule {}
