import { FormModule } from '../form/form.module';
import { FormsModule } from '@angular/forms';
import { InfoPopupComponent } from './info-popup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [InfoPopupComponent],
    imports: [CommonModule, FormsModule, FormModule],
	exports: [InfoPopupComponent]
})
export class InfoPopupModule {}
