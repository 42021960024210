import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

let id: number = 0;

@Component({
    selector: 'sCheckbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor {
    @Input('sClass') public sClass: string = '';
    @Input('label') public label: string = '';
    @Input('name') public name: string = '';
    @Input('value') public value: string = '';
    @Input('disabled') public disabled: boolean = false;
    @Input('checked') public checked: boolean = false;

    public id: number;

    public onChange: (val: any) => void = () => {};
    public onTouched: () => void = () => {};

    public constructor() {
        id += 1;
        this.id = id;
    }

    /**
     * Registers the onChange function
     */
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers the onTouch function
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Writes the value
     */
    public writeValue(value: any): void {
        this.checked = value;
    }

    /**
     * When the checkbox has been toggled
     */
    public onChanged(): void {
        this.checked = !this.checked;

        this.onChange(this.checked);
        this.onTouched();
    }
}
