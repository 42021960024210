import { LanguageService } from '../services/language.service';
import { ChangeDetectorRef, Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';
import { Subscription } from 'rxjs';

@Injectable()
@Pipe({
    name: 'translate',
    pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {
    public value: string = '';
    public lastKey: string;
    public lastParams: Array<any>;

    public onLangChange: Subscription;

    public constructor(private translate: TranslateService, private _ref: ChangeDetectorRef, private language: LanguageService) {}

    public ngOnDestroy(): void {
        if (this.onLangChange) {
            this.onLangChange.unsubscribe();
        }
    }

    public transform(query: string, ...args): string {
        if (!query || query.length === 0) {
            return query;
        }

        if (query == this.lastKey && args == this.lastParams) {
            return this.value;
        }

        this.lastKey = query;
        this.lastParams = args;

        this.updateValue(query, args);

        if (!this.onLangChange) {
            this.onLangChange = this.language.languageChange.subscribe(() => {
                if (this.lastKey) {
                    this.lastKey = null;
                    this.updateValue(query, args);
                }
            });
        }
        return this.value;
    }

    private updateValue(label: string, args: Array<any>): void {
        this.value = this.translate.getParsedResult(label, args);
        this.lastKey = label;
        this.lastParams = args;
        this._ref.markForCheck();
    }
}
