import { Component, Input } from '@angular/core';
import { GroupItem } from '../../../models/item.model';

@Component({
    selector: 'sGroupItem',
    templateUrl: './group-item.component.html',
    styleUrls: ['./group-item.component.less'],
})
export class GroupItemComponent {
    @Input() data: GroupItem;
}
