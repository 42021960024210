import { NgModule } from '@angular/core';
import { adminRouting } from './admin.routes';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { CommonModule } from '@angular/common';
import { PopupModule } from 'src/app/core/components/popup/popup.module';
import { AdminComponent } from './admin.component';
import { DatePickrModule } from 'src/app/core/directives/datepickr/datepickr.module';
import { UserModule } from './user/user.module';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { ImportComponent } from './user/import/import.component';
import { LanguageComponent } from './language/language.component';
import { TranslationComponent } from './translation/translation.component';
import { TableModule } from '../../core/components/table/table.module';
import { ItemModule } from '../../core/components/item/item.module';
import { TranslationModule } from '../translate.module';
import { ModuleComponent } from './module/module.component';

@NgModule({
    declarations: [
        AdminComponent,
        ImportComponent,
        TwoFactorAuthenticationComponent,
        LanguageComponent,
        ModuleComponent,
        TranslationComponent,
    ],
    imports: [
        adminRouting,
        CommonModule,
        PopupModule,
        FormsModule, //required for [(ngModel)]
        FormModule, // Custom Safira form module
        DatePickrModule,
        UserModule,
        TableModule,
        ItemModule,
        TranslationModule,
    ],
})
export class AdminModule {}
