import { ProductService } from '../../../core/services/product.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.less'],
})
export class ProductsComponent implements OnInit {
    public searchValue: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public productService: ProductService
    ) {}

    async ngOnInit() {
        await this.productService.productGroupPromise;
        await this.initActiveGroupId();
		this.productService.filterGroups = [];
        await Promise.all([
            this.productService.getProductsByGroupId(),
            this.productService.getFilterGroups(),
        ]);
    }

    public async initActiveGroupId() {
        if (!this.productService.activeGroupId) {
            this.productService.activeGroupId = await this.productService.getActiveGroupIdByType(
                (
                    this.route.snapshot.params.productType[0].toUpperCase() +
                    this.route.snapshot.params.productType.slice(1)
                )
                    .split('-')
                    .join(' ')
            );
            if (!this.productService.activeGroupId) {
                this.router.navigateByUrl('/');
            }
        }
    }

    public showProduct(product): boolean {
        if (!this.searchValue) {
            return true;
        }
        return (
            (product.fields.title && product.fields.title.toLowerCase().includes(this.searchValue.toLowerCase())) ||
            (product.fields.brand && product.fields.brand.toLowerCase().includes(this.searchValue.toLowerCase())) ||
            (product.article_number &&
                product.article_number.toLowerCase().includes(this.searchValue.toLowerCase())) ||
            (product.size && product.size.toLowerCase().includes(this.searchValue.toLowerCase()))
        );
    }
}
