import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { core } from '@angular/compiler';
import { AjaxService } from '../ajax.service';
import { StorageService } from '../storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorisationService {
    public constructor(private router: Router, private ajax: AjaxService, private storage: StorageService) {}

    /*
     * 	canAccess
     *
     *	Checks if an user is authorised to the given page
     */
    public canAccess(): boolean {
        return this.storage.getStorage('authToken') !== '';
    }

    public routeIsLoginPage(): boolean {
        return this.router.routerState.snapshot.root.firstChild.data.login === true;
    }

    /*
     * 	goToLogin
     *
     *	Navigate user to the login page
     */
    public goToLogin(nextUrl?: string, force: boolean = false): void {
        const params: NavigationExtras = nextUrl
            ? {
                  queryParams: {
                      next: nextUrl,
                  },
              }
            : {};

        setTimeout(() => {
            if (force || !this.routeIsLoginPage()) {
                this.router.navigate(['/login'], params);
            }
        }, 0);
    }

    /**
     * Check if auth token is valid.
     *
     * @author    Damian
     */
    public async checkIfAuthTokenIsValid(): Promise<boolean> {
        return (await this.ajax.get('user/authorisation/checkIfAuthTokenIsValid').catch(() => {
            return false;
        })) as boolean;
    }
}
