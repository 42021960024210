import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'blocks',
    templateUrl: './blocks.component.html',
})
export class BlocksComponent {
    constructor(public router: Router) {}
}
