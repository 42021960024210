import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from '../../../../core/services/ajax.service';
import { ToasterService } from '../../../../core/services/toaster.service';
import { ConfirmboxService } from '../../../../core/services/confirmbox.service';

@Component({
    selector: 'webcam-photo',
    templateUrl: './webcam-and-photo.component.html',
    styleUrls: ['./webcam-and-photo.component.less'],
})
export class WebcamPhotoComponent implements AfterViewInit, OnDestroy {
    public webcamStream: any;
    public video: any;
    public blob: any = [];
    public canvas: HTMLCanvasElement;
    public ctx: CanvasRenderingContext2D;
    public webcamOn: boolean;
    public webcamText: string;
    public pictureTaken: boolean;
    public pictureUploaded: boolean;
    public camCheck: boolean;

    constructor(
        public router: Router,
        public toast: ToasterService,
        public confirmboxService: ConfirmboxService,
        private ajax: AjaxService
    ) {}

    ngOnInit() {
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                devices.forEach((device) => {
                    if (this.camCheck) return;

                    if (device.kind === 'videoinput') {
                        this.webcamText = 'itemMedia.click_to_start';
                        this.camCheck = true;
                    } else {
                        this.camCheck = false;
                        this.webcamText = 'itemMedia.not_available';
                    }
                });
            })
            .catch(function (err) {
                console.log(err.name + ': ' + err.message);
            });
    }

    ngAfterViewInit() {
        this.canvas = <HTMLCanvasElement>document.getElementById('myCanvas');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = 720;
        this.canvas.height = 720;
    }

    public startWebcam() {
        if (!this.camCheck) return console.log('Camera is not available');
        navigator.mediaDevices
            .getUserMedia(
                // constraints
                {
                    video: true,
                    audio: false,
                }
            )
            .then(
                // successCallback
                (localMediaStream) => {
                    this.webcamOn = true;
                    this.video = document.querySelector('#video');
                    setTimeout(() => {
                        this.canvas.width = 720;
                        this.canvas.height = 720;
                        let widthratio = this.video.offsetWidth / this.video.offsetHeight;
                        let heightratio = this.video.offsetHeight / this.video.offsetWidth;

                        if (widthratio > 1) {
                            this.canvas.width = this.canvas.width * widthratio;
                        } else if (heightratio > 1) {
                            this.canvas.height = this.canvas.height * heightratio;
                        }
                    }, 200);

                    this.video.srcObject = localMediaStream;
                    this.webcamStream = localMediaStream;
                }
            )

            // errorCallback
            .catch((err) => {
                console.log('The following error occured: ' + err);
            });
    }

    public stopWebcam() {
        this.webcamOn = false;
        this.webcamStream.getVideoTracks()[0].stop();
    }
    //---------------------
    // TAKE A SNAPSHOT CODE
    //---------------------
    public snapshot() {
        // Draws current image from the video element into the canvas
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        //horizontale flip
        this.ctx.setTransform(-1, 0, 0, 1, this.canvas.width, 0);
        this.ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
        const image = this.canvas.toDataURL('image/jpeg');
        const blob = this.dataURLToBlob(image);
        this.blob[0] = blob;
        this.pictureTaken = true;
        this.stopWebcam();
    }

    public dataURLToBlob(dataURL: string) {
        const BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
            const parts = dataURL.split(',');
            const contentType = parts[0].split(':')[1];
            const raw = parts[1];

            return new Blob([raw], { type: contentType });
        }

        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw: any = window.atob(parts[1]);
        const rawLength = raw.length;

        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    public reset() {
        this.pictureUploaded = false;
    }

    public uploadPhoto(rewrite: number) {
        const promise = new Promise((resolve, reject) => {
            this.ajax
                .upload('image/uploadProfilePicture', this.blob, 'image', { rewrite: rewrite }, '.content')
                .then(
                    (res) => {
                        // Success
                        if (res['error']['code'] === 1) {
                            // File could not be uploaded. Reason unknown.
                            this.toast.error('Oops! Er is iets mis gegaan. Probeer het opnieuw.');
                        } else if (res['error']['code'] === 2) {
                            // File already exists.
                            this.confirmboxService.confirm(
                                'Foto uploaden',
                                'U staat op het punt om uw oude profiel foto te overschrijven met een nieuwe profiel foto. Weet u zeker dat u dit wilt doen?',
                                () => {
                                    this.uploadPhoto(1);
                                },
                                () => {}
                            );
                        } else if (res['error']['code'] === 3) {
                            // File extension is not allowed.
                            this.toast.error('Bestandstype is niet toegestaan!');
                        }
                        resolve(res);
                    },
                    (msg) => {
                        //Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    public imageUpload() {
        const input: Element = document.getElementById('imageSelect').children[0].children[0].children[0];
        (<any>input).click();
    }

    public upload(e: Event) {
        const target = <HTMLInputElement>e.target;
        const canvas = <HTMLCanvasElement>document.getElementById('myCanvas');
        const ctx = this.canvas.getContext('2d');
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const FR = new FileReader();
        FR.onload = function (e: Event) {
            const img = new Image();
            img.addEventListener('load', function () {
                var wrh = img.width / img.height;
                var newWidth = canvas.width;
                var newHeight = newWidth / wrh;
                if (newHeight > canvas.height) {
                    newHeight = canvas.height;
                    newWidth = newHeight * wrh;
                }
                ctx.drawImage(
                    img,
                    (canvas.width - newWidth) / 2,
                    (canvas.height - newHeight) / 2,
                    newWidth,
                    newHeight
                );
            });
            img.src = <string>(<FileReader>e.target).result;
        };
        FR.readAsDataURL(target.files[0]);

        this.blob[0] = target.files[0];
        this.pictureUploaded = true;
    }

    ngOnDestroy() {
        if (this.webcamOn) {
            this.stopWebcam();
        }
    }
}
