import { Component } from '@angular/core';

@Component({
  selector: 'blocks-media',
  templateUrl: './blocks-media.component.html',
})
export class BlocksMediaComponent {

  constructor() { }
}
