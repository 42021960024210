import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/core/services/user/account.service';
import { CmsService } from '../../../core/services/cms.service';

@Component({
    selector: 'hybrid-example',
    templateUrl: './hybrid-example.component.html',
    styleUrls: ['./hybrid-example.component.less'],
})
export class HybridExampleComponent implements OnInit {
    constructor(private cms: CmsService, public account: AccountService) {
        // set the amount of components that will be loaded
        this.cms.setTotalComponents(4);
    }

    ngOnInit(): void {}
}
