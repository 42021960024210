import { Component, Input } from '@angular/core';
import { action } from '../../../models/action.model';

@Component({
    selector: 'sAction',
    templateUrl: './action.component.html',
})
export class ActionComponent {
    @Input() action: action;
}
