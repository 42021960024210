export class popupModel {
    public headerText: string;
    public contentText: string;
    public id: string;

    constructor(headerText: string, contentText: string, id: string) {
        this.headerText = headerText;
        this.contentText = contentText;
        this.id = id;
    }
}
