import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConfirmboxService {
    /**
     * content
     *
     * Content of the label. HTML can be applied.
     */
    public content: string;

    /**
     * title
     *
     * title of the label. HTML can be applied.
     */
    public title: string;

    /**
     * active
     *
     * wether the modal is visible or not
     */
    public active: boolean = false;

    /**
     * confirmAction
     *
     * Holds the callback for the confirm button
     */
    public confirmAction: () => void;

    /**
     * cancelAction
     *
     * Holds the callback for the cancel button
     */
    public cancelAction: () => void;

    /**
     * confirm
     *
     * Function to call when you want to use the confirmbox component.
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public confirm(title: string, message: string, confirm: () => void, cancel: () => void): void {
        this.active = true;
        this.content = message;
        this.title = title;
        this.confirmAction = confirm;
        this.cancelAction = cancel;
    }

    /**
     * onConfirm
     *
     * Default actions for confirm
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public onConfirm(): void {
        this.confirmAction();
        this.active = false;
    }

    /**
     * onCancel
     *
     * Default actions for cancel
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public onCancel(): void {
        this.cancelAction();
        this.active = false;
    }
}
