import { ProductService } from '../../../core/services/product.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.less'],
})
export class ConfirmationComponent implements OnInit {
    public orderDetails;

    constructor(public productService: ProductService, private router: Router) {}

    async ngOnInit() {
        this.orderDetails = await this.productService.getConfirmedOrder();
        if (!this.orderDetails) this.router.navigateByUrl('/');
    }
}
