import { Directive, HostListener } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UtilService } from 'src/app/core/services/util.service';

@Directive({
    selector: '[websiteValidation]',
})
export class WebsiteValidationDirective {
    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $target.value;
        if (!this.websiteValidation(value)) {
            this.util.addClass($target, 'error');
            this.validation.errors.websiteValidation = true;
        } else if (this.websiteValidation(value)) {
            this.util.removeClass($target, 'error');
            this.validation.errors.websiteValidation = false;
        }
    }

    public constructor(private validation: ValidationService, private util: UtilService) {}

    /**
     * websiteValidation
     *
     * checks if the input is a website
     */
    private websiteValidation(value: any): boolean {
        return (
            typeof value === 'string' &&
            /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(value) &&
            value != ''
        );
    }
}
