import { HttpStatusCode } from '../http-status-code';

export class CrudException extends Error {
    constructor(
        public readonly code: HttpStatusCode,
        public readonly message: string,
        public readonly error?
    ) {
        super(message);
    }
}
