import { LocationStrategy } from '@angular/common';
import { ShareService } from './../../../../core/services/share.service';
import { UtilService } from '../../../../core/services/util.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { popupModel } from '../../../../core/components/input/popup.model';
import { SelectorChoice } from '../../../../core/components/selector/selector.model';
import { ValidationService } from '../../../../core/services/validation.service';
import { ToasterService } from '../../../../core/services/toaster.service';

@Component({
    selector: 'formsInput',
    templateUrl: './forms-input.component.html',
    styleUrls: ['./forms-input.component.less'],
})
export class FormsInputComponent implements OnInit {
    value: number = 0;
    public popup: popupModel = {
        headerText: 'Input met popup info',
        contentText: 'Dit is een test popup.',
        id: 'input-popup',
    };

    public selectorArray: Array<SelectorChoice> = [
        {
            name: 'Option 1',
            chosen: false,
            connected: false,
            selected: true,
        },
        {
            name: 'Option 2',
            chosen: true,
            connected: false,
            selected: false,
        },
        {
            name: 'Option 3 disabled',
            chosen: true,
            connected: true,
            selected: false,
        },
        {
            name: 'Option 4',
            chosen: false,
            connected: false,
            selected: true,
        },
        {
            name: 'Option 5',
            chosen: false,
            connected: false,
            selected: false,
        },
    ];

    constructor(
        public router: Router,
        public validationService: ValidationService,
        public locationStrategy: LocationStrategy,
        private toaster: ToasterService,
        private util: UtilService
    ) {}

    ngOnInit(): void {
        this.validationService.checkErrors();
        this.toaster.type = null;
    }

    /**
     * submitForm
     * submit form outside <sForm></sForm> tag
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public submitForm(): void {
        let _element = this.util.getElement('#validation-button');
        if (_element) {
            _element.click();
        }
    }
}
