import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';

@Directive({
    selector: '[passwordValidation]',
})
export class PasswordValidationDirective {
    @Output() public callback: EventEmitter<object> = new EventEmitter<object>();
    @Output() public error: EventEmitter<object> = new EventEmitter<object>();

    public minStrength: number = 2;

    public constructor(private validationService: ValidationService) {}

    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        this.checkStrength(e);
    }

    /**
     * checkStrength
     *
     * checks the strength of the password and updates the indicator
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private checkStrength(e: Event): void {
        const $input: HTMLInputElement = <HTMLInputElement>e.target;
        const strength: string = this.validationService.checkPasswordStrength($input.value);
        const strengthNumber: number = this.validationService.getRankIndexByStringName(strength);

        if ($input.value === '' || strengthNumber >= Number(this.minStrength)) {
            this.callback.emit(e);
        } else {
            this.error.emit(e);
        }
    }
}
