import { Component } from '@angular/core';

@Component({
    selector: 'excel',
    templateUrl: './excel.component.html',
    styleUrls: ['./excel.component.less'],
})
export class ExcelComponent {
    public excelContent: any = [];

    constructor() {}
}
