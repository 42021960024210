import { Component, Input, OnDestroy } from '@angular/core';
import { Table, TableColumn, TableFilter, TableFilters } from '../../models/table.model';
import { TableService } from '../../services/table.service';
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../../services/responsive.service';
import { item } from '../../models/item.model';
import { BreadcrumbService } from '../../services/breadcrumb-service.service';

@Component({
    selector: 'sTable',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.less'],
})
export class TableComponent implements OnDestroy {
    @Input() buttons: item[];

    public table: Table;

    private readonly subscriptions: Subscription[] = [];

    constructor(
        public tableService: TableService,
        public responsive: ResponsiveService,
        public breadCrumb: BreadcrumbService
    ) {
        this.subscriptions.push(
            ...[
                this.tableService.tableChanged.subscribe((table: Table) => {
                    this.table = table;
                }),
            ]
        );
    }

    hasSearches() {
        return (
            this.table &&
            this.table.filter &&
            this.table.filter.searches !== undefined &&
            this.table.filter.searches.length > 0
        );
    }

    hasFilters() {
        return (
            this.table &&
            this.table.filter &&
            this.table.filter.filters !== undefined &&
            this.table.filter.filters.length > 0
        );
    }

    hasFilterOptions(filter?: TableFilter): boolean {
        if (filter) {
            if (filter.options === undefined) {
                filter.options = this.tableService.getOptions(filter.filterOn);
            }
            return filter.options.length > 1;
        }
        for (const filter of this.table.filter.filters) {
            if (filter.options === undefined) {
                filter.options = this.tableService.getOptions(filter.filterOn);
            }
            if (filter.options.length > 1) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (subscription !== undefined) {
                subscription.unsubscribe();
            }
        }
    }

    showColumn(column: TableColumn): boolean {
        return (
            (column.show === undefined || column.show) &&
            (column.breakpoint === undefined ||
                column.breakpoint.state === undefined ||
                column.breakpoint.state === 'both' ||
                (column.breakpoint.state === 'after'
                    ? this.responsive.isSize(column.breakpoint.breakpoint)
                    : !this.responsive.isSize(column.breakpoint.breakpoint)))
        );
    }

    getColumnWidth(column: TableColumn): number {
        return column.breakpoint === undefined ||
            column.breakpoint.widthUntil === undefined ||
            this.responsive.isSize(column.breakpoint.breakpoint)
            ? column.width
            : column.breakpoint.widthUntil;
    }

    showExpandColumn(): boolean {
        return (
            this.table.rows[0].expandable !== undefined &&
            !this.responsive.isSize(this.table.rows[0].expandable.breakpoint)
        );
    }

    showButtons(): boolean {
        return this.buttons && this.buttons.length > 0;
    }

    showFilters(): boolean {
        return this.table && this.table.filter && this.hasFilters() && this.hasFilterOptions();
    }
}
