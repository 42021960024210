import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from '../../services/auth/authenticate.service';
import { NavigationService } from '../../services/navigation.service';
import { ProductService } from '../../services/product.service';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
    selector: 'sNavigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent {
    open: boolean = false;
    openCategories: boolean = false;

    constructor(public authenticate: AuthenticateService, public product: ProductService, public router: Router) {

	}

	public getCartAmount(): number{
		return this.product.cart.map((cartProduct) => cartProduct.amount).reduce((a, b) => +a + +b, 0);
	}
}
