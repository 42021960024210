import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectorChoice } from './selector.model';

@Component({
    selector: 'sSelector',
    templateUrl: './selector.component.html',
    styleUrls: ['./selector.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectorComponent),
            multi: true,
        },
    ],
})
export class SelectorComponent implements ControlValueAccessor {
    private _value: Array<SelectorChoice> = null;
    @Input('value') public set value(val: Array<SelectorChoice>) {
        this.all = [];
        this.chosen = [];

        if (!val) return;

        for (const element of val) {
            if (element.chosen) {
                this.chosen.push(element);
            } else {
                this.all.push(element);
            }
        }

        this._value = val;
        this.onChange(val);
        this.onTouched();
    }
    public get value(): Array<SelectorChoice> {
        return this._value;
    }

    @Input('chosenText') public chosenText: string = '';
    @Input('allText') public allText: string = '';

    public onChange: (val: any) => void = (val: any) => {};
    public onTouched: () => void = () => {};

    public all: Array<SelectorChoice> = [];
    public chosen: Array<SelectorChoice> = [];

    public constructor() {}

    /**
     * Set the positve status
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public setValue(value: Array<SelectorChoice>): void {
        this.value = value;
    }

    /**
     * Updates the value of the input
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public update(event: { target: { value: Array<SelectorChoice> } }): void {
        this.value = event.target.value;
    }

    /**
     * Registers the onChange function
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers the onTouch function
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Writes the value
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public writeValue(value: Array<SelectorChoice>): void {
        this.value = value;
    }

    /**
     * loop throught the other colum to remove all the selects
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public changeSelected(branch: SelectorChoice, otherColumn: Array<SelectorChoice>): void {
        if (branch.connected) {
            return;
        }

        for (const branch of otherColumn) {
            branch.selected = false;
        }

        branch.selected = !branch.selected;
    }

    public appendSelected($element: HTMLElement): void {
        if ($element.classList.contains('disabled')) {
            return;
        }

        for (const element of this._value) {
            if (element.selected) {
                element.chosen = true;
            }
        }
        this.setValue(this._value);
    }

    public removeSelected($element: HTMLElement): void {
        if ($element.classList.contains('disabled')) {
            return;
        }

        for (const element of this._value) {
            if (element.selected) {
                element.chosen = false;
            }
        }
        this.setValue(this._value);
    }

    public mayUse(array: Array<SelectorChoice>): boolean {
        for (const choice of array) {
            if (choice.selected) {
                return true;
            }
        }
        return false;
    }
}
