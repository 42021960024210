import { EventEmitter, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { UtilService } from './util.service';
import { AjaxService } from './ajax.service';

@Injectable({
    providedIn: 'root',
})
export class CmsService {
    public totalComponents = 0;
    public components: Array<object> = [];
    public cssLoading: boolean = false;
    public cssLoaded: boolean = false;
    private loadedCssFiles = [];
    private loadedJsFiles = [];
    private setWebsiteUrl: boolean = false;
    private randomFileNr: string = '';

    public cssDoneLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
    public loadingComponents: boolean = false;

    constructor(private ajax: AjaxService, private util: UtilService, private config: ConfigService) {
        this.randomFileNr = Math.random().toString().replace('0.', '');
    }

    /**
     * setTotalComponents
     *
     * Call this from the app component and make known howmuch CMS components you want to load in
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      total    $number
     * @returns    void
     */
    public setTotalComponents(total: number): void {
        this.totalComponents = total;
    }

    /**
     * registerComponent
     *
     * called from the CMS component
     *
     * @author    Eric van Doorn <Eric@safira.nl>
     * @param     groupId      $number
     * @param     itemId       $number
     * @param     component    $number
     */
    public registerComponent(groupId: number, itemId: number, component: any) {
        // check if its not already registered
        if (this.components.includes({ groupId: groupId, itemId: itemId, component: component })) return;

        // add the component to the componetns array
        this.components.push({ groupId: groupId, itemId: itemId, component: component });

        // when the registered component has reached the total components set, start loading them 1 by 1
        if (this.components.length >= this.totalComponents) {
            this.loadComponents();
        }
    }

    /**
     * loadComponents
     *
     * Loads all CMS components one by one
     *
     * @author    Eric van Doorn <Eric@safira.nl>
     * @param     none
     */
    public async loadComponents(componentToLoad = null) {
        // if already loading, dont start loading again
        if (this.loadingComponents) {
            return;
        }

        this.loadingComponents = true;

        for (let component of this.components) {
            if (componentToLoad && componentToLoad != component['component']) continue;
            // wait until its finished before iterating to next one
            let res = await this.getCmsItemGroup(component['groupId'], component['itemId']);

            // CSS and JS are loaden in in previous step, so load the HTML
            component['component'].html = (<any>res).html;

            // Store the response in the local storage so next time it can be showed direcly without having to wait
            localStorage.setItem(
                btoa(component['groupId'] + '-' + component['itemId']),
                btoa(JSON.stringify(res))
            );

            // store the css files so they can be loaded seperatly on next page load
            // this makes sure we can load all the CSS before loading all components
            this.storeCssFile((<any>res).css);
        }
        this.loadingComponents = false;
    }

    /**
     * getCmsItemGroup
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public getCmsItemGroup(groupId, itemGroupId) {
        let promise = new Promise((resolve, reject) => {
            let data = { groupId, itemGroupId };
            this.ajax.post('cms/getCmsItemGroup', data).then(
                async (res: string) => {
                    // succes
                    res = JSON.parse(res);
                    await this.addCssLinks((<any>res).css, (<any>res).url);
                    this.addJsLinks((<any>res).js, (<any>res).url);

                    if (!this.setWebsiteUrl) {
                        let _html = document.getElementsByTagName('html')[0]
                        let _element: HTMLElement = null;
                        _element = this.util.createElement('script', '', '');
                        (<any>_element).text = 'var websiteUrl = "' + (<any>res).url + '";';
                        _html.appendChild(_element)

                        this.setWebsiteUrl = true;
                    }

                    resolve(res);
                },
                (msg) => {
                    // reject
                    reject(msg);
                }
            );
        });
        return promise;
    }

    /**
     * addCssLinks
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public addCssLinks(cssObject, url) {
        return new Promise((resolve) => {
            let _head = document.getElementsByTagName('head')[0]
            let i = 0;
            let alreadyLoaded = 0;

            Object.keys(cssObject).forEach((key) => {
                if (!this.loadedCssFiles.includes(cssObject[key])) {
                    let _element: HTMLLinkElement = null;
                    _element = <HTMLLinkElement>this.util.createElement('link', '', '');
                    _element.setAttribute('href', url + cssObject[key] + '?' + this.randomFileNr);
                    _element.setAttribute('rel', 'stylesheet');
                    _element.setAttribute('type', 'text/css');
                    _element.setAttribute('media', 'all');
                    _head.appendChild(_element)
                    this.loadedCssFiles.push(cssObject[key]);

                    (<any>_element).onload = () => {
                        i++;
                        if (i == Object.keys(cssObject).length - alreadyLoaded) {
                            resolve(true);
                        }
                    };
                } else {
                    // the css file is already loaded by another component.
                    // +1 on the already loaded so this can be used to resolve. else the i == length above here will never apply
                    alreadyLoaded++;

                    // if this is the last one and already loaded is equal to the length, it means no new CSS files will be loaded. So just resolve
                    if (alreadyLoaded == Object.keys(cssObject).length) {
                        resolve(true);
                    }
                }
            });
        });
    }

    /**
     * addJsLinks
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public addJsLinks(jsObject, url) {
        return new Promise((resolve) => {
            let _head = document.getElementsByTagName('head')[0]

            Object.keys(jsObject).forEach((key) => {
                if (!this.loadedJsFiles.includes(jsObject[key])) {
                    let _element: HTMLScriptElement = null;
                    _element = <HTMLScriptElement>this.util.createElement('script', '', '');
                    _element.setAttribute('src', url + jsObject[key] + '?' + this.randomFileNr);
                    _element.setAttribute('type', 'text/javascript');
                    (<any>_element).async = false;
                    _head.appendChild(_element)
                    this.loadedJsFiles.push(jsObject[key]);
                }
            });

            resolve(true);
        });
    }

    /**
     * storeCssFile
     *
     * stores the array of urls in the storage so it can be used to load in css without having to wait on the API call to be finished
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      urls    $any
     * @returns    void
     */
    public storeCssFile(urls: any): void {
        // get the already stored css files
        let files: any = localStorage.getItem(btoa('css'));

        // if there werent any css files stored yet, create a new array, else parse the files
        if (!files) {
            files = [];
        } else {
            files = JSON.parse(atob(files));
        }

        // check for each provided url if its already present in the css storage, if not, add it
        Object.keys(urls).forEach((key) => {
            if (!files.includes(urls[key])) {
                files.push(urls[key]);
            }
        });

        // write back the new version of the css array to the storage
        localStorage.setItem(btoa('css'), btoa(JSON.stringify(files)));
    }

    /**
     * loadStoredCss
     *
     * loads the CSS urls that are stored in the storage
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      none
     * @returns    Promise<boolean>
     */
    public loadStoredCss(): Promise<boolean> {
        return new Promise(async (resolve) => {
            // get the css files from the storage
            let files: any = localStorage.getItem(btoa('css'));

            // if there arent any css urls stored yet, resolve true to notifiy the loading is done
            if (!files) {
                resolve(true);
            } else {
                // get the files from the storage
                files = JSON.parse(atob(files));

                // setup the URL from CMS where they can be retrieved
                let url = this.config.apiUrl.replace('/api/v2.0', '');

                // add the css links, wait for it to finish
                // inside addCSSLinks, it waits until all elements are loaded before resolving, this should make sure the CSS is active before loading the HTML
                await this.addCssLinks(files, url);

                // resolve true, after this, trigger the HTML and JS to be loaded
                resolve(true);
            }
        });
    }
}
