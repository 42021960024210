import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { AccountService } from '../../services/user/account.service';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.less'],
})
export class SettingsComponent {
    public constructor(
        public router: Router,
        public userService: UserService,
        public account: AccountService
    ) {}
}
