import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';

@Injectable({
    providedIn: 'root',
})
export class SignatureService {
    public constructor(private ajax: AjaxService) {}
    public signature: string = '';

    public sendSignature(): void {
        this.ajax.post('test/testSignature', {
            signature: this.signature,
        });
    }
}
