import { Component } from '@angular/core';

@Component({
  selector: 'blocks-basic',
  templateUrl: './blocks-basic.component.html',
})
export class BlocksBasicComponent {

  constructor() { }
}
