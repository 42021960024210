import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { DragdropComponent } from './dragdrop.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [DragdropComponent],
    imports: [CommonModule, FormsModule, FormModule, DragDropModule],
    exports: [DragdropComponent],
})
export class DragdropModule {}
