import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { accountRouting } from './account.routes';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from '../translate.module';
import { ValidationModule } from '../../core/directives/validation/validation.module';
import { FormModule } from '../../core/components/form/form.module';

@NgModule({
    imports: [accountRouting, CommonModule, FormModule, FormsModule, TranslationModule, ValidationModule],
})
export class AccountModule {}
