import { Component } from '@angular/core';
import { PrintModalService } from '../../services/printmodal.service';

@Component({
    selector: 'modal',
    templateUrl: './printmodal.component.html',
    styleUrls: ['./printmodal.component.less'],
})
export class PrintModalComponent {
    public constructor(public modalService: PrintModalService) {}
}
