import { Injectable } from '@angular/core';
import { CrudServiceModel } from '../../models/crud/crud-service.model';
import { UserGroupModel } from '../../models/user/user-group.model';
import { ToasterService } from '../toaster.service';
import { AjaxService } from '../ajax.service';

@Injectable({
    providedIn: 'root',
})
export class UserGroupService extends CrudServiceModel<UserGroupModel> {
    protected readonly create = UserGroupModel;
    protected readonly module = 'user/group';

    constructor(protected ajax: AjaxService, private toaster: ToasterService) {
        super();
    }

    protected validation(): boolean {
        return true;
    }

    async save(
        object: UserGroupModel[] | UserGroupModel = this.object
    ): Promise<UserGroupModel | UserGroupModel[]> {
        try {
            return super.save(object);
        } catch (error) {
            this.toaster.error(error.message);
        }
    }
}
