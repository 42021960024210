import { ProductComponent } from './../../core/components/product/product.component';
import { FilterComponent } from './../../core/components/filter/filter.component';
import { productsRouting } from './products.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbModule } from 'src/app/core/components/breadcrumb/breadcrumb.module';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { TranslationModule } from '../translate.module';
import { ProductsComponent } from './products/products.component';
import { FooterModule } from 'src/app/core/components/footer/footer.module';
import { PopupModule } from 'src/app/core/components/popup/popup.module';

@NgModule({
    declarations: [ProductsComponent, FilterComponent, ProductComponent],
    imports: [
        productsRouting,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BreadcrumbModule,
        FormsModule, //required for [(ngModel)]
        FormModule, // Custom Safira form module
        PopupModule,
        TranslationModule,
        FooterModule,
    ],
    exports: [ProductComponent],
})
export class ProductsModule {}
