import { NgModule } from '@angular/core';
import { WorkPlannerComponent } from './workplanner.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PopupModule } from '../popup/popup.module';
import { FormModule } from '../form/form.module';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from 'src/app/modules/translate.module';

@NgModule({
    declarations: [WorkPlannerComponent],
    imports: [CommonModule, BrowserModule, PopupModule, FormsModule, FormModule, TranslationModule],
    exports: [WorkPlannerComponent],
})
export class WorkPlannerModule {}
