import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    // Default API URL
    public apiUrl: string = '';

    public isSafira: boolean = false;

    //Public Safira IP
    public safiraIp: string = '195.134.175.56';

    //Local Safira IP
    public safiraIpv4: string = '172.17.0.1';

    // Hestia IP prefix
    public hestiaInternal: string = '10.10.10.';

    // BMS version
    public version: string = '8.17.0';

    // key used for google services, key can be found in safira passwords
    public googleAPIKey = '';

    //Checkbox at login to store either in local or session storage
    public rememberMe: boolean;

    // Shows password value
    public showPassword: boolean = false;

    public isHybrid = false;

	public domainId: number = 0;

    // Icons used for specific input types
    public typeIcons: object = {
        date: 'far fa-calendar',
        time: 'fal fa-clock',
        tel: 'fas fa-phone',
        email: 'fal fa-envelope',
        iban: 'fal fa-credit-card-front',
        popup: 'fal fa-info-circle'
    };

    public constructor(private storage: StorageService) {
        this.rememberMe = this.storage.usingLocalStorage();
        if (location.hostname.includes('portal-accept')) {
            this.apiUrl = 'https://portal-accept.stemidkunststoffen.nl/api/v2.0/';
        } else if (
            location.hostname.includes('stemid-portal-dev') ||
            location.hostname === 'localhost' ||
            location.hostname === '127.0.0.1'
        ) {
            this.apiUrl = 'https://stemid-portal-dev.safira-test.nl/api/v2.0/';
        } else {
            this.apiUrl = 'https://portal.stemidkunststoffen.nl/api/v2.0/';
        }
    }
}
