import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickrDirective } from './datepickr.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [DatePickrDirective],
    exports: [DatePickrDirective],
})
export class DatePickrModule {}
