import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

export enum Breakpoint {
    XXS = 1,
    XS = 420,
    SM = 640,
    MD = 768,
    LG = 1024,
    XL = 1280,
    XXL = 1536,
}

export interface GradeChange {
    from: Breakpoint;
    to: Breakpoint;
}

@Injectable({
    providedIn: 'root',
})
export class ResponsiveService {
    public gradeChanged: Subject<GradeChange> = new Subject<GradeChange>();

    public width: number;
    public grade: string;

    public onResize(width: number): void {
        this.width = width;
        const newGrade: string = this.getGrade();
        if (this.grade !== newGrade) {
            this.gradeChanged.next({ from: Breakpoint[this.grade], to: Breakpoint[newGrade] });
            this.grade = newGrade;
        }
    }

    public subscribeOnBreakpoints(
        breakpoints: Breakpoint[],
        callback: (change?: GradeChange) => void
    ): Subscription {
        return this.gradeChanged.subscribe((grade: GradeChange) => {
            if (breakpoints.find((refresh) => refresh === grade.from || refresh === grade.to) !== undefined) {
                callback(grade);
            }
        });
    }

    public isSize(size: Breakpoint) {
        return this.width >= size;
    }

    public getGrade(): string {
        let grade: string;
        for (const size in Breakpoint) {
            if (+size) {
                if (this.width < +size) {
                    if (grade === undefined) {
                        grade = Breakpoint[size];
                    }
                    return grade;
                }
                grade = Breakpoint[size];
            }
        }
        return grade;
    }
}
