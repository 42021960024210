import { ProductService } from './../../../core/services/product.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
    constructor(private router: Router, public product: ProductService) {}

	ngOnInit(){
	}
}
