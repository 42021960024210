import { Component, Input } from '@angular/core';
import { TextItem } from '../../../models/item.model';

@Component({
    selector: 'sTextItem',
    templateUrl: './text-item.component.html',
    styleUrls: ['./text-item.component.less'],
})
export class TextItemComponent {
    textArray: (string | number)[] = [];

    @Input() data: TextItem;

    isArray(text: (string | number)[] | string | number) {
        return Array.isArray(text);
    }
}
