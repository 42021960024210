import { Component, OnInit, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { UtilService } from '../../services/util.service';

const timeBlockMarginTop: number = 15;

@Component({
    selector: 'sCalendar',
    templateUrl: './delivery-calendar.component.html',
    styleUrls: ['./delivery-calendar.component.less'],
})
export class CalendarComponent implements OnInit {
    public week: Array<any> = [];
    public selectedDay: Array<any> = [];
    public timeRange: Array<string> = [];
    public noTimeBlocks: boolean = false;

    public showLeftArrow: boolean = false;
    public showRightArrow: boolean = true;

    //holds the times for the calendar
    @Input('times') times: Array<any>;

    //holds the default time range for the calendar
    @Input('defaultTimeRange') defaultTimeRange: Array<any>;

    // Call the parent delete function.
    @Output() setDeliveryDay = new EventEmitter();

    public constructor(private renderer: Renderer2, private util: UtilService) {}

    public ngOnInit(): void {
        for (let i: number = 0; i < this.times.length; i++) {
            if (this.times[i]['selected'] == 1) {
                this.selectedDay = this.times[i];

                // If the day is not in the first week we need to scroll to the next week
                const week: Number = Math.ceil(i / 6);
                if (week > 1) {
                    this.scroll('right');
                }

                this.timeRange =
                    this.selectedDay['timeRange'] != null
                        ? this.selectedDay['timeRange']
                        : this.defaultTimeRange;

                break;
            } else if (i == this.times.length - 1) {
                this.noTimeBlocks = true;
            }
        }
    }

    public ngAfterViewInit(): void {
        if (!this.noTimeBlocks) this.calcTimeRangeSpacing(this.timeRange);
    }

    /**
     * Scroll
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public scroll(direction: string) {
        const blocks: HTMLElement = this.util.getElement('.scroll-div-app');
        const offsetLeft: string = window.getComputedStyle(blocks, null).getPropertyValue('left');
        let scrollAmount: number = 600;

        // Check if we are dealing with a mobile device.
        if (window.innerWidth < 768) {
            scrollAmount = 250;
        }

        if (direction === 'right' && offsetLeft == '0px') {
            this.renderer.setStyle(blocks, 'left', '-' + scrollAmount + 'px');
            this.showRightArrow = false;
            this.showLeftArrow = true;
        } else if (direction === 'left' && offsetLeft == '-' + scrollAmount + 'px') {
            this.renderer.setStyle(blocks, 'left', '0px');
            this.showRightArrow = true;
            this.showLeftArrow = false;
        }
    }

    /**
     * selectDay
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public selectDay(day: any): void {
        this.selectedDay = day;

        for (let i: number = 0; i < this.times.length; i++) {
            if (this.times[i].selected == true) {
                this.times[i]['selected'] = false;
            } else if (this.times[i].day == day.day) {
                this.times[i]['selected'] = true;
            }
        }

        this.timeRange =
            this.selectedDay['timeRange'] != null ? this.selectedDay['timeRange'] : this.defaultTimeRange;

        // Timeout of 0 to fix a timings issue.
        setTimeout(() => {
            this.calcTimeRangeSpacing(this.timeRange);
        }, 0);
    }

    /**
     * setDeliveryTime
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public setDeliveryTime(time: any, selectedDay: any, e: Event): void {
        this.setDeliveryDay.emit({ time, selectedDay });
        const $activeTimeBlock: HTMLElement = this.util.getElement('.active-time');

        if ($activeTimeBlock != undefined) this.util.removeClass($activeTimeBlock, 'active-time');

        const $target = <HTMLElement>e.target;
        if ($target.classList.contains('time-block')) this.util.addClass($target, 'active-time');
        else this.util.addClass(this.util.findParentWithClass($target, 'time-block'), 'active-time');

        const $deliveryMoment: HTMLElement = this.util.getElement('.delivery-moment');
        const $deliveryMomentBlock: HTMLElement = this.util.getChild($deliveryMoment, '.white-block');

        this.util.addClass($deliveryMomentBlock, 'highlight');

        setTimeout(() => {
            this.util.removeClass($deliveryMomentBlock, 'highlight');
        }, 2000);
    }

    /**
     * calcTimeRangeSpacing
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    private calcTimeRangeSpacing(timeRange: Array<any>): void {
        const totalHours: number = timeRange.length;
        if (totalHours == 0) {
            return;
        }

        // Set position of the hours
        document.querySelectorAll('div.hour-container').forEach((element, eKey) => {
            // FORMULA = ((100% / totalHours - 1) * element index)
            let position: number = (100 / (totalHours - 1)) * eKey;
            this.renderer.setStyle(element, 'left', position + '%');
        });

        this.setTimeRowsAndBlocks();
    }

    /**
     * setTimeRowsAndBlocks
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    private setTimeRowsAndBlocks(): void {
        const $elements: NodeListOf<Element> = document.querySelectorAll('div.time-block');
        // Counter for the $elements
        let z: number = 0;
        if (this.selectedDay['rows'] == undefined) return;

        for (let i: number = 0; i < this.selectedDay['rows'].length; i++) {
            for (let x: number = 0; x < this.selectedDay['rows'][i].length; x++) {
                let element: HTMLElement = this.util.getElement('.hour');
                // FORMULA = ((timeRangeHeight) + row * (timeBlockHeight + marginTop))
                this.renderer.setStyle(
                    $elements[z],
                    'top',
                    element.offsetHeight +
                        i * ((<HTMLElement>$elements[z]).offsetHeight + timeBlockMarginTop) +
                        'px'
                );

                this.renderer.setStyle(
                    $elements[z],
                    'left',
                    this.selectedDay['rows'][i][x]['offset']['offset'] + '%'
                );
                this.renderer.setStyle(
                    $elements[z],
                    'width',
                    'calc(' + this.selectedDay['rows'][i][x]['offset']['blockLength'] + '% - 10px)'
                );
                this.util.addClass(<HTMLElement>$elements[z], 'visible');

                z++;
            }
        }
    }
}
