import { BreadcrumbService } from './../../../../core/services/breadcrumb-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from '../../../../core/models/user/user.model';
import { Breakpoint, ResponsiveService } from '../../../../core/services/responsive.service';
import { UserService } from '../../../../core/services/user/user.service';
import { RowItem, TextItem } from '../../../../core/models/item.model';
import { TableService } from '../../../../core/services/table.service';
import { AccountService } from '../../../../core/services/user/account.service';
import { AuthenticateService } from '../../../../core/services/auth/authenticate.service';
import { TableColumn } from '../../../../core/models/table.model';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.less'],
})
export class UserListComponent implements OnInit, OnDestroy {
    public isSubAccounts: boolean = false;
    private subscription: Subscription = null;

    button: TextItem = {
        type: 'text',
        icon: 'user-plus fa-lg',
        text: 'global_create',
        isButton: true,
        action: {
            type: 'click',
            click: () => {
                let subPage = this.router.url.match(/^\/admin\/users(\/*[0-9]{1,6}\/sub-accounts){0,1}$/);
                if (subPage[1] && this.userService.object) {
                    this.router.navigate([`admin/users/${this.userService.object.userId}/sub-accounts/new`]);
                } else {
                    this.router.navigate(['admin/users/new']);
                }
                return false;
            },
        },
    };

    constructor(
        public tableService: TableService,
        public accountService: AccountService,
        private userService: UserService,
        private authenticate: AuthenticateService,
        private responsive: ResponsiveService,
        private router: Router,
        private breadcrumb: BreadcrumbService,
        private activatedRoute: ActivatedRoute
    ) {}

    async ngOnInit() {
        if (this.breadcrumb.pages.length == 0 || this.breadcrumb.pages[0].text !== 'itemUser.title') {
            this.breadcrumb.pushPage('itemUser.title', '/admin/users');
        }
        this.isSubAccounts = this.router.url.includes('/sub-accounts');
        if (this.isSubAccounts) {
            this.subscription = this.activatedRoute.params.subscribe(async (params) => {
                if (!this.userService.object || params.userId !== this.userService.object.userId) {
                    await this.userService.get(params.userId, '.page-content');
                }
                if (
                    this.breadcrumb.pages.findIndex((bc) => bc.text == this.userService.object.fullName) ===
                    -1
                ) {
                    this.breadcrumb.pushPage(
                        this.userService.object.fullName,
                        `/admin/users/${this.userService.object.userId}`
                    );
                }
                if (this.breadcrumb.pages.findIndex((bc) => bc.text == 'Subaccounts') === -1) {
                    this.breadcrumb.pushPage(
                        'Subaccounts',
                        `/admin/users/${this.userService.object.userId}/sub-accounts`
                    );
                }

                this.loadTable();
            });
        } else {
            this.breadcrumb.pages.splice(1, this.breadcrumb.pages.length - 1);
            this.loadTable();
        }
    }

    public async loadTable() {
        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [Breakpoint.MD], row: [Breakpoint.MD] },
            },
            this.mapColumns.bind(this),
            this.mapUsersToTable.bind(this),
            this.isSubAccounts ? await this.userService.getAllSubAccounts() : await this.userService.getAll()
        );
        this.tableService.addSearches([
            { searchOn: 'fullName', accuracy: 80 },
            { searchOn: 'email', accuracy: 80 },
        ]);
    }
    ngOnDestroy() {
        this.breadcrumb.pages = [];
        this.tableService.destroyTable();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    private mapColumns(): TableColumn[] {
        return [
            {
                item: { type: 'text', text: 'itemUser.name' },
                sortOn: 'fullName',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
            {
                item: { type: 'text', text: 'itemUser.email' },
                sortOn: 'email',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
            {
                item: { type: 'text', text: 'itemUser.verified' },
                position: 'center',
                sortOn: 'verified',
                width: 15,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
            {
                item: { type: 'text', text: 'itemUser.actions' },
                position: 'center',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
        ];
    }

    public setButtonRoute() {}

    /**
     * Map users to table format
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapUsersToTable(user: UserModel): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                colspan: 2,
                gap: 10,
                closedHeight: 16.8,
                openHeight: 83.6,
            },
            items: [
                { type: 'text', fontWeight: 600, text: user.fullName, expansion: 'hidden' },
                {
                    type: 'group',
                    gap: 6,
                    expansion: 'expandable',
                    show: !this.responsive.isSize(Breakpoint.MD),
                    items: [
                        { type: 'text', fontWeight: 600, text: user.fullName },
                        {
                            type: 'bool',
                            bool: user.verified,
                            icon: 'user-check fa-sm',
                            color: 'success',
                            falseIcon: 'user-slash fa-sm',
                            falseColor: 'default',
                        },
                    ],
                },
                { type: 'text', text: user.email, show: !this.responsive.isSize(Breakpoint.MD) },
                {
                    type: 'text',
                    text: user.email,
                    hover: true,
                    show: this.responsive.isSize(Breakpoint.MD),
                    action: {
                        type: 'anchor',
                        route: 'mailto:' + user.email,
                        target: 'top',
                    },
                },
                {
                    type: 'bool',
                    bool: user.verified,
                    icon: 'user-check',
                    color: 'success',
                    falseIcon: 'user-slash',
                    falseColor: 'default',
                    position: 'center',
                    show: this.responsive.isSize(Breakpoint.MD),
                },
                {
                    type: 'group',
                    position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                    expansion: 'expandable',
                    items: [
                        {
                            type: 'text',
                            hidden: user.adminAccount,
                            show: !user.adminAccount || this.responsive.isSize(Breakpoint.MD),
                            icon: 'sign-out',
                            iconBorder: true,
                            iconHover: true,
                            title: 'itemUser.loginAs',
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'group',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'itemUser.loginAs',
                                        },
                                        { type: 'text', text: user.fullName },
                                    ],
                                },
                                form: {
                                    routeTo: '/',
                                    submitLabel: 'itemUser.submit_login_as',
                                    inputs: [
                                        {
                                            type: 'form',
                                            inputType: 'password',
                                            text: 'password',
                                            name: 'password',
                                            data: null,
                                            required: true,
                                        },
                                    ],
                                    submit: async (
                                        data: any,
                                        formData: Map<string, any>
                                    ): Promise<boolean> => {
                                        if (
                                            formData.get('password') === null ||
                                            formData.get('password').length <= 1
                                        ) {
                                            return false;
                                        }
                                        await this.authenticate.loginAs(
                                            user.userId,
                                            formData.get('password')
                                        );
                                    },
                                },
                            },
                        },
                        {
                            type: 'text',
                            icon: 'pen',
                            iconHover: true,
                            iconBorder: true,
                            action: {
                                type: 'route',
                                route: './' + user.userId,
                            },
                            title: 'global_edit',
                        },
                        {
                            type: 'text',
                            icon: 'trash-alt',
                            iconBorder: true,
                            hidden: user.userId === this.accountService.object.userId,
                            iconHover: true,
                            title: 'global_delete',
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'group',
                                    items: [
                                        {
                                            type: 'text',
                                            text: 'itemUser.delete',
                                        },
                                        { type: 'text', text: user.fullName },
                                    ],
                                },
                                form: {
                                    routeTo: './',
                                    submitLabel: 'itemUser.submit_delete',
                                    inputs: [
                                        {
                                            type: 'form',
                                            inputType: 'checkbox',
                                            text: 'itemUser.confirm_delete',
                                            name: 'confirm',
                                            data: null,
                                            required: true,
                                        },
                                    ],
                                    submit: async (data: any, formData: Map<string, any>) => {
                                        if (formData.get('confirm')) {
                                            await this.userService.delete(user);
                                            this.tableService.setObjects(
                                                this.isSubAccounts
                                                    ? await this.userService.getAllSubAccounts()
                                                    : await this.userService.getAll()
                                            );
                                            return true;
                                        }
                                        return false;
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    }
}
