import { TranslationModule } from './../../../modules/translate.module';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemModule } from '../item/item.module';
import { FormModule } from '../form/form.module';

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, ItemModule, FormModule, RouterModule, TranslationModule],
    exports: [FooterComponent],
})
export class FooterModule {}
