import { Component, Input } from '@angular/core';
import { PopupAction } from '../../../../models/action.model';

@Component({
    selector: 'sPopupAction',
    templateUrl: './popup-action.component.html',
    styleUrls: ['./popup-action.component.less'],
})
export class PopupActionComponent {
    @Input() action: PopupAction;

    isOpen: boolean = false;
}
