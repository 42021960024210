import {
    AfterViewChecked,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AjaxService} from '../../services/ajax.service';
import {UtilService} from '../../services/util.service';
import {TranslateService} from '../../services/translate.service';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import {LanguageService} from '../../services/language.service';

// Arguments are for testing. Remove them in production.
const today = new Date(2020, 9, 14);

/**
 * Gets the ISO week number for a date
 *
 * @author    https://stackoverflow.com/a/6117889
 */
function getWeekNumber(date: Date): number {
    // Copy date to not modify the original
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    // Get first day of year
    const yearStart: number = Date.UTC(date.getUTCFullYear(), 0, 1);
    // Calculate and return the week number
    return Math.ceil(((date.getTime() - yearStart) / (24 * 60 * 60 * 1000) + 1) / 7);
}

interface DateStrings {
    short: string;
    long: string;
    dayClass: string;
}

interface DateCell {
    name: string;
    columnSpan: number;
    dateNumber?: number;
}

interface DayCell extends DateCell {
    dateString: DateStrings;
    today: boolean;
}

interface WorkPreparerRow {
    type: 'preparer';
    id?: number;
    name: string;
    dayPartNumbers: Array<number>;
    dayPartsOff: Set<string>;
    firstPhasePlanned: {
        [dayPart: string]: string;
    };
    open: boolean;
}

interface ProjectRow {
    type: 'project';
    preparer: WorkPreparerRow;
    id: number;
    name: string;
    firstPhasePlanned: {
        [dayPart: string]: string;
    };
    open: boolean;
}

type PlannedDay = Array<{
    type: string;
    hasComments: boolean;
} | null>;

interface PhaseRow {
    type: 'phase';
    project: ProjectRow;
    id: number;
    name: string;
    daysPlanned: {
        [date: string]: PlannedDay;
    };
    deadlines: {
        [date: string]: string;
    };
}

type WorkPlannerRow = WorkPreparerRow | ProjectRow | PhaseRow;

@Component({
    selector: 'sWorkPlanner',
    templateUrl: './workplanner.component.html',
    styleUrls: ['./workplanner.component.less'],
})
export class WorkPlannerComponent implements OnInit, AfterViewChecked, OnDestroy {
    public phases: Array<string> = [
        'preorder',
        'draw',
        'measurement',
        'approval',
        'order',
        'views',
        'saw',
        'production',
        'start',
        'delivery',
    ];
    // The range of dates to show
    public startDate: Date;
    public endDate: Date;
    // The months to show in the header
    public months: Array<DateCell> = [];
    // The weeks to show in the header
    public weeks: Array<DateCell> = [];
    // The days to show in the header
    public days: Array<DayCell> = [];
    // The planning data from the API
    public planning: Array<any> = null;
    // The work preparers/projects/phases to show in the table
    public planningRows: Array<WorkPlannerRow> = [];
    // The day the user currently is hovering over
    public hoveringDay: DayCell;
    // Information about currently editing phase
    public currentlyEditing: {
        dateString: DateStrings;
        phase: PhaseRow;
        comments: Array<string>;
        setDayParts: Array<string>;
        editingComment: number;
    };
    private dayClassNames: Array<string> = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    // The amount of past days to show
    private pastDays: number = 30;
    // The amount of future days to show
    private futureDays: number = 120;
    // The IDs of work planners to show their projects of
    private foldedOutWorkPlanners: Set<number> = new Set();
    // The IDs of projects to show their phases of
    private foldedOutProjects: Set<number> = new Set();
    // If the browser should scroll to the today column when ngAfterViewChecked is called
    private scrollToToday = false;
    // Ends all subscriptions
    private destroyed$: ReplaySubject<void> = new ReplaySubject(1);
    @ViewChild('editPopup', {read: ElementRef, static: true})
    private editPopup: ElementRef;

    public constructor(
        private ajax: AjaxService,
        private erf: ElementRef,
        private util: UtilService,
        private translate: TranslateService,
        private language: LanguageService
    ) {
    }

    public ngOnInit(): void {
        this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - this.pastDays);
        this.endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.futureDays);

        this.getPlanning();

        this.language.languageChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.setDateColumns(this.startDate, this.endDate);
            this.setPlanningRows();
        });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public ngAfterViewChecked(): void {
        if (!this.scrollToToday) {
            return;
        }
        this.scrollToToday = false;

        const $ele = <HTMLElement>this.erf.nativeElement;
        $ele.querySelector('th.today').scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'center',
        });
    }

    /**
     * Fills the `months`, `weeks` and `days` properties.
     *
     * Weekend days are skipped.
     *
     * @author    Mike van Os <mike@safira.nl>
     * @param     startDate    $The    first date to show
     * @param     endDate      $The    final date to show
     */
    private setDateColumns(startDate: Date, endDate: Date): void {
        this.months.length = 0;
        this.weeks.length = 0;
        this.days.length = 0;

        const currDate = new Date(startDate);

        const monthFormatter = Intl.DateTimeFormat(this.language.current.iso, {
            month: 'long',
        });
        const dayFormatter = Intl.DateTimeFormat(this.language.current.iso, {
            weekday: 'short',
            day: 'numeric',
        });

        let currMonth: DateCell = {
            name: monthFormatter.format(startDate),
            columnSpan: 0,
            dateNumber: startDate.getMonth(),
        };
        const firstWeek = getWeekNumber(currDate);
        let currWeek: DateCell = {
            name: 'Week ' + firstWeek,
            columnSpan: 0,
            dateNumber: firstWeek,
        };
        while (currDate <= endDate) {
            if (currDate.getDay() !== 0 && currDate.getDay() !== 6) {
                if (currDate.getMonth() === currMonth.dateNumber) {
                    currMonth.columnSpan += 1;
                } else {
                    this.months.push(currMonth);
                    currMonth = {
                        name: monthFormatter.format(currDate),
                        columnSpan: 1,
                        dateNumber: currDate.getMonth(),
                    };
                }

                const weekNumber: number = getWeekNumber(currDate);
                if (weekNumber === currWeek.dateNumber) {
                    currWeek.columnSpan += 1;
                } else {
                    this.weeks.push(currWeek);
                    currWeek = {
                        name: this.translate.getParsedResult('itemAgenda.week_number', [weekNumber]),
                        columnSpan: 1,
                        dateNumber: weekNumber,
                    };
                }

                this.days.push({
                    name: dayFormatter.format(currDate),
                    columnSpan: 1,
                    dateString: {
                        short: this.dateToShortString(currDate),
                        long: this.dateToLongString(currDate),
                        dayClass: this.dayClassNames[currDate.getDay()],
                    },
                    today: this.dateToShortString(today) === this.dateToShortString(currDate),
                });
            }

            currDate.setDate(currDate.getDate() + 1);
        }

        this.months.push(currMonth);
        this.weeks.push(currWeek);
    }

    /**
     * Fills `planningRows` based on the `planning` and `days` properties.
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public setPlanningRows(): void {
        this.planningRows.length = 0;

        if (this.planning == null) {
            return;
        }

        for (const preparer of this.planning) {
            const partsPerDay: number = Number.parseInt(preparer['dayPartsPerDay'], 10);
            const dayPartNumbers = [];

            for (let part = 0; part < partsPerDay; part += 1) {
                dayPartNumbers.push(part);
            }

            const preparerRow: WorkPreparerRow = {
                type: 'preparer',
                id: preparer['id'],
                name: preparer['name'],
                dayPartsOff: new Set(),
                firstPhasePlanned: {},
                open: this.foldedOutWorkPlanners.has(preparer['id']),
                dayPartNumbers,
            };

            this.planningRows.push(preparerRow);

            for (const dayPartOff of preparer['dayPartsOff']) {
                preparerRow.dayPartsOff.add(dayPartOff);
            }

            for (const project of preparer['projects']) {
                const projectRow: ProjectRow = {
                    type: 'project',
                    preparer: preparerRow,
                    id: project['id'],
                    name: project['name'],
                    firstPhasePlanned: {},
                    open: this.foldedOutProjects.has(project['id']),
                };

                if (preparerRow.open) {
                    this.planningRows.push(projectRow);
                }

                for (const phase of project['phases']) {
                    const phaseRow: PhaseRow = {
                        type: 'phase',
                        project: projectRow,
                        id: phase['id'],
                        name: phase['name'],
                        daysPlanned: {},
                        deadlines: phase['deadlines'],
                    };

                    if (preparerRow.open && projectRow.open) {
                        this.planningRows.push(phaseRow);
                    }

                    for (const day of this.days) {
                        const plannedDay: PlannedDay = [];
                        phaseRow.daysPlanned[day.dateString.short] = plannedDay;

                        if (day.dateString.short in phase['plannedDays']) {
                            for (const part of preparerRow.dayPartNumbers) {
                                if (phase['plannedDays'][day.dateString.short][part] == null) {
                                    plannedDay[part] = {
                                        hasComments: false,
                                        type: '',
                                    };
                                    continue;
                                }

                                const plannedDayPart: any = phase['plannedDays'][day.dateString.short][part];
                                plannedDay[part] = {
                                    hasComments: plannedDayPart['hasComments'],
                                    type: plannedDayPart['type'],
                                };

                                const dayPart = day.dateString.short + ',' + part;

                                // In the preparer row show the color of the last planned phase
                                preparerRow.firstPhasePlanned[dayPart] = plannedDay[part]['type'];

                                // In the project row show the color of the last planned phase
                                projectRow.firstPhasePlanned[dayPart] = plannedDay[part]['type'];
                            }
                        } else {
                            for (const part of preparerRow.dayPartNumbers) {
                                plannedDay[part] = {
                                    hasComments: false,
                                    type: '',
                                };

                                const dayPart = day.dateString.short + ',' + part;

                                if (!(dayPart in preparerRow.firstPhasePlanned)) {
                                    preparerRow.firstPhasePlanned[dayPart] = '';
                                }
                                if (!(dayPart in projectRow.firstPhasePlanned)) {
                                    projectRow.firstPhasePlanned[dayPart] = '';
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Makes Visual Studio Code think that the row is a work preparer row
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public asWorkPreparer(row: WorkPlannerRow): WorkPreparerRow {
        return row as WorkPreparerRow;
    }

    /**
     * Makes Visual Studio Code think that the row is a project row
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public asProject(row: WorkPlannerRow): ProjectRow {
        return row as ProjectRow;
    }

    /**
     * Makes Visual Studio Code think that the row is a phase row
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public asPhase(row: WorkPlannerRow): PhaseRow {
        return row as PhaseRow;
    }

    /**
     * Toggles visibility of the projects of a work preparer
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public toggleWorkPreparer(row: WorkPlannerRow): void {
        const id: number = (row as WorkPreparerRow).id;
        if (this.foldedOutWorkPlanners.has(id)) {
            this.foldedOutWorkPlanners.delete(id);
        } else {
            this.foldedOutWorkPlanners.add(id);
        }
        this.setPlanningRows();
    }

    /**
     * Toggles visibility of the phases of a project
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public toggleProjects(row: WorkPlannerRow): void {
        const id: number = (row as ProjectRow).id;
        if (this.foldedOutProjects.has(id)) {
            this.foldedOutProjects.delete(id);
        } else {
            this.foldedOutProjects.add(id);
        }
        this.setPlanningRows();
    }

    /**
     * Shows a popup to edit the clicked phase date
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public showEditPopup(dateString: DateStrings, phase: PhaseRow): void {
        this.getComments(phase.id, dateString.short).then((comments) => {
            this.currentlyEditing = {
                comments,
                phase,
                dateString,
                setDayParts: phase.daysPlanned[dateString.short].map((planned) => planned.type),
                editingComment: null,
            };
            this.util.showPopup(this.editPopup.nativeElement);
        });
    }

    /**
     * Changes which day part to show the comment edit form of
     *
     * @author    Mike van Os <mike@safira.nl>
     * @param     part    $The    day part number
     */
    public setEditingComment(part: number): void {
        if (this.currentlyEditing.editingComment === part) {
            this.currentlyEditing.editingComment = null;
        } else {
            this.currentlyEditing.editingComment = part;
        }
    }

    /**
     * Get the planning data from the API and render the table
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public getPlanning(): void {
        this.ajax
            .post('/workPlanner/getPlanning', {
                startDate: this.dateToShortString(this.startDate),
                endDate: this.dateToShortString(this.endDate),
            })
            .then((planning: Array<any>) => {
                this.planning = planning;
                this.scrollToToday = true;

                this.setPlanningRows();
            });

        this.setDateColumns(this.startDate, this.endDate);
    }

    /**
     * Toggle a day part off for a preparer
     *
     * @author    Mike van Os <mike@safira.nl>
     * @param     dateString    $The    date in `Y-m-d` format
     */
    public toggleDayOff(workPreparer: WorkPreparerRow, dateString: DateStrings, dayPart: number): void {
        const dayPartStr = dateString.short + ',' + dayPart;
        if (workPreparer.firstPhasePlanned[dayPartStr]) {
            alert(this.translate.getParsedResult('itemAgenda.day_part_already_planned'));
            return;
        }

        if (workPreparer.id == null) {
            return;
        }

        this.ajax
            .post('/workPlanner/setDayOff', {
                preparerId: workPreparer.id,
                dayOff: !workPreparer.dayPartsOff.has(dayPartStr),
                date: dateString.short,
                startDate: this.dateToShortString(this.startDate),
                endDate: this.dateToShortString(this.endDate),
                dayPart,
            })
            .then((planning: Array<any>) => {
                this.planning = planning;
                this.setPlanningRows();
            });
    }

    /**
     * Get comments for a phase date
     *
     * @author    Mike van Os <mike@safira.nl>
     * @param     dateString    $The    date in `Y-m-d` format
     */
    public getComments(phaseId: number, dateString: string): Promise<Array<string>> {
        return <Promise<Array<string>>>this.ajax.post('/workPlanner/getComments', {
            phaseId,
            date: dateString,
        });
    }

    /**
     * Saves the day that the user is currently editing
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public saveDay(): void {
        const popup: HTMLElement =
            typeof this.editPopup.nativeElement === 'string' ? this.util.getElement('#' + this.editPopup.nativeElement) : this.editPopup.nativeElement;
        popup.classList.add('hide');

        document.body.style.height = 'none';
        document.body.style.overflow = 'auto';

        const day: any = {
            phaseId: this.currentlyEditing.phase.id,
            date: this.currentlyEditing.dateString.short,
            dayParts: [],
            startDate: this.dateToShortString(this.startDate),
            endDate: this.dateToShortString(this.endDate),
        };
        for (const part of this.currentlyEditing.phase.project.preparer.dayPartNumbers) {
            const partType = this.currentlyEditing.setDayParts[part];
            day.dayParts.push({
                type: partType,
                comment: partType === '' ? '' : this.currentlyEditing.comments[part],
            });
        }

        this.ajax.post('/workPlanner/saveDay', day).then((planning: Array<any>) => {
            this.planning = planning;
            this.setPlanningRows();
        });
    }

    /**
     * Converts a date to a string in `Y-m-d` format
     *
     * @author    Mike van Os <mike@safira.nl>
     * @param     date    $The    date
     */
    public dateToShortString(date: Date): string {
        const month: string = String(date.getMonth() + 1).padStart(2, '0');
        const day: string = String(date.getDate()).padStart(2, '0');

        return String(date.getFullYear()) + '-' + month + '-' + day;
    }

    /**
     * Converts a date to a string in long format
     *
     * @author    Mike van Os <mike@safira.nl>
     * @param     date    $The    date
     */
    public dateToLongString(date: Date): string {
        var formatter = Intl.DateTimeFormat(this.language.current.iso, {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
        return formatter.format(date);
    }
}
