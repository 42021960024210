import { Component, Input } from '@angular/core';
import { AnchorAction } from '../../../../models/action.model';

@Component({
    selector: 'sAnchorAction',
    templateUrl: './anchor-action.component.html',
    styleUrls: ['anchor-action.component.less'],
})
export class AnchorActionComponent {
    @Input() action: AnchorAction;
}
