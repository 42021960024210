import { ProductService } from '../../../core/services/product.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'my-orders-overview',
    templateUrl: './my-orders-overview.component.html',
    styleUrls: ['./my-orders-overview.component.less'],
})
export class MyOrdersOverviewComponent implements OnInit {
    public orders;

    constructor(public productService: ProductService, private router: Router) {}

    async ngOnInit() {
        this.orders = await this.productService.getAllOrders();
    }
}
