import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudServiceModel } from '../models/crud/crud-service.model';
import { TranslationModel } from '../models/translation.model';
import { AjaxService } from './ajax.service';
import { LanguageService } from './language.service';

export type Language =
    | string[]
    | {
          [module: string]: string[];
      };

interface Languages {
    [language: string]: Language;
}

export class ItemGroupsModel {
    title: string;
    description: string;
    item_module_id: number;
}

@Injectable({
    providedIn: 'root',
})
export class TranslationService extends CrudServiceModel<TranslationModel> {
    public languages: Languages = {};
    public translation: TranslationModel;
    public mode: string;
    public itemGroup: ItemGroupsModel;
    public itemGroups: ItemGroupsModel[] = [];

    translations: TranslationModel[];
    labels: TranslationModel[];

    protected readonly create = TranslationModel;
    protected readonly module: string = 'translation';

    public constructor(
        protected ajax: AjaxService,
        public router: Router,
        public route: ActivatedRoute,
        private language: LanguageService
    ) {
        super();
    }

    protected validation(): boolean {
        return true;
    }

    public async getAll() {
        return (this.translations = await super.getAll());
    }

    public async getAllLabels(): Promise<TranslationModel[]> {
        await this.getAll();

        this.labels = [];
        const savedLabels: string[] = [];

        for (const translation of this.translations) {
            const label = (translation.module ?? '') + translation.label;
            if (
                savedLabels.findIndex((savedLabel) => {
                    return savedLabel === label;
                }) < 0
            ) {
                savedLabels.push(label);
                this.labels.push(translation);
            }
        }
        return this.labels;
    }

    public async autoTranslate(input, targetLang, sourceLang = 'en') {
        const res = await fetch('https://libretranslate.de/translate', {
            method: 'POST',
            body: JSON.stringify({
                q: input,
                source: sourceLang,
                target: targetLang,
                format: 'text',
            }),
            headers: { 'Content-Type': 'application/json' },
        });
        return res.json();
    }

    /**
     * Fetches the language file
     *
     * @author    damian
     */
    public async retrieveTranslations() {
        this.languages[this.language.current.iso] = (await this.ajax.get(
            'language/getLabels/' + this.language.current.iso
        )) as Language;
    }
}
