import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';
import { ToasterService } from '../../../core/services/toaster.service';

@Component({
    selector: 'activate',
    template: '',
})
export class UnblockComponent implements OnInit {
    private token: string;

    public constructor(
        private authenticate: AuthenticateService,
        private route: ActivatedRoute,
        private toast: ToasterService,
        private router: Router
    ) {
        this.route.params.subscribe((params) => {
            this.token = params['token'];
        });
    }

    async ngOnInit() {
        await this.authenticate.unblockAccount(this.token);
        this.toast.success('Account gedeblokkeerd');
        await this.router.navigate(['/login']);
    }
}
