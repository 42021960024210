import { CrudModel } from '../crud/crud.model';
import { UserAddressModel } from './user-address.model';
import { UserGroupModel } from './user-group.model';

export class UserModel extends CrudModel<UserModel> {
    public userId: number;
    public username: string;
    public email: string;
    public adminAccount: boolean; // TODO change into isAdmin
    public verified: boolean; // TODO change into isVerified
    public firebaseId: boolean;
    public fullName: string;
    public firstName: string;
    public insertion: string;
    public surname: string;
    public dayOfBirth: string;
    public phoneHome: string;
    public phoneMobile: string; // TODO remove a phone number or make separate table
    public companyName: string;
    public batch: number;
	public parentId: number;
    public userGroups: UserGroupModel[] = [];
    public addresses: UserAddressModel[] = [];

    public readonly excluded: string[] = ['adminAccount', 'verified', 'fullName', 'deleted'];
}
