import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowItem, TextItem } from '../../../core/models/item.model';
import { TableColumn } from '../../../core/models/table.model';
import { TranslationModel } from '../../../core/models/translation.model';
import { TranslationService } from '../../../core/services/translation.service';
import { Form, FormInput } from '../../../core/models/form-item.model';
import { UtilService } from '../../../core/services/util.service';
import { Breakpoint, ResponsiveService } from '../../../core/services/responsive.service';
import { BreadcrumbService } from '../../../core/services/breadcrumb-service.service';
import { LanguageService } from '../../../core/services/language.service';
import { TableService } from '../../../core/services/table.service';
import { ModuleModel } from '../../../core/models/module.model';
import { ModuleService } from '../../../core/services/module.service';

@Component({
    selector: 'translation',
    templateUrl: './translation.component.html',
})
export class TranslationComponent implements OnInit, OnDestroy {
    navigation: TextItem = {
        type: 'text',
        text: ['global_manage', ' ', 'global_options'],
        action: {
            type: 'dropdown',
            indicator: {},
            openOnHover: true,
            items: [
                {
                    type: 'text',
                    text: ['global_manage', ' ', 'itemTranslation.language'],
                    padding: '12px 16px',
                    action: {
                        type: 'route',
                        route: '../languages',
                    },
                },
                {
                    type: 'text',
                    text: ['global_manage', ' ', 'itemTranslation.module'],
                    padding: '12px 16px',
                    action: {
                        type: 'route',
                        route: '../modules',
                    },
                },
            ],
        },
    };

    button: TextItem;

    private translation: TranslationModel;
    private modules: ModuleModel[];

    constructor(
        public util: UtilService,
        public breadCrumb: BreadcrumbService,
        private tableService: TableService,
        private translationService: TranslationService,
        private language: LanguageService,
        private moduleService: ModuleService,
        private responsive: ResponsiveService
    ) {}

    async ngOnInit() {
        this.breadCrumb.pushPage('itemMenu.translations');
        this.modules = await this.moduleService.getAll();

        if (!this.language.languages) await this.language.getAll();

        this.setCreateButton();

        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [], row: [Breakpoint.MD] },
            },
            this.mapColumns.bind(this),
            this.mapRows.bind(this),
            await this.translationService.getAllLabels()
        );

        this.tableService.addSearches([{ searchOn: 'label', accuracy: 80 }, { searchOn: 'value' }]);
        this.tableService.addFilter([{ filterOn: 'module' }]);
    }

    ngOnDestroy() {
        this.breadCrumb.popPage();
    }

    private mapColumns(): TableColumn[] {
        return [
            {
                item: { type: 'text', text: 'itemTranslation.module' },
                sortOn: 'module',
                width: 30,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 35,
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.label' },
                sortOn: 'label',
                width: 30,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 35,
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.actions' },
                position: 'center',
                width: 20,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
        ];
    }

    /**
     * Map users to table format
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapRows(translation: TranslationModel): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                colspan: 3,
                gap: 10,
                closedHeight: 16.8,
                openHeight: 83.6,
            },
            items: [
                { type: 'text', text: translation.module ?? 'global' },
                { type: 'text', text: translation.label + ' ' },
                {
                    type: 'group',
                    position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                    expansion: 'expandable',
                    items: [
                        {
                            type: 'text',
                            icon: 'pen',
                            position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                            iconHover: true,
                            iconBorder: true,
                            title: 'global_edit',
                            breakpoint: { breakpoint: Breakpoint.MD, state: 'after' },
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'text',
                                    text: ['global_edit', ' ', 'itemTranslation.translation'],
                                },
                                form: this.getForm(translation),
                            },
                        },
                        {
                            type: 'text',
                            icon: 'trash-alt',
                            iconBorder: true,
                            iconHover: true,
                            title: 'itemTranslation.delete_translation',
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'text',
                                    text: ['itemTranslation.delete_translation', ' ', translation.label],
                                },
                                items: [{ type: 'text', text: ['itemTranslation.delete_info'] }],
                                form: {
                                    routeTo: './',
                                    submitLabel: 'global.global_delete',
                                    inputs: [],
                                    submit: async () => {
                                        await this.translationService.delete(translation);
                                        this.tableService.setObjects(await this.translationService.getAll());
                                        return true;
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    }

    private setCreateButton() {
        this.translation = new TranslationModel(this.translationService.save.bind(this.translationService));

        this.button = {
            type: 'text',
            text: ['global_create', ' ', 'itemTranslation.translation'],
            isButton: true,
            action: {
                type: 'popup',
                title: { type: 'text', text: ['global_create', ' ', 'itemTranslation.translation'] },
                form: this.getForm(this.translation),
            },
        };
    }

    private getForm(translation: TranslationModel): Form {
        return {
            routeTo: './',
            submitLabel: 'global_save_changes',
            inputs: [
                {
                    type: 'form',
                    inputType: 'select',
                    text: 'itemTranslation.module',
                    name: 'module',
                    disabled: translation.itemModuleId !== undefined,
                    data: translation.itemModuleId ?? 0,
                    options: this.getModuleOptions(),
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'itemTranslation.label',
                    name: 'label',
                    disabled: translation.label !== undefined,
                    data: translation.label,
                    required: true,
                },
                ...this.getTranslationInput(translation),
            ],
            submit: async (data: any, formData: Map<string, any>): Promise<boolean> => {
                const translations: TranslationModel[] = [];
                for (const language of this.language.available) {
                    const saveTranslation: TranslationModel = new TranslationModel(null);
                    saveTranslation.languageId = language.languageId;
                    saveTranslation.itemModuleId = formData.get('module');
                    saveTranslation.label = formData.get('label');
                    saveTranslation.value = formData.get(language.iso);
                    translations.push(saveTranslation);
                }
                await this.translationService.save(translations);
                this.setCreateButton();
                this.tableService.setObjects(await this.translationService.getAllLabels());
                return true;
            },
        };
    }

    private getModuleOptions(): { label: string; value: number | string | boolean; disabled?: boolean }[] {
        const options: { label: string; value: number | string | boolean; disabled?: boolean }[] = [];
        for (const module of this.modules) {
            options.push({ label: module.title, value: module.itemModuleId });
        }
        return options;
    }

    private getTranslationInput(translation: TranslationModel): FormInput[] {
        if (!this.language.languages) {
            this.language.getAll();
        }

        const inputs: FormInput[] = [];
        for (const language of this.language.languages) {
            if (translation.languageId === undefined || translation.languageId === null) {
                inputs.push({
                    type: 'form',
                    inputType: 'text',
                    text: 'global_' + language.iso,
                    name: language.iso,
                    data: '',
                    required: Boolean(language.default),
                });
                continue;
            }

            const label: TranslationModel = this.translationService.translations.find(
                (label: TranslationModel) => {
                    return (
                        label.languageId === language.languageId &&
                        label.module === translation.module &&
                        label.label === translation.label
                    );
                }
            );
            inputs.push({
                type: 'form',
                inputType: 'text',
                text: 'global_' + language.iso,
                name: language.iso,
                data: label === undefined ? '' : label.value,
                required: Boolean(language.default),
            });
        }
        return inputs;
    }
}
