import { InputItemComponent } from './../input-item/input-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormItemComponent } from './form-item.component';
import { FormModule } from '../../form/form.module';
import { RouterModule } from '@angular/router';
import { TranslationModule } from '../../../../modules/translate.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [FormItemComponent, InputItemComponent],
    imports: [CommonModule, FormModule, RouterModule, TranslationModule, FormsModule],
    exports: [FormItemComponent, InputItemComponent],
})
export class FormItemModule {}
