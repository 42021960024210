import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    NgZone,
} from '@angular/core';
import * as jsbarcode from 'jsbarcode';

@Component({
    selector: 'sBarcode',
    templateUrl: './barcode.component.html',
    styleUrls: ['./barcode.component.less'],
})
export class BarcodeComponent implements OnChanges {
    @ViewChild('barcode', { static: true }) private $barcode: ElementRef;

    @Input('text') private text: string = '';
    @Input('format') private format: string = 'CODE128';

    @Input('display-text') private displayText: string = undefined;
    @Input('font') private font: string = 'monospace';
    @Input('font-options') private fontOptions: string = '';
    @Input('font-size') private fontSize: string = '20';
    @Input('text-align') private textAlign: 'left' | 'center' | 'right' = 'center';
    @Input('text-position') private textPosition: 'top' | 'bottom' = 'bottom';
    @Input('text-margin') private textMargin: string = '2';

    @Input('bar-width') private barWidth: string = '2';
    @Input('height') private height: string = '100';

    @Input('color') private color: string = '#000000';

    // Only for EAN8/EAN13
    @Input('flat') private flat: string | boolean = false;

    public constructor(private zone: NgZone) {}

    public ngOnChanges(changes: SimpleChanges): void {
        this.updateBarcode();
    }

    private updateBarcode() {
        const text = this.text?.trim();

        if (text == null || text.length === 0) {
            (this.$barcode.nativeElement as SVGElement).textContent = '';
            return;
        }

        this.zone.runOutsideAngular(() => {
            jsbarcode(this.$barcode.nativeElement, this.text, {
                format: this.format,
                width: Number.parseInt(this.barWidth, 10),
                height: Number.parseInt(this.height, 10),
                text: this.displayText,
                fontOptions: this.fontOptions,
                font: this.font,
                textAlign: this.textAlign,
                textPosition: this.textPosition,
                textMargin: Number.parseInt(this.textMargin, 10),
                fontSize: Number.parseInt(this.fontSize, 10),
                background: 'transparent',
                lineColor: this.color,
                margin: 0,
                flat: this.flat === 'true' || this.flat === '1' || this.flat === true,
            });
        });
    }
}
