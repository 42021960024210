import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ShippingComponent } from './shipping/shipping.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { ModuleWithProviders } from '@angular/core';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { MyOrdersOverviewComponent } from './my-orders-overview/my-orders-overview.component';
import { MyOrdersDetailComponent } from './my-orders-detail/my-orders-detail.component';

export const orderRoutes: Routes = [
	{
        path: 'bestelling',
        redirectTo: 'bestelling/overzicht',
        pathMatch: 'full',
    },
    {
        path: 'bestelling/overzicht',
        component: OrderOverviewComponent,
        canActivate: [AuthGuardService],
    },
	{
        path: 'bestelling/levering',
        component: ShippingComponent,
        canActivate: [AuthGuardService],
    },
	{
        path: 'bestelling/bevestigd',
        component: ConfirmationComponent,
        canActivate: [AuthGuardService],
    },
	{
        path: 'mijn-bestellingen',
        component: MyOrdersOverviewComponent,
        canActivate: [AuthGuardService],
    },
	{
        path: 'mijn-bestellingen/:orderId',
        component: MyOrdersDetailComponent,
        canActivate: [AuthGuardService],
    },
];

export const orderRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(orderRoutes);
