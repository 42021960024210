import { ToasterService } from './../../services/toaster.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { AjaxService } from 'src/app/core/services/ajax.service';
import { MapHostDirective } from './map-host.directive';
import { LocationService } from '../../services/location/location.service';
import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    OnDestroy,
    HostListener,
} from '@angular/core';
import { Coordinates } from '../../services/location/abstractLocation';
import { MobileMenuService } from '../../services/mobile-menu.service';

@Component({
    selector: 's-maps',
    templateUrl: './s-maps.component.html',
    styleUrls: ['./s-maps.component.less'],
})
export class SMapsComponent implements OnInit, OnDestroy {
    @ViewChild(MapHostDirective, { static: true }) mapHost!: MapHostDirective;
    public myCoords: Coordinates = null;
    public scriptLoaded: boolean = false;
    public markerAdded: boolean = false;
    // demo location input
    public street: string = 'Bosmanskamp';
    public houseNumber: string = '1';
    public suffix: string = 'b';
    public zipCode: string = '4191MS';
    public city: string = 'Geldermalsen';
    private mapsApiUrl = '://maps.googleapis.com/maps/api/js?key=';

    constructor(
        public locationService: LocationService,
        public toasterService: ToasterService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private ajax: AjaxService,
        private config: ConfigService,
        public mobileService: MobileMenuService
    ) {}

    ngOnInit(): void {
        this.mobileService.addButtons([
            {
                text: 'Kaarten',
                active: true,
                icon: 'map',
                click: this.switchView,
            },
            {
                text: 'Instellingen',
                active: false,
                icon: 'cog',
                click: () => {
                    this.switchView(true);
                },
            },
        ]);

        // hide settings container if mobile media query is active, unfortuanately not possibly with basic media queries as the class must be removable.
        if (window.matchMedia('screen and (max-width:767px)').matches) {
            let _settings = document.getElementById('map-settings');
            _settings.classList.add('display-none');
        }

        let _elements: [] = [].slice.call(document.getElementsByTagName('script'));

        // check if google maps script already has been loaded
        this.scriptLoaded =
            _elements.filter((_el) => {
                let src: string = _el['src'];
                if (src.includes(this.mapsApiUrl)) {
                    return _el;
                }
            }).length >= 1;

        this.loadScript(() => this.loadComponent());
    }

    @HostListener('window:resize')
    public onResize() {
        this.mobileService.active =
            window.matchMedia('screen and (max-width:767px)').matches &&
            this.mobileService.getMenuItems().length > 0;
        // make sure settings and map are visible when needed, else hide settings view as default.
        let _settings = document.getElementById('map-settings');
        let _map = document.getElementById('map-template');
        if (!window.matchMedia('screen and (max-width:767px)').matches) {
            _settings.classList.remove('display-none');
            _map.classList.remove('display-none');
        } else if (
            !_map.classList.contains('display-none') &&
            !_settings.classList.contains('display-none')
        ) {
            _settings.classList.add('display-none');
            this.mobileService.handleClick(
                this.mobileService.getMenuItems().find((item) => item.text === 'Kaarten')
            );
        }
    }

    public switchView(showSettings: boolean = false) {
        let _settings = document.getElementById('map-settings');
        let _map = document.getElementById('map-template');

        if (showSettings) {
            _map.classList.add('display-none');
            _settings.classList.remove('display-none');
        } else {
            _settings.classList.add('display-none');
            _map.classList.remove('display-none');
        }
    }

    public resize() {
        let _element = document.getElementById('map-container');
        if (_element) {
            this.locationService.getStrategy().setMapHeight(_element.offsetHeight);
        }
    }

    /**
     * Load component set in the locationService
     * Differs between google maps and open street maps
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public loadComponent() {
        // resolve component linked to the set strategy
        const compomentFactory = this.componentFactoryResolver.resolveComponentFactory(
            this.locationService.getStrategy().component
        );
        const viewContainerRef = this.mapHost.viewContainerRef;
        // clear content off ng-container
        viewContainerRef.clear();

        let componentRef = viewContainerRef.createComponent<any>(compomentFactory);

        this.locationService.getStrategy().setComponentReference(componentRef);
        // Load data to component
        this.locationService.getStrategy().loadComponentData();
    }

    public switchMap() {
        this.locationService.switchStrategy();
        this.markerAdded = false;
        if (!this.scriptLoaded) {
            this.loadScript(() => this.loadComponent());
        } else {
            this.loadComponent();
        }

        if (this.mobileService.active) {
            let _settings = document.getElementById('map-settings');
            let _map = document.getElementById('map-template');

            _settings.classList.add('display-none');
            _map.classList.remove('display-none');
        }
    }

    /**
     * removes marker from the active strategy map
     *
     * More like a demo function
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public removeMarkers() {
        this.locationService
            .getStrategy()
            .getMarkers()
            .slice()
            .forEach((marker) => {
                this.locationService.getStrategy().removeMarker(marker);
            });
    }

    /**
     * adds a new marker to the active strategy map
     *
     * More like a demo function
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public async addMarker() {
        let coords = await this.getGeocode();
        if (coords) {
            this.locationService.getStrategy().addMarker({
                longitude: coords.lon,
                latitude: coords.lat,
                iconUrl: 'assets/custompointer.png',
                title: 'Nieuwe marker',
                img: 'assets/index.jpg',
                subtitle: 'Geldermalsen',
                description: `Nieuw toegoevoegde locatie op lon: ${coords.lon}, lat: ${coords.lat}`,
            });
            this.locationService.getStrategy().setViewPosition(coords);
            this.markerAdded = !this.markerAdded;
        }
        if (coords === null) {
            this.toasterService.error('Geef een geldig adres op');
        }
    }

    /**
     * sets coordinates from address
     *
     * More like a demo function
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public getGeocode(): Promise<Coordinates> {
        // pass callback function to geocode to handle logic for result from geocode coordinates;
        return new Promise((resolve, reject) => {
            this.locationService.geocode(
                this.zipCode,
                this.houseNumber,
                this.suffix,
                this.city,
                this.street,
                (coordinates: Coordinates) => {
                    // do something with received coordinates
                    this.myCoords = coordinates;
                    resolve(coordinates);
                }
            );
        });
    }

    ngAfterContentChecked() {
        this.resize();
    }

    /**
     * Loads the google api script.
     *
     * Logic takes other strategies in account and skips loading the key if google strategy is not active.
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    Promise<boolean>
     */
    public async loadScript(callback: Function): Promise<boolean> {
        let scriptTimeout = 100; // ms

        if (this.locationService.getStrategy().key != 'google' || this.scriptLoaded) {
            setTimeout(() => {
                callback();
            }, scriptTimeout);

            return true;
        }

        let key = await this.ajax.get('/appconfig/getGoogleMapsAPIKey');
        if (key !== null && String(key['value']).length > 0) {
            this.config.googleAPIKey = key['value'];
            // inject google api scipt dynamicly, prevents hardcoding the apiKey in index.html
            let node = document.createElement('script'); // creates the script tag
            node.src = window.location.href.split(':', 1)[0] + this.mapsApiUrl + this.config.googleAPIKey; // sets the source (insert url in between quotes)
            node.type = 'text/javascript'; // set the script type
            node.async = true; // makes script run asynchronously
            // append to head of document
            document.getElementsByTagName('head')[0].appendChild(node);

            setTimeout(() => {
                this.scriptLoaded = true;
                callback();
            }, scriptTimeout);

            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.mobileService.clearMenuItems();
    }
}
