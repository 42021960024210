import { Component, Input } from '@angular/core';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.less'],
})
export class ToasterComponent {
    //holds all the classes for the input
    @Input('sClass') public sClass: string = '';

    constructor(public toast: ToasterService) {}
}
