import { Component, Input, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { UtilService } from '../../services/util.service';

interface MenuItem {
    type: string;
    class: string;
    action: () => void;
    name: string;
}

@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.less'],
})
export class CardComponent {
    //holds the title
    @Input('title') public title: string = '';

    //holds the subtitle
    @Input('subtitle') public subtitle: string = '';

    //wehter the title has a contrast background
    @Input('contrast') public contrast: boolean = false;

    //wehter no margin & padding in the card body
    @Input('fill') public fill: boolean = false;

    //get the html refrence for content
    @Input('menu') public menu: Array<MenuItem> = [];

    public dropdownOpen: boolean = false;

    public constructor(
        private viewContainerRef: ViewContainerRef,
        private cdRef: ChangeDetectorRef,
        public util: UtilService
    ) {}

    public ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
