import { TranslationModule } from 'src/app/modules/translate.module';
import { FormsModule } from '@angular/forms';
import { FormModule } from './../form/form.module';
import { OpenstreetmapModule } from './../openstreetmap/openstreetmap.module';
import { GooglemapsModule } from './../googlemaps/googlemaps.module';
import { SMapsComponent } from './s-maps.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapHostDirective } from './map-host.directive';

@NgModule({
    declarations: [SMapsComponent, MapHostDirective],
    imports: [
        CommonModule,
        FormModule,
        FormsModule,
        GooglemapsModule,
        OpenstreetmapModule,
        TranslationModule,
    ],
    exports: [SMapsComponent],
})
export class SMapModule {}
