import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';

export class Format {
    id: string;
    name: string;
    text?: string;
    items: Format[];
}
export interface DropInfo {
    targetId: string;
    action?: string;
}

@Component({
    selector: 'sDragdrop',
    templateUrl: './dragdrop.component.html',
    styleUrls: ['./dragdrop.component.less'],
})
export class DragdropComponent implements OnInit {
    @Input() values: Format[];

    dropTargetIds = [];
    itemLookup = {};
    dropAction: DropInfo = null;

    constructor(@Inject(DOCUMENT) private document: Document) {}
    ngOnInit(): void {
        this.prepareDragDrop(this.values);
    }

    /**
     * PrepareDragDrop
     *
     * Fill the dropTargetIds array with all id's so the lists are linked
     *
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @param      list    array
     * @returns    void
     */
    public prepareDragDrop(list: Format[]) {
        list.forEach((item) => {
            this.dropTargetIds.push(item.id);
            this.itemLookup[item.id] = item;
            this.prepareDragDrop(item.items);
        });
    }

    /**
     * dragMoved
     *
     * While moving determine where the item will be placed
     *
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @param      event    event
     * @returns    void
     */
    public dragMoved(event) {
        let elementFromPoint = this.document.elementFromPoint(
            event.pointerPosition.x,
            event.pointerPosition.y
        );
        if (!elementFromPoint) {
            return;
        }

        let container = elementFromPoint.classList.contains('drag-item')
            ? elementFromPoint
            : elementFromPoint.closest('.drag-item');
        if (!container) {
            return;
        }

        const targetRect = container.getBoundingClientRect();
        this.dropAction = {
            targetId: container.getAttribute('data-id'),
        };

        const buffer = 10;
        if (event.pointerPosition.y - targetRect.top < buffer) {
            this.dropAction['action'] = 'before';
        } else if (event.pointerPosition.y - targetRect.top > targetRect.height - buffer) {
            this.dropAction['action'] = 'after';
        } else {
            this.dropAction['action'] = 'inside';
        }

        this.showIndicator();
    }

    /**
     * drop
     *
     * drop item in the new place and remove it from the old place
     *
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @param      event    event
     * @returns    void
     */
    public drop(event) {
        if (!this.dropAction) {
            return;
        }

        const draggedItemId = event.item.data;
        const parentItemId = event.previousContainer.id;
        const targetListId = this.getParentId(this.dropAction.targetId, this.values, 'main');

        const draggedItem = this.itemLookup[draggedItemId];

        const oldItemContainer = parentItemId != 'main' ? this.itemLookup[parentItemId].items : this.values;
        const newContainer = targetListId != 'main' ? this.itemLookup[targetListId].items : this.values;
        let index = oldItemContainer.findIndex((container) => container.id === draggedItemId);
        oldItemContainer.splice(index, 1);
        const targetIndex = newContainer.findIndex((container) => container.id === this.dropAction.targetId);
        switch (this.dropAction.action) {
            case 'before':
                newContainer.splice(targetIndex, 0, draggedItem);
                break;
            case 'after':
                newContainer.splice(targetIndex + 1, 0, draggedItem);
                break;
            case 'inside':
                this.itemLookup[this.dropAction.targetId].items.push(draggedItem);
                break;
        }
        this.clearIndicator(true);
    }

    /**
     * getParentId
     *
     * getParentId
     *
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @param      id          string
     * @param      items       array
     * @param      parentId    string
     * @returns    string
     */
    public getParentId(id: string, items: Format[], parentId: string): string {
        for (let item of items) {
            if (item.id == id) {
                return parentId;
            }
            let ret = this.getParentId(id, item.items, item.id);
            if (ret) {
                return ret;
            }
        }
        return null;
    }

    /**
     * showIndicator
     *
     * Show an indicator when you want to drag inside another item
     *
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @returns    void
     */
    public showIndicator() {
        this.clearIndicator();
        if (this.dropAction.action === 'inside') {
            this.document.getElementById('item' + this.dropAction.targetId).classList.add('drop-inside');
        }
    }

    /**
     * clearIndicator
     *
     * Remove the indicator if you arent dragging inside anothet item
     *
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @param      dropped    boolean
     * @returns    void
     */
    public clearIndicator(dropped = false) {
        if (dropped) {
            this.dropAction = null;
        }
        this.document
            .querySelectorAll('.drop-inside')
            .forEach((element) => element.classList.remove('drop-inside'));
    }
}
