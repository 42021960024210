import { Injectable } from '@angular/core';

export type ToasterType = 'success' | 'error' | 'alert' | 'notification'

@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    public toasterActive: boolean = false;
    public type: ToasterType = 'error';
    public message: string[] = [];
    private activeTime: number = 6000;
    private _timeout: any;

    public constructor() {}

    public success(message: string | string[]): void {
        this.toast(message, 'success');
    }

    public error(message: string | string[]): void {
        this.toast(message, 'error');
    }

    public toast(message: string | string[], type: ToasterType): void {
        this.type = type;
        this.message = Array.isArray(message) ? message : [message];
        this.toasterActive = true;
        this.toasterInterval();
    }

    private toasterInterval(): void {
        let tmpThis: ToasterService = this;
        clearTimeout(this._timeout);

        this._timeout = setTimeout(() => {
            tmpThis.toasterActive = false;
        }, this.activeTime);
    }
}
