import { Subscription } from 'rxjs';
import { MobileMenuService } from '../../services/mobile-menu.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'mobile-bottom-bar',
    templateUrl: './mobile-bottom-bar.component.html',
    styleUrls: ['./mobile-bottom-bar.component.less'],
})
export class MobileBottomBarComponent implements OnInit {
    private menuChangedSubscription = new Subscription();
    constructor(public mobileService: MobileMenuService, private changeDetector: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.menuChangedSubscription = this.mobileService.menuChanged.subscribe((menuCount) => {
            this.handleMenu(menuCount);
        });
    }

    private handleMenu(menuCount: number) {
        menuCount = menuCount ?? 0;
        let bottomMenu = document.getElementById('bottom-menu');
		let page = document.getElementById('page');

        if (menuCount > 0) {
            // show menu
            bottomMenu.classList.remove('display-none');
			page.classList.add('margin');
            // setTimeout to prevent expression changed after it has been checked error...
            setTimeout(() => {
                this.mobileService.active = window.matchMedia('screen and (max-width:767px)').matches;
            }, 1);
            this.changeDetector.detectChanges();
        } else {
            // close menu;
            bottomMenu.classList.add('display-none');
			page.classList.remove('margin');
            setTimeout(() => {
                this.mobileService.active = false;
            }, 1);
            this.changeDetector.detectChanges();
        }
    }

    ngOnDestroy() {
        if (this.menuChangedSubscription) {
            this.menuChangedSubscription.unsubscribe();
        }
    }
}
