import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import Cropper from './cropper.js';
import { DomSanitizer } from '@angular/platform-browser';
import { ToasterService } from '../../services/toaster.service';
import { UploadService } from '../../services/upload.service';
import { AjaxService } from '../../services/ajax.service';
import { LanguageService } from '../../services/language.service';

let instance: number = 0;

@Component({
    selector: 'cropper',
    templateUrl: './cropper.component.html',
    styleUrls: ['./cropper.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class CropperComponent implements OnInit {
    public mimeNotSupported: boolean = false;
    public imageLoaded: boolean;
    public showUploadView: boolean = true;
    public isAdvancedUpload = (function () {
        var div = document.createElement('div');
        return (
            ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
            'FormData' in window &&
            'FileReader' in window
        );
    })();
    public showDragHere = false;
    public showDropNow = false;
    @ViewChild('cropImage', { static: true }) private imgElement: ElementRef;
    @ViewChild('Container', { static: true }) private Container: ElementRef;
    @ViewChild('fileInput', { static: true }) private fileInput: ElementRef;
    @ViewChild('genWidth', { static: true }) private genWidth: ElementRef;
    @ViewChild('genHeight', { static: true }) private genHeight: ElementRef;
    // Max image width and height
    private readonly maxWidth: number = 3000;
    private readonly maxHeight: number = 3000;
    private cropperInstance: number;
    private cropper: Cropper;
    private fileName: string;
    private originalUploadedFile: File;

    public constructor(
        private sanitizer: DomSanitizer,
        private toaster: ToasterService,
        private renderer: Renderer2,
        private ajax: AjaxService,
        private upload: UploadService,
        private zone: NgZone,
        private cd: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.cropperInstance = instance;
        this.renderer.setAttribute(this.Container.nativeElement, 'id', 'Cropper' + this.cropperInstance);

        instance += 1;

        if (this.isAdvancedUpload) {
        }
    }

    /**
     * onImageUpload
     *
     * received the uploaded image and places it in the cropper
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      files    $File
     * @returns    void
     */
    public onImageUpload(files: File): void {
        this.renderer.setStyle(this.Container.nativeElement, 'display', 'block');
        const file: File = files[0];

        // set as the original uploaded file for using the reset button
        this.originalUploadedFile = file;

        if (!file.type.startsWith('image/')) {
            this.mimeNotSupported = true;
            return this.toaster.error('Image mime type not supported');
        }

        this.imageLoaded = false;
        this.mimeNotSupported = false;
        this.fileName = file.name;

        this.setImageToCropper(file);

        this.showDragHere = false;
        this.showDropNow = false;
        this.showUploadView = false;
    }

    private createCropper(): void {
        let cropper: any;
        this.zone.runOutsideAngular(() => {
            cropper = new Cropper(this.imgElement.nativeElement, {
                minCropBoxheight: 128,
                minCropBoxWidth: 128,
                viewMode: 2,
            });
        });
        this.cropper = cropper;
    }

    /**
     * uploadImg
     *
     * uploads the cropped image
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      none
     * @returns    void
     */
    public uploadImg(): void {
        this.cropper.getCroppedCanvas().toBlob((blob: Blob) => {
            const reader: FileReader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const files: File = this.blobToFile(blob, this.fileName);

                if (this.upload.uploadImage(files)) {
                    this.cancelFile();
                    this.cd.detectChanges();
                }
            };
        });
    }

    /**
     * Outputs the cropper image result.
     * @author    Bas
     */
    public cropImg(): void {
        if (!this.imageLoaded) {
            return;
        }

        this.cropper.getCroppedCanvas().toBlob((blob: Blob) => {
            const file: File = new File([blob], 'cropped');
            this.setImageToCropper(file);
        });
    }

    public zoomIn(): void {
        this.cropper.zoom(0.1);
    }

    public zoomOut(): void {
        this.cropper.zoom(-0.1);
    }

    public rotateLeft(): void {
        this.cropper.rotate(-90);
        this.cropper.zoomTo(0);
    }

    public rotateRight(): void {
        this.cropper.rotate(90);
        this.cropper.zoomTo(0);
    }

    public flipVertical(): void {
        const data: any = this.cropper.getData();

        if (data.scaleY === -1) {
            this.cropper.scaleY(1);
        } else {
            this.cropper.scaleY(-1);
        }
    }

    public flipHorizontal(): void {
        const data: any = this.cropper.getData();

        if (data.scaleX === -1) {
            this.cropper.scaleX(1);
        } else {
            this.cropper.scaleX(-1);
        }
    }

    /**
     * Changes the aspect ratio of the cropper to 1:1.
     * @author    Joery <joery@safira.nl>
     */
    public oneToOne(): void {
        this.cropper.setAspectRatio(1 / 1);
    }

    /**
     * Changes the aspect ratio of the cropper to 4:3.
     * @author    Joery <joery@safira.nl>
     */
    public fourToThree(): void {
        this.cropper.setAspectRatio(4 / 3);
    }

    /**
     * Resets the aspect ratio of the cropper to default.
     * @author    Joery <joery@safira.nl>
     */
    public free(): void {
        this.cropper.setAspectRatio(NaN);
    }

    /**
     * reset
     *
     * sets back the original uploaded file and resets the cropper and aspect ratio
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      none
     * @returns    void
     */
    public reset(): void {
        this.setImageToCropper(this.originalUploadedFile);
        this.cropper.reset();
        this.cropper.setAspectRatio(NaN);
    }

    /**
     *
     * Casts a blob to a file type and adds a name and last modified date to it
     *
     * @author    Bas Hoppenbrouwers <bas@safira.nl>
     */
    public blobToFile(theBlob: Blob, fileName: string): File {
        const b: any = theBlob;

        b.lastModifiedDate = new Date();
        b.name = fileName;

        return <File>theBlob;
    }

    /**
     * dragHere
     *
     * activates the drag here class on the input
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      value    $boolean
     * @returns    void
     */
    public dragHere(value): void {
        this.showDragHere = value;
    }

    /**
     * dropNow
     *
     * activates the drop now class on the input
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      value    $boolean
     * @returns    void
     */
    public dropNow(event: Event, value: boolean): void {
        event.preventDefault();
        this.showDropNow = value;
    }

    /**
     * drop
     *
     * function that is triggered when a file is dropped inside the drag and drop box
     * @author     Eric van doorn <Eric@safira.nl>
     * @param      file    $File
     * @returns    void
     */
    public drop(event: Event, file: File): void {
        event.preventDefault();
        this.onImageUpload(file);
    }

    /**
     * triggerFileUpload
     *
     * triggers a click on the input type file
     *
     * @author     Eric van doorn <Eric@safira.nl>
     * @param      none
     * @returns    void
     */
    public triggerFileUpload(): void {
        this.fileInput.nativeElement.click();
    }

    /**
     * cancelFile
     *
     * cancels the current file and retursn back to the upload view
     *
     * @author     Eric van doorn <Eric@safira.nl>
     * @param      none
     * @returns    void
     */
    public cancelFile(): void {
        // reset the variables
        this.imageLoaded = false;
        this.fileName = '';
        this.showUploadView = true;
        this.fileInput.nativeElement.value = '';
        this.renderer.setStyle(this.Container.nativeElement, 'display', 'none');
    }

    /**
     * setImageToCropper
     *
     * global function to set the image in the cropper
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      file    $File
     * @returns    void
     */
    public setImageToCropper(file: File): void {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const image: HTMLImageElement = new Image();

            image.onload = () => {
                this.renderer.setAttribute(
                    this.imgElement.nativeElement,
                    'src',
                    `data:${file.type};base64${reader.result}`
                );

                const canvas: HTMLCanvasElement = document.createElement('canvas');
                const canvasContext: CanvasRenderingContext2D = canvas.getContext('2d');

                let width: number = image.naturalWidth;
                let height: number = image.naturalHeight;
                const aspectRatio: number = width / height;

                // Checks if the image is too big, if it is resize it with the proper aspectRatio
                if (width >= this.maxWidth || height >= this.maxHeight) {
                    width = Math.min(this.maxWidth, width * aspectRatio);
                    height = Math.min(this.maxHeight, height / aspectRatio);
                }

                canvas.width = width;
                canvas.height = height;

                canvasContext.drawImage(
                    image,
                    0,
                    0,
                    image.naturalWidth,
                    image.naturalHeight,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );

                this.imgElement.nativeElement.src = canvas.toDataURL('image/png');

                if (this.cropper) {
                    this.cropper.replace(canvas.toDataURL(`image/${file.type}`));

                    // Use timeout because else replace will not be finished when setCropboxData is called
                    // replace cannot be called async and the build in function crop() in de options also calls to early
                    setTimeout(() => {
                        var contData = this.cropper.getContainerData(); //Get container data
                        this.cropper.setCropBoxData({ height: contData.height, width: contData.width }); //set data
                    }, 0);

                    this.imageLoaded = true;
                    return;
                }
				
                this.imageLoaded = true;

                this.createCropper();
            };

            image.src = `data:${file.type};base64${reader.result}`;
        };
    }
}
