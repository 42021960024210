import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Form } from '../../../models/form-item.model';
import { FormComponent } from '../../form/form.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'sForm-item',
    templateUrl: './form-item.component.html',
})
export class FormItemComponent implements AfterContentInit {
    @Input() form: Form;
    @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('formComponent', { static: true })
    private _formComponent: FormComponent;

    private destroyed$: ReplaySubject<void> = new ReplaySubject(1);

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngAfterContentInit(): void {
        this._formComponent.formSubmitted.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
            let formData = new Map<string, any>();
            this.form.inputs.map((input) => {
                if (input.inputType === 'number') {
                    formData.set(input.name, +input.data);
                } else {
                    formData.set(input.name, input.data);
                }
            });
            if (await this.form.submit(this.form.data, formData)) {
                this.formSubmitted.next();
                await this.router.navigate([this.form.routeTo], {
                    relativeTo: this.activatedRoute,
                });
            }
        });
    }
}
