import { FormsModule } from '@angular/forms';
import { MobileBottomBarComponent } from './mobile-bottom-bar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
		MobileBottomBarComponent
	],
    imports: [CommonModule, FormsModule],
	exports: [
		MobileBottomBarComponent
	]
})
export class MobileModule {}
