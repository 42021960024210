import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
    Table,
    TableBreakpoints,
    TableColumn,
    TableFilter,
    TableFilters,
    TableSearch,
} from '../models/table.model';
import { item, RowItem } from '../models/item.model';
import { PaginatorService } from './paginator.service';
import { ResponsiveService } from './responsive.service';
import { UtilService } from './util.service';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class TableService {
    public tableChanged: Subject<Table> = new Subject<Table>();
    public filterChanged: Subject<TableFilters> = new Subject<TableFilters>();
    public selectedObjects: Object[] = [];
    public table: Table;
    private mapper: (objects: Object) => RowItem;
    private columnMapper: (object?: Object) => TableColumn[];
    private subscriptions: Subscription[] = [];
    private sortColumn: number = null;
    private sortByDesc: boolean = true;
    private originalObjects: Object[] = null;
    private objects: Object[] = [];

    constructor(
        private paginatorService: PaginatorService,
        private responsive: ResponsiveService,
        private util: UtilService
    ) {}

    /**
     * Register the table if this is done the table will automatically load inside any sTable.
     *
     * After this nothing else needs to be done sorting will be configured automatically.
     * If you change the list with the setObject method the table will also automatically update.
     *
     * Only the subject is really required all other parameters are there to customize the table
     *
     * @param     tableOptions      The    options of a table
     * @param     initialObjects    The    objects that the table is starting with
     * @param     mapToColumns      The    function used to map table columns
     * @param     mapToRows         The    function used to map table rows
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public register(
        tableOptions?: {
            paginator?: boolean;
            breakpoint?: TableBreakpoints;
        },
        mapToColumns: (object?: Object) => TableColumn[] = this.mapToColumns,
        mapToRows: (objects: Object) => RowItem = this.mapToRows,
        initialObjects: Object[] = []
    ): void {
        // Destroy the previous set table and revert to defaults
        this.destroyTable();

        if (tableOptions) {
            this.table.paginator = tableOptions.paginator;
            this.table.refreshBreakpoint = tableOptions.breakpoint;
        }

        this.mapper = mapToRows;
        this.columnMapper = mapToColumns;

        // Set paginator
        if (this.table.paginator) {
            this.paginatorService.resetPaginator();

            // Set paginator on table objects change
            this.subscriptions.push(
                this.tableChanged.subscribe(() => {
                    this.paginatorService.setPaginationObjects(this.getObjects());
                })
            );

            // Map objects on pagination change
            this.subscriptions.push(
                this.paginatorService.paginationChanged.subscribe(() => {
                    this.selectedObjects = [];
                    this.table.rows = this.mapRows(this.paginatorService.getCurrentPageObjects());
                    this.refreshColumns();
                })
            );
        } else {
            // Remap on change without paginator
            this.subscriptions.push(
                this.tableChanged.subscribe(() => {
                    this.table.rows = this.mapRows(this.getObjects());
                })
            );
        }

        // Subscribe to responsiveness
        this.subscriptions.push(
            this.responsive.subscribeOnBreakpoints(
                this.table.refreshBreakpoint.column,
                this.refreshColumns.bind(this)
            ),
            this.responsive.subscribeOnBreakpoints(
                this.table.refreshBreakpoint.row,
                this.refreshRows.bind(this)
            )
        );

        // Set the initial objects
        this.setObjects(initialObjects);

        // Set initial columns
        this.refreshColumns();
    }

    /**
     * Set objects of the table
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public setObjects(objects: Object[]): void {
        this.originalObjects = objects ?? [];
        this.search();
    }

    /**
     * Add searches of the table, run in OnInit
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public addSearches(searches: TableSearch[]): void {
        this.table.filter.searches.push(...searches);
    }

    /**
     * Add filter to the table, run in OnInit
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public addFilter(filters: TableFilter[]): void {
        this.table.filter.filters.push(...filters);
    }

    /**
     * Get filters from table
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public getFilters(): TableFilters {
        if (this.table) {
            return this.table.filter;
        }
    }

    /**
     * Set new filters for the table
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public setFilters(filter: TableFilters): void {
        this.table.filter = filter;
    }

    /**
     * Get the sortColumn of the table
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public getSortColumn(): number {
        return this.sortColumn;
    }

    /**
     * Get the sortByDesc of the table
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public getSortByDesc(): boolean {
        return this.sortByDesc;
    }

    /**
     * Returns all objects in a cloned object
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public getObjects(): Object[] {
        return JSON.parse(JSON.stringify(this.objects));
    }

    /**
     * Add a single object to the table
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public addObjects(objects: Object | Object[]): void {
        const newObjects: Object[] = Array.isArray(objects) ? objects : [objects];
        const currentObjects = this.getObjects();
        currentObjects.push(...newObjects);
        this.setObjects(currentObjects);
    }

    /**
     * Retrieve a single object by index
     * Returns false if object doesn't exist
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public getObject(index: number): Object | false {
        const objects = this.getObjects();
        if (index < 0 || index >= objects.length) {
            return false;
        }
        return JSON.parse(JSON.stringify(objects[index]));
    }

    /**
     * Retrieve the index of a object
     * Returns false if object doesn't exist
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public findObject(search: Object): number | false {
        const objects = this.getObjects();
        const index = objects.indexOf(
            objects.find((object) => JSON.stringify(search) === JSON.stringify(object))
        );
        return index < 0 ? false : index;
    }

    /**
     * Remove the object
     * Returns if was successful
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public removeObject(object: Object): boolean {
        const index = this.findObject(object);
        return index === false ? false : this.removeObjectByIndex(index);
    }

    /**
     * Remove the object by index
     * Returns if was successful
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public removeObjectByIndex(index: number): boolean {
        const objects = this.getObjects();
        if (index < 0 || index > objects.length) {
            return false;
        }
        objects.splice(index, 1);
        this.setObjects(objects);
        return true;
    }

    /**
     * Refreshes the whole table
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public refreshTable() {
        this.refreshColumns();
        this.refreshRows();
    }

    /**
     * Refreshes the table columns
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public refreshColumns() {
        this.table.columns = this.columnMapper(this.getObject(0));
    }

    /**
     * Refreshes the table rows
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public refreshRows() {
        this.table.rows = this.mapRows(
            this.table.paginator ? this.paginatorService.getCurrentPageObjects() : this.getObjects()
        );
    }

    /**
     * Switch the sorted column if it's the same column change toggle ordering
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public switchSortColumn(columnIndex: number): void {
        // Check if column has sorting
        if (
            this.table.columns[columnIndex] === undefined ||
            this.table.columns[columnIndex].sortOn === undefined
        ) {
            return;
        }

        // Check if a new column was clicked
        if (this.sortColumn === columnIndex) {
            // Turn off sorting
            if (!this.sortByDesc) {
                this.sortColumn = null;
                this.sortByDesc = true;
                this.search();
                return;
            }

            // Order by asc
            this.sortByDesc = false;
        } else {
            this.sortColumn = columnIndex;
            this.sortByDesc = true;
        }
        this.sort();
    }

    public resetSort() {
        this.sortColumn = null;
        this.sortByDesc = true;
        this.search();
    }

    /**
     * Get all options of a filter
     *
     * @param     filter
     * @param     label    Give    your label string place %VALUE% to insert the value here
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public getOptions(filter: string, label?: string): { label: string; value: number | string | boolean }[] {
        if (this.originalObjects === null) {
            this.originalObjects = this.getObjects();
        }

        if (this.originalObjects === undefined || this.originalObjects.length <= 0) {
            return [];
        }

        const options: { label: string; value: number | string | boolean }[] = [];
        for (const object of this.originalObjects) {
            if (object[filter] && object[filter].toString().length > 0) {
                const newOption = {
                    label: label ? label.replace('%VALUE%', object[filter]) : object[filter].toString(),
                    value: object[filter],
                };
                let unique = true;
                for (const option of options) {
                    if (newOption.value === option.value) {
                        unique = false;
                        break;
                    }
                }
                if (unique) {
                    options.push(newOption);
                }
            }
        }
        return options;
    }

    /**
     * If you want to remove the currently loaded table call this method
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public destroyTable(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }

        this.subscriptions = [];

        this.table = {
            paginator: false,
            columns: [],
            rows: [],
            refreshBreakpoint: {
                column: [],
                row: [],
            },
            filter: {
                searches: [],
                filters: [],
            },
        };

        this.mapper = this.mapToRows;
        this.columnMapper = this.mapToColumns;

        this.sortColumn = null;
        this.sortByDesc = true;

        this.selectedObjects = [];

        this.setObjects(null);
    }

    /**
     * Search the table for the searched objects
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public search(searchString?: string, filters?: TableFilter[]) {
        if (this.table.filter === undefined) {
            return;
        }
        if (searchString === undefined) {
            searchString = this.table.filter.searchString === undefined ? '' : this.table.filter.searchString;
        }
        if (filters === undefined) {
            filters = this.table.filter.filters === undefined ? [] : this.table.filter.filters;
        }
        this.table.filter.searchString = searchString;
        this.table.filter.filters = filters;

        if (this.originalObjects === null) {
            this.originalObjects = this.getObjects();
        }
        let filteredList = this.originalObjects;

        // Apply search
        if (searchString !== undefined && searchString.length > 0) {
            filteredList = filteredList.filter((object: Object) => {
                for (const search of this.table.filter.searches) {
                    if (object[search.searchOn] === undefined || object[search.searchOn] === null) {
                        continue;
                    }
                    if (
                        this.util.getMatchAccuracy(searchString, object[search.searchOn].toString()) >=
                        (search.accuracy ?? 100)
                    ) {
                        return true;
                    }
                }
            });
        }

        // Apply filters
        for (const filter of filters) {
            if (filter.filterOn !== undefined && filter.filterOn !== null) {
                filteredList = filteredList.filter((object) => {
                    return (
                        filter.filteredOn === undefined ||
                        filter.filteredOn === '' ||
                        (filter.filteredOn === 'null' && object[filter.filterOn] === null) ||
                        (object[filter.filterOn] !== undefined &&
                            object[filter.filterOn] !== null &&
                            object[filter.filterOn].toString() === filter.filteredOn.toString())
                    );
                });
            }
        }

        this.objects = filteredList;
        if (!this.sort()) {
            this.setCurrentObjects(filteredList);
        }
    }

    /**
     * returns helper object for table
     * function selects all objects in the table on the current page
     *
     * @author    Mitchell Sterrenberg <Mitchell@safira.nl>
     */
    public getSelectAll(): item {
        const tableObjects = this.table.paginator
            ? this.paginatorService.getCurrentPageObjects()
            : this.getObjects();

        return {
            type: 'form',
            inputType: 'checkbox',
            name: 'getSelectAll',
            data: this.selectedObjects.length >= tableObjects.length,
            change: async (data: any) => {
                if (this.selectedObjects.length < tableObjects.length) {
                    this.selectedObjects = JSON.parse(JSON.stringify(tableObjects));
                } else {
                    this.selectedObjects = [];
                }
                this.refreshRows();
            },
        };
    }

    /**
     * Selects a single object on the current page
     * function selects a single object in the table on the current page
     *
     * @author    Mitchell Sterrenberg <Mitchell@safira.nl>
     */
    public getSelect(user): item {
        return {
            type: 'form',
            inputType: 'checkbox',
            name: 'getSelect',
            data:
                this.selectedObjects.findIndex((selectedObject) => {
                    return _.isEqual(selectedObject, user);
                }) >= 0,
            change: async (data: any) => {
                if (data) {
                    this.selectedObjects.push(user);
                } else {
                    const index: number = this.selectedObjects.findIndex((selectedObject) => {
                        return _.isEqual(selectedObject, user);
                    });
                    if (index < 0) {
                        return;
                    }
                    this.selectedObjects.splice(index, 1);
                }
                this.refreshColumns();
            },
        };
    }

    /**
     * Set the current objects of the table keep the old ones saved
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private setCurrentObjects(objects?: Object[]): void {
        this.objects = objects ?? this.originalObjects;
        this.tableChanged.next(this.table);
    }

    /**
     * Sort the table. You don't need to call!
     * This this will be called on change in column sorting or if the table objects are changed
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private sort(): boolean {
        // Get the name of the variable that needs sorting
        if (this.table.columns === undefined || this.table.columns[this.sortColumn] === undefined) {
            return false;
        }
        const sortOn = this.table.columns[this.sortColumn].sortOn;
        if (sortOn === undefined) {
            return false;
        }

        // Set sorted list to page object
        this.setCurrentObjects(
            this.getObjects().sort((first, second) => {
                let firstSortOn = first[sortOn];
                let secondSortOn = second[sortOn];
                if (firstSortOn === secondSortOn) {
                    return 0;
                }
                if (firstSortOn === null || firstSortOn < secondSortOn) {
                    return this.sortByDesc ? 1 : -1;
                }
                if (secondSortOn === null || firstSortOn > secondSortOn) {
                    return this.sortByDesc ? -1 : 1;
                }
                return 0;
            })
        );
        return true;
    }

    /**
     * Receives the to be mapped objects and calls te mapper for each object
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapRows(objects: Object[]): RowItem[] {
        const tableRows: RowItem[] = [];
        for (let object of objects) {
            tableRows.push(this.mapper(object));
        }
        return tableRows;
    }

    /**
     * If there are no columns defined in the register the columns will be automatically generated
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapToColumns(object: Object): TableColumn[] {
        if (object === undefined || object === null) {
            return;
        }
        const tableColumns: TableColumn[] = [];
        const columns = Object.keys(object);
        for (const columnData of columns) {
            const column: TableColumn = { item: { type: 'text', text: columnData }, sortOn: columnData };
            tableColumns.push(column);
        }
        return tableColumns;
    }

    /**
     * Receives the object to be mapped and maps it
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapToRows(object: Object): RowItem {
        let row: RowItem = { type: 'row', items: [] };
        for (const itemData of Object.values(object)) {
            if (typeof itemData === 'function') {
                continue;
            }
            let item: item;
            switch (typeof itemData) {
                case 'boolean': {
                    item = {
                        type: 'bool',
                        bool: Boolean(itemData),
                    };
                    break;
                }
                default: {
                    item = { type: 'text', text: itemData };
                }
            }
            row.items.push(item);
        }
        return row;
    }
}
