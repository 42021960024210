import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';
import { AuthorisationService } from '../../../core/services/auth/authorisation.service';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.less'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    public newPassword: string = '';
    public newPasswordRepeat: string = '';
    public resetErrorId: string = 'reset-error';
    public sendPassword: boolean = false;
    public validHash: boolean = null;
    public hash: string = null;
    public canSubmit: boolean = false;

    public paramSubscription: Subscription = null;

    public constructor(
        public authenticate: AuthenticateService,
        private route: ActivatedRoute,
        private auth: AuthorisationService,
        private router: Router
    ) {
        if (this.auth.canAccess()) {
            this.router.navigate(['/producten']);
        }
    }

    public ngOnInit(): void {
        this.paramSubscription = this.route.params.subscribe((params: Params) => {
            this.hash = params['hash'];

            this.authenticate.checkResetPasswordHashAndTime(this.hash).then((result: boolean) => {
                this.validHash = result;
            });
        });
    }

    public ngOnDestroy(): void {
        this.paramSubscription?.unsubscribe();
    }
}
