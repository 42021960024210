import { Component } from '@angular/core';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';

@Component({
    selector: 'sLoginWrapper',
    templateUrl: './login-wrapper.component.html',
    styleUrls: ['./login-wrapper.component.less'],
})
export class LoginWrapperComponent {
    constructor(public authenticate: AuthenticateService) {}
}
