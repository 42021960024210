import { Component, Input } from '@angular/core';
import { BoolItem } from '../../../models/item.model';

@Component({
    selector: 'sBoolItem',
    templateUrl: './bool-item.component.html',
    styleUrls: ['./bool-item.component.less'],
})
export class BoolItemComponent {
    @Input() data: BoolItem;
}
