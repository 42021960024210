import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'colors',
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.less'],
})
export class ColorsComponent {
    constructor(public router: Router) {}
}
