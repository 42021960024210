import { UploadService } from './../../services/upload.service';
import { Component, ElementRef, Input, OnChanges, SimpleChanges, NgZone } from '@angular/core';
import QRCodeStyling from 'qr-code-styling';

@Component({
    selector: 'sQrCode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.less'],
})
export class QrcodeComponent implements OnChanges {
    @Input('text') private text: string;
    @Input('custom') private custom: boolean = true;

    constructor(private uploadService: UploadService, private $elRef: ElementRef, private zone: NgZone) {}

    public ngOnChanges(changes: SimpleChanges): void {
        this.updateQrcode();
    }

    public updateQrcode() {
        const $ele: HTMLElement = this.$elRef.nativeElement;

        if (this.text == null || this.text.length === 0) {
            $ele.innerHTML = '';
            return;
        }
        $ele.innerHTML = '';
        let logo = '';
        let color = '#000000';
        if (this.custom) {
            logo = 'assets/logo.svg';
            color = '#3e1052';
        }
        this.zone.runOutsideAngular(() => {
            const qrcode = new QRCodeStyling({
                width: 270,
                height: 270,
                data: this.text,
                type: 'svg',
                image: logo,
                qrOptions: {
                    typeNumber: 0,
                    mode: 'Byte',
                    errorCorrectionLevel: 'H',
                },
                imageOptions: {
                    hideBackgroundDots: true,
                    imageSize: 0.5,
                    margin: 5,
                    crossOrigin: 'anonymous',
                },
                dotsOptions: {
                    color: color,
                },
                cornersDotOptions: {
                    color: color,
                },
                cornersSquareOptions: {
                    color: color,
                },
            });
            qrcode.append($ele);
            this.uploadService.qrcode = qrcode;
        });
    }
}
