import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public constructor() {}

    // Defaults to sessionStorage
    private get storage(): Storage {
        return this.usingLocalStorage() ? localStorage : sessionStorage;
    }

    /**
     * Will get a value from the relevant storage
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public getStorage(name: string): string {
        if (!this.hasStorage()) {
            throw new Error('Storage is full or not available');
        }

        const value: string = this.storage.getItem(name);

        if (value == null) {
            return '';
        }

        return value;
    }

    /**
     * Will set a value in the relevant storage
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public setStorage(name: string, value: string): void {
        if (!this.hasStorage()) {
            throw new Error('Storage is full or not available');
        }

        this.storage.setItem(name, value);
    }

    /**
     * Will set a value in the relevant storage
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public deleteStorage(name: string): void {
        this.storage.removeItem(name);
    }

    /**
     * Sets the relevant storage
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public setRelevantStorage(isLocal: boolean): void {
        if (isLocal) {
            try {
                localStorage.setItem('rememberMe', 'true');
            } catch (ex) {
                throw new Error('Storage is full or not available');
            }
        } else {
            localStorage.removeItem('rememberMe');
        }
    }

    /**
     * Gets if localStorage is the current relevant storage
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public usingLocalStorage(): boolean {
        return localStorage.getItem('rememberMe') === 'true';
    }

    /**
     * Checks if there's storage space available
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    private hasStorage(): boolean {
        try {
            this.storage.setItem('check', 'available');
            this.storage.removeItem('check');

            return true;
        } catch (ex) {
            return false;
        }
    }
}
