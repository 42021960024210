import { ToasterService } from './toaster.service';
import { Injectable } from '@angular/core';

export interface shareData {
    title: string;
    text: string;
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class ShareService {
    constructor(private toaster: ToasterService) {}

    /**
     * checks the data is valid and then opens the share menu on the device
     *
     * @author    Mitchell Sterrenberg <mitchell@safira.nl>
     */
    public async openShare(data: shareData): Promise<void> {
        if ((await navigator.canShare(data)) === true) {
            await navigator.share(data);
        } else {
            this.toaster.error('Er is een fout opgetreden tijdens het delen. Probeer het later nog eens.');
        }
    }
}
