import { Directive, HostListener } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UtilService } from 'src/app/core/services/util.service';

@Directive({
    selector: '[numberValidation]',
})
export class NumberValidationDirective {
    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $target.value;
        if (!this.numberValidation(value)) {
            this.util.addClass($target, 'error');
            this.validation.errors.numberValidation = true;
        } else if (this.numberValidation(value)) {
            this.util.removeClass($target, 'error');
            this.validation.errors.numberValidation = false;
        }
    }

    public constructor(private validation: ValidationService, private util: UtilService) {}

    /**
     * numberValidation
     *
     * checks if the input is a number
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    private numberValidation(value: any): boolean {
        return typeof value === 'string' && /^[0-9.,]+$/.test(value) && value != '';
    }
}
