import { Component } from '@angular/core';
import { SearchboxOption } from '../../../../core/components/searchbox/searchbox.model';

@Component({
    selector: 'search',
    templateUrl: './searchbox.component.html',
    styleUrls: ['./searchbox.component.less'],
})
export class SearchComponent {
    public searchboxValue: string;
    public searchboxData: Array<SearchboxOption> = [
        { value: '1', name: 'Hamsterkooi' },
        { value: '2', name: 'Gehakt' },
        { value: '3', name: 'Halogeen lamp' },
        { value: '22', name: 'Vlaai' },
        { value: '77', name: 'Spiegel' },
        { value: '94', name: 'Zilveren lepel' },
        { value: '124', name: 'Spons' },
        { value: '187', name: 'Shotglaasje' },
        { value: '365', name: 'Bureau' },
        { value: '391', name: 'Plant' },
        { value: '392', name: 'Trekhaak' },
    ];
}
