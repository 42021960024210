import { Injectable } from '@angular/core';
import { TextItem } from '../models/item.model';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    public pages: TextItem[] = [];

    public pushPage(pageItem: string | TextItem, route?: string) {
        const page: TextItem = typeof pageItem === 'string' ? {
            type: 'text',
            text: pageItem,
        } : pageItem;

        if (route) {
            page.action = {
                type: 'route',
                route: route,
            };
        }

        this.pages.push(page);
    }

    public popPage() {
        if (this.pages.length > 0) {
            this.pages.pop();
        }
    }

    public getPages() {
        return this.pages;
    }
}
