import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { ModuleWithProviders } from '@angular/core';
import { SettingsComponent } from 'src/app/core/components/settings/settings.component';

export const dashboardRoutes: Routes = [
    {
        path: '',
        redirectTo: 'producten',
        pathMatch: 'full',
    },
    {
        path: 'producten',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
    },
];

export const dashboardRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(dashboardRoutes);
