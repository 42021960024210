import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslatePipe } from '../core/pipe/translate.pipe';
import { TranslateService } from '../core/services/translate.service';

@NgModule({
    declarations: [TranslatePipe],
    exports: [TranslatePipe],
})
export class TranslationModule {
    static forRoot(): ModuleWithProviders<TranslationModule> {
        return {
            ngModule: TranslationModule,
            providers: [TranslateService],
        };
    }
}
