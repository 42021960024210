import { Component, Input } from '@angular/core';
import { RouteAction } from '../../../../models/action.model';

@Component({
    selector: 'sRouteAction',
    templateUrl: './route-action.component.html',
    styleUrls: ['../../colors.less'],
})
export class RouteActionComponent {
    @Input() action: RouteAction;
}
