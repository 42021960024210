import { NgModule } from '@angular/core';
import { QrcodeComponent } from './qrcode.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [QrcodeComponent],
    imports: [CommonModule],
    exports: [QrcodeComponent],
})
export class QrcodeModule {}
