import { Injectable } from '@angular/core';
import { CrudServiceModel } from '../models/crud/crud-service.model';
import { AjaxService } from './ajax.service';
import { CountryModel } from '../models/country.model';

@Injectable({
    providedIn: 'root',
})
export class CountryService extends CrudServiceModel<CountryModel> {
    public countries: CountryModel[];

    protected module = 'country';
    protected create = CountryModel;

    constructor(protected ajax: AjaxService) {
        super();
    }

    protected validation(): boolean {
        return true;
    }

    async getAll(param?: any, loadingTarget?: string): Promise<CountryModel[]> {
        return (this.countries = await super.getAll(param, loadingTarget));
    }
}
