import { Directive, HostListener } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UtilService } from 'src/app/core/services/util.service';

@Directive({
    selector: '[dateValidation]',
})
export class DateValidationDirective {
    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $target.value;
        if (!this.dateValidation(value)) {
            this.util.addClass($target, 'error');
            this.validation.errors.dateValidation = true;
        } else if (this.dateValidation(value)) {
            this.util.removeClass($target, 'error');
            this.validation.errors.dateValidation = false;
        }
    }

    public constructor(private validation: ValidationService, private util: UtilService) {}

    /**
     * dateValidation
     *
     * checks if the input is a date
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private dateValidation(value: any): boolean {
        return (
            typeof value === 'string' &&
            (/^(19|20)\d\d[- /.](0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])$/i.test(value) ||
                /^(0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)\d\d$/i.test(value) ||
                value === '')
        );
    }
}
