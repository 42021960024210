import { InfoPopupModule } from '../info-popup/InfoPopup.module';
import { RouterModule } from '@angular/router';
import { OpenStreetMapComponent } from './openstreetmap.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormModule } from '../form/form.module';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [OpenStreetMapComponent],
    imports: [CommonModule, FormsModule, FormModule, RouterModule, InfoPopupModule],
    exports: [CommonModule, OpenStreetMapComponent],
})
export class OpenstreetmapModule {}
