import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToasterComponent } from './toaster.component';
import { TranslationModule } from '../../../modules/translate.module';

@NgModule({
    declarations: [ToasterComponent],
    imports: [CommonModule, RouterModule, TranslationModule],
    exports: [ToasterComponent],
    providers: [ToasterComponent],
})
export class ToasterModule {}
