import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowItem, TextItem } from '../../../core/models/item.model';
import { TableColumn } from '../../../core/models/table.model';
import { UtilService } from '../../../core/services/util.service';
import { Breakpoint, ResponsiveService } from '../../../core/services/responsive.service';
import { BreadcrumbService } from '../../../core/services/breadcrumb-service.service';
import { TableService } from '../../../core/services/table.service';
import { ModuleModel } from '../../../core/models/module.model';
import { ModuleService } from '../../../core/services/module.service';
import { Form } from '../../../core/models/form-item.model';

@Component({
    selector: 'language',
    templateUrl: './module.component.html',
})
export class ModuleComponent implements OnInit, OnDestroy {
    button: TextItem;

    navigation: TextItem = {
        type: 'text',
        isButton: true,
        text: ['global_manage', ' ', 'itemTranslation.translation'],
        action: { type: 'route', route: '../translations' },
    };

    private module: ModuleModel;

    constructor(
        public util: UtilService,
        public breadCrumb: BreadcrumbService,
        private tableService: TableService,
        private moduleService: ModuleService,
        private responsive: ResponsiveService
    ) {}

    async ngOnInit() {
        this.breadCrumb.pushPage('itemMenu.translations');
        this.setCreateButton();

        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [], row: [Breakpoint.MD] },
            },
            this.mapColumns.bind(this),
            this.mapRows.bind(this),
            await this.moduleService.getAll()
        );

        this.tableService.addSearches([{ searchOn: 'title' }, { searchOn: 'description_title' }]);
        this.tableService.addFilter([{ filterOn: 'active' }]);
    }

    ngOnDestroy() {
        this.breadCrumb.popPage();
    }

    private mapColumns(): TableColumn[] {
        return [
            {
                item: { type: 'text', text: 'itemTranslation.module' },
                sortOn: 'title',
                width: 20,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 25,
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.description' },
                width: 50,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 65,
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.active' },
                position: 'center',
                sortOn: 'active',
                width: 15,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.actions' },
                position: 'center',
                width: 15,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
        ];
    }

    /**
     * Map users to table format
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapRows(module: ModuleModel): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                colspan: 2,
                gap: 10,
                closedHeight: 16.8,
                openHeight: 83.6,
            },
            items: [
                { type: 'text', fontWeight: 600, text: module.title, icon: module.icon, expansion: 'hidden' },
                {
                    type: 'group',
                    gap: 6,
                    expansion: 'expandable',
                    breakpoint: { breakpoint: Breakpoint.MD, state: 'until' },
                    items: [
                        { type: 'text', fontWeight: 600, text: module.title, icon: module.icon },
                        { type: 'bool', bool: module.active },
                    ],
                },
                { type: 'text', text: module.description },
                {
                    type: 'bool',
                    bool: module.active,
                    position: 'center',
                    expansion: 'hidden',
                    breakpoint: { breakpoint: Breakpoint.MD, state: 'after' },
                },
                {
                    type: 'group',
                    position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                    expansion: 'expandable',
                    items: [
                        {
                            type: 'text',
                            icon: 'pen',
                            position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                            iconHover: true,
                            iconBorder: true,
                            title: 'global_edit',
                            breakpoint: { breakpoint: Breakpoint.MD, state: 'after' },
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'text',
                                    text: ['global_edit', ' ', 'itemTranslation.module'],
                                },
                                form: this.getForm(module),
                            },
                        },
                        {
                            type: 'text',
                            icon: 'trash-alt',
                            iconBorder: true,
                            iconHover: true,
                            title: 'itemTranslation.delete_module',
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'text',
                                    text: ['itemTranslation.delete_module', ' ', module.title],
                                },
                                form: {
                                    routeTo: './',
                                    submitLabel: 'global.global_delete',
                                    inputs: [
                                        {
                                            type: 'form',
                                            inputType: 'checkbox',
                                            text: 'itemTranslation.confirm_delete_module',
                                            name: 'confirm',
                                            data: null,
                                            required: true,
                                        },
                                    ],
                                    submit: async (data: any, formData: Map<string, any>) => {
                                        if (formData.get('confirm')) {
                                            await this.moduleService.delete(module);
                                            this.tableService.setObjects(await this.moduleService.getAll());
                                            return true;
                                        }
                                        return false;
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    }

    private setCreateButton() {
        this.module = new ModuleModel(this.moduleService.save.bind(this.moduleService));
        this.button = {
            type: 'text',
            text: ['global_create', ' ', 'itemTranslation.module'],
            isButton: true,
            action: {
                type: 'popup',
                title: { type: 'text', text: ['global_create', ' ', 'itemTranslation.language'] },
                form: this.getForm(this.module),
            },
        };
    }

    private getForm(module: ModuleModel): Form {
        return {
            routeTo: './',
            inputs: [
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'title',
                    data: module.title,
                    name: 'title',
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'description_title',
                    data: module.descriptionTitle,
                    name: 'descriptionTitle',
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'description',
                    data: module.description,
                    name: 'description',
                },
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'icon',
                    data: module.icon,
                    name: 'icon',
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'number',
                    text: 'order',
                    data: module.order,
                    name: 'order',
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'edit_width',
                    data: module.editWidth,
                    name: 'editWidth',
                },
                {
                    type: 'form',
                    inputType: 'checkbox',
                    text: 'active',
                    data: module.active,
                    name: 'active',
                },
            ],
            submit: async (data: any, formData: Map<string, any>): Promise<boolean> => {
                module.title = formData.get('title');
                module.descriptionTitle = formData.get('descriptionTitle');
                module.description = formData.get('description');
                module.active = formData.get('active');
                module.icon = formData.get('icon');
                module.order = formData.get('order');
                module.editWidth = formData.get('editWith');
                await this.moduleService.save(new ModuleModel(null, module));
                this.setCreateButton();
                this.tableService.setObjects(await this.moduleService.getAll());
                return true;
            },
        };
    }
}
