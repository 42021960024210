import { Component } from '@angular/core';
import { AuthorisationService } from '../../services/auth/authorisation.service';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent {
    /*
     *  constructor
     *
     *  Start component
     */
    public constructor(public auth: AuthorisationService) {}
}
