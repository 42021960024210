import { Component } from '@angular/core';
import { Format } from '../../../../core/components/dragdrop/dragdrop.component';

@Component({
    selector: 'dragDrop',
    templateUrl: './drag-en-drop.component.html',
    styleUrls: ['./drag-en-drop.component.less'],
})
export class DragDropComponent {
    dragdropList: Format[] = [
        { id: '1', name: 'item 1', items: [] },

        { id: '2', name: 'item 2', items: [] },

        { id: '3', name: 'item 3', text: 'Dit is een text', items: [] },

        {
            id: '4',
            name: 'item 4',
            items: [
                {
                    id: '4.1',
                    name: 'item 4.1',
                    items: [
                        {
                            id: '4.1.1',
                            name: 'item 4.1.1',
                            items: [
                                { id: '4.1.1.1', name: 'item 4.1.1.1', items: [] },
                                { id: '4.1.1.2', name: 'item 4.1.1.2', items: [] },
                            ],
                        },
                        {
                            id: '4.1.2',
                            name: 'item 4.1.2',
                            items: [
                                { id: '4.1.2.1', name: 'item 4.1.2.1', items: [] },
                                {
                                    id: '4.1.2.2',
                                    name: 'item 4.1.2.2',
                                    items: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
}
