import {
    Component,
    Input,
    forwardRef,
    ViewChild,
    ElementRef,
    AfterViewInit,
    HostListener,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sSelect',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements AfterViewInit, ControlValueAccessor {
    /** The current value of the selector */
    public set value(val: string) {
        if ((!this.options || this.options.length > 5) && this.select != undefined) {
            const eleValue: string = val == null ? '' : String(val);
            const $select: HTMLSelectElement = this.select.nativeElement;

            $select.value = null; // Make sure the shown value changes
            $select.value = eleValue;
        }
        this._value = val !== this._value ? val : undefined;

        this.onChange(this._value);
    }
    public get value(): string {
        return this._value;
    }
    @Input('value') private _value: string = '';

    /** The visible name of the selector */
    @Input('label') public label: string = '';

    /** Whether an option needs to be selected */
    @Input('required') public required: any = null;

    /** The disabled state, `null`, `undefined` or `false` means it's not disabled */
    @Input('disabled') public disabled: any = null;

    /** The classes of the selector */
    @Input('sClass') public sClass: string = '';

    /** The options the user can select */
    @Input('options') public options: Array<{ id: string; value: string }> = null;

    @Input() public clone: boolean = false;
	@Input() public noDefault: boolean = false;

    // Hooks from Angular
    public onChange: (val: any) => void = () => {};
    public onTouched: () => void = () => {};

    /** The `<select>` element */
    @ViewChild('select', { static: false }) private select: ElementRef;
    @ViewChild('input', { static: false }) private input: ElementRef;

    public cloneOpen: boolean = false;
    public cloneItems: { name: string; value: string; image: string; price: number }[] = [];
    public cloneItemSearchValue: string = '';
    public cloneItemSelector: number = -1;
    public cloneItemSelectorMap = [];

    public constructor() {}

    ngAfterViewInit(): void {
        if (this.clone) {
            Array.from(this.select.nativeElement.children as HTMLInputElement[]).forEach((element) => {
                this.cloneItems.push({
                    name: element.innerText,
                    value: element.value,
                    image: element.getAttribute('image'),
                    price: Number(element.getAttribute('price')),
                });
            });
            if (!this.value && this.cloneItems.length > 0) {
                this.value = this.cloneItems[0].value;
            }
        }
    }

    public writeValue(value: string): void {
        this.value = value !== this.value ? value : undefined;
    }
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * When the user chooses an option in the <select> element
     *
     * @param     event    The    (select) event
     * @author    Mike van Os <mike@safira.nl>
     */
    public choose(event: Event): void {
        this.value = (event.target as HTMLInputElement).value;
    }

    /**
     * When the user clicks an option in the short select box
     *
     * @param     value    The    id of the option the user clicked
     * @author    Mike van Os <mike@safira.nl>
     */
    public switch(value: string): void {
        if (this.isDisabled()) return;

        this.value = value;
        this.onChange(this.value);
        this.onTouched();
    }

    /**
     * Has this selector been disabled?
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public isDisabled(): boolean {
        return this.disabled !== false && this.disabled != null;
    }

    /**
     * Is this selector required?
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public isRequired(): boolean {
        return this.required !== false && this.required != null;
    }

    public getCloneValue(value: string): string {
		if (this.value === null) return value === 'name' ? 'Kies een kleur':'';
        let item = this.cloneItems.find((item) => item.value == this.value);
        return item ? item[value] : '';
    }

    public openSelectCloneWithDelay() {
        // delay om code uit te voeren na de host listener
        setTimeout(() => {
            this.cloneOpen = true;
            if (!navigator.userAgent.match(/Android/i)) {
                this.input.nativeElement.focus();
            }
        });
    }

    public displayCloneItem(item) {
        if (item.value == this.value) return false;
        if (!this.cloneItemSearchValue) return true;
        return this.compareCloneNameToSearchValue(item) ? true : false;
    }

    public setCloneSelection(side) {
        if (side === 'down' && this.cloneItemSelectorMap.length - 1 > this.cloneItemSelector) {
            this.cloneItemSelector++;
        } else if (side === 'up' && this.cloneItemSelector > 0) {
            this.cloneItemSelector--;
        }
    }

    public setCloneValue() {
        if (this.cloneItemSelector < 0 || !this.cloneOpen) return;
        this.value = this.cloneItems[this.cloneItemSelectorMap[this.cloneItemSelector]].value;
        this.select.nativeElement.click();
    }

    @HostListener('document:click') click() {
        this.cloneOpen = false;
        this.cloneItemSearchValue = '';
        this.cloneItemSelector = -1;
    }

    private compareCloneNameToSearchValue(item): boolean {
        return item.name.toLowerCase().trim().startsWith(this.cloneItemSearchValue.toLowerCase());
    }

    public buildCloneItemSelectorMap() {
        let cloneItemSelectorMap = [];
        this.cloneItems.forEach((item, i) => {
            if (this.compareCloneNameToSearchValue(item) && item.value != this.value) {
                cloneItemSelectorMap.push(i);
            }
        });
        if (JSON.stringify(this.cloneItemSelectorMap) !== JSON.stringify(cloneItemSelectorMap)) {
            this.cloneItemSelector = 0;
        }
        if (cloneItemSelectorMap.length === 0) {
            this.cloneItemSelector = -1;
        }
        this.cloneItemSelectorMap = cloneItemSelectorMap;
    }
}
