import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { AjaxService } from './ajax.service';
import { Language, TranslationService } from './translation.service';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    public constructor(
        private ajax: AjaxService,
        private config: ConfigService,
        private util: UtilService,
        private language: LanguageService,
        private translation: TranslationService
    ) {}

    /**
     * gets the translation and replaces %s/%d with the args.
     *
     * @author    damian
     * @param     label    $the     label to search for, can be key.key.value or value
     * @param     args     $list    of arguments
     */
    public getParsedResult(label: string, args: any[] = []): string {
        if (this.language.current === undefined) {
            return;
        }
        return this.util.sprintf(this.get(this.language.current.iso, label), args);
    }

    /**
     * Gets the translated string
     *
     * @author    damian
     * @param     lang     $iso
     * @param     label    $key
     */
    public get(lang: string, label: string | number): string {
        if (!this.translation.languages[lang] || this.language.loading) {
            return '';
        }

        const stringLabel = label.toString();

        let parts: string[] = stringLabel.split('.');
        if (parts.length <= 1) {
            parts.unshift('');
        }
        let value: any = this.translation.languages[lang];

        parts.forEach((part, index) => {
            if (!value[part] && index == parts.length) {
                return stringLabel;
            }
            if (value[part]) {
                value = value[part];
                return;
            }
        });
        return typeof value === 'string' ? value : stringLabel;
    }

    /**
     * Return all labels of the current language.
     */
    public getLabels(): Language {
        return this.translation.languages[this.language.current.iso];
    }

    /**
     * Sets the language to use.
     * @author    damian
     * @param     lang    $iso
     */
    public async use(lang: string) {
        this.language.setCurrentLanguage();

        if (this.translation.languages[lang] !== undefined) {
            this.language.languageChange.next();
            return;
        }

        await this.translation.retrieveTranslations();
    }
}
