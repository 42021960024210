import { AuthenticateService } from 'src/app/core/services/auth/authenticate.service';
import { Component } from '@angular/core';

@Component({
    selector: 'my-orders-template',
    templateUrl: './my-orders-template.component.html',
    styleUrls: ['./my-orders-template.component.less'],
})
export class MyOrdersTemplateComponent {

    constructor(public authenticateService: AuthenticateService) {}
}
