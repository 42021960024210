import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'sButton',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less'],
})
export class ButtonComponent implements OnInit {
    @Input('sClass') sClass: string = '';
    @Input('type') type: string = 'button';
    @Input('disabled') disabled: boolean = false;
    @Input('sStyle') sStyle: string = '';
    @Output('buttonClick') private onClick: EventEmitter<void> = new EventEmitter<void>();

    public constructor() {}

    public ngOnInit(): void {}

    public click() {
        this.onClick.next();
    }
}
