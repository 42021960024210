import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'geo-overview',
    templateUrl: './geo-overview.component.html',
    styleUrls: ['./geo-overview.component.less'],
})
export class GeoOverviewComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
