import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { CrudServiceModel } from '../models/crud/crud-service.model';
import { ModuleModel } from '../models/module.model';

@Injectable({
    providedIn: 'root',
})
export class ModuleService extends CrudServiceModel<ModuleModel> {
    protected readonly create = ModuleModel;
    protected readonly module: string = 'module';

    public constructor(protected ajax: AjaxService) {
        super();
    }

    protected validation(): boolean {
        return true;
    }
}
