import { ResponsiveService } from 'src/app/core/services/responsive.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Html5Qrcode } from 'html5-qrcode';
import { CameraDevice, Html5QrcodeResult } from 'html5-qrcode/esm/core';

@Component({
    selector: 'sBarcodeScanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.component.less'],
})
export class BarcodeScannerComponent implements OnInit, OnDestroy, OnChanges {
    @Output() public camerasFound: EventEmitter<CameraDevice[]> = new EventEmitter<CameraDevice[]>();
    @Output() public scanSuccess: EventEmitter<Html5QrcodeResult> = new EventEmitter<Html5QrcodeResult>();
    @Input() public webcamOn: boolean;
    @Input() public camera: string;
    private scanner: Html5Qrcode;

    constructor(public responsive: ResponsiveService) {}

    public ngOnInit(): void {
        this.scanner = new Html5Qrcode('reader');
        this.getCamera();
    }
    public ngOnDestroy(): void {}
    ngOnChanges(changes: SimpleChanges): void {
        if (this.webcamOn) {
            for (const [name, change] of Object.entries(changes)) {
                switch (name) {
                    case 'camera':
                        if (!change.previousValue) break;
                        this.scanner.stop();
                        break;
                    default:
                        break;
                }
            }
            this.startCam();
        } else {
            this.scanner.stop();
        }
    }

    private getCamera() {
        Html5Qrcode.getCameras()
            .then((devices) => {
                this.camerasFound.next(devices);
            })
            .catch(() => {
                document.querySelector<HTMLElement>('.not-available').style.removeProperty('display');
                document.querySelector('.not-available').classList.add('visible');
            });
    }

    private startCam() {
        if (this.camera) {
            this.scanner
                .start(
                    this.camera,
                    { fps: 30 },
                    (decodedText, decodedResult) => {
                        this.scanSuccess.next(decodedResult);
                    },
                    () => {}
                )
                .catch(() => {
                    document.querySelector<HTMLElement>('.not-available').style.removeProperty('display');
                    document.querySelector('.not-available').classList.add('visible');
                })
                .then(() => {
                    document.querySelector('video').style.width = '100%';
                });
        }
    }
}
