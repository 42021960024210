import { Component, ElementRef, Input } from '@angular/core';
import { ValidationService } from '../../services/validation.service';
import { UtilService } from '../../services/util.service';
import { Subject } from 'rxjs';

const ERRORTEXT: string = 'error-text';
const VALIDTEXT: string = 'valid-text';
const FUNCTIONSUFFIX = 'Validate';
const MINUSOFFSETTOP: number = 100;

@Component({
    selector: 'sForm',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.less'],
})
export class FormComponent {
    @Input('buttonText') public buttonText: string;
    @Input('hideFormSuccess') public hideFormSuccess: string | boolean = false;
    @Input('hideButton') public hideButton: boolean = false;

    public formSubmitted = new Subject<void>();

    public constructor(
        private elt: ElementRef,
        private validationService: ValidationService,
        private util: UtilService
    ) {}

    /**
     * validateContent
     *
     * Validate all possible input types
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public validateContent(): void {
        let valid: boolean = true;
        let errorText: string = '';

        // Get all elements in the card body
        let $form = this.elt.nativeElement.childNodes[0];

        let htmlCollection = $form.getElementsByTagName('sInput');
        let elements = [...htmlCollection];

        elements.forEach(($element: HTMLElement) => {
            if (this.elementValidationPossible($element.localName)) {
                let myFunctionReturnValues: [boolean, string] =
                    this.validationService[$element.localName + FUNCTIONSUFFIX]($element);
                if (!myFunctionReturnValues[0]) {
                    $element.classList.add('validation-error');
                    errorText = errorText + `${myFunctionReturnValues[1]}<br/>`;
                    valid = false;
                }
            }
        });

        // Importing right text in card
        elements.forEach(($element: HTMLElement) => {
            if ($element.classList && $element.classList.contains('validation-text')) {
                if (!valid) {
                    $element.classList.add(ERRORTEXT);
                    $element.classList.remove('hide');

                    $element.innerHTML = errorText;
                } else {
                    $element.classList.remove(ERRORTEXT);
                    $element.classList.add(VALIDTEXT);
                    $element.classList.add('hide');
                }
            }
        });

        /* gives the user feedback */
        if (!valid) {
            const offsetTop: number = this.util.getOffset(<HTMLElement>elements[0]).top;
            window.scrollTo({ top: offsetTop - MINUSOFFSETTOP, behavior: 'smooth' });
        } else {
            // Notify subscribers that the form has been validated and submitted
            // Also sends a key/value object containing all form data
            this.formSubmitted.next();
            if (this.hideFormSuccess === false) this.openFormSucces($form);
        }
    }

    /**
     * validationPossible
     *
     * is there a element thats possible to validate
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public validationPossible(): boolean {
        return this.validationService.validationPossible(this.elt.nativeElement);
    }

    /**
     * closeFormSucces
     *
     * closes the succes form and shadow
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public closeFormSuccess(): void {
        const $form: HTMLElement = this.elt.nativeElement.childNodes[0];
        for (let index: number = 0; index < $form.children.length; index++) {
            const $child: HTMLElement = <HTMLElement>$form.children[index];
            if ($child.classList.contains('form-succes') || $child.classList.contains('form-succes-shadow')) {
                $child.classList.add('hide');
            }
        }
        this.unlockBody($form);
    }

    /**
     * lockBody
     *
     * locks the scroll from the body
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private lockBody($form: HTMLElement): void {
        const scrollTop: number = window.pageYOffset;
        const $content: HTMLElement = <HTMLElement>(
            $form.parentNode.parentNode.parentNode.parentNode.parentNode
        );

        $content.style.top = scrollTop + 'px';
        document.body.style.height = '100%';
        document.body.style.overflow = 'hidden';
    }

    /**
     * unlockBody
     *
     * unlocks the scroll from the body
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private unlockBody($form: HTMLElement) {
        const scrollTop: number = window.pageYOffset;
        const $content: HTMLElement = <HTMLElement>(
            $form.parentNode.parentNode.parentNode.parentNode.parentNode
        );

        document.body.style.height = '';
        document.body.style.overflow = '';
        $content.style.top = '';

        window.scrollTo(0, scrollTop);
    }

    /**
     * openFormSucces
     *
     * opens the succes form and shadow
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private openFormSucces($form: HTMLElement): void {
        for (let index: number = 0; index < $form.children.length; index++) {
            const child: HTMLElement = <HTMLElement>$form.children[index];
            if (child.classList.contains('form-succes') || child.classList.contains('form-succes-shadow')) {
                child.classList.remove('hide');
            }
        }
        this.lockBody($form);
    }

    /**
     * elementValidationPossible
     *
     * is it possible to validate this element
     */
    private elementValidationPossible(localName: string): boolean {
        return this.validationService.elementValidationPossible(localName);
    }
}
