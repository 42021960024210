import { AuthenticateService } from 'src/app/core/services/auth/authenticate.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../../core/services/user/user.service';
@Component({
    selector: 'general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.less'],
})
export class GeneralComponent implements OnInit {
    showPasswordBtn: boolean = false;
    constructor(
        public userService: UserService,
        public authenticateService: AuthenticateService,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.router.url.slice(this.router.url.lastIndexOf('/') + 1) !== 'new') {
            this.showPasswordBtn = true;
        }
    }
}
