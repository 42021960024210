import { Injectable } from '@angular/core';
import { ToasterService } from './toaster.service';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { AjaxService } from './ajax.service';
import QRCodeStyling, { FileExtension } from 'qr-code-styling';

const CHUNK_SIZE: number = 4 * 1024 * 1024; // 4MiB
const FILE_TRESHOLD: number = 8 * 1024 * 1024; // 8MiB.

@Injectable({
    providedIn: 'root',
})
export class UploadService {
    public constructor(private ajax: AjaxService, private toaster: ToasterService) {}
    public qrcode: QRCodeStyling;

    /**
     *
     * Post uploads an image to the api
     *
     * @author    Bas Hoppenbrouwers <bas@safira.nl>
     */
    public uploadImage(file: File, loadingTarget?: string): Promise<unknown> {
        return new Promise((resolve) => {
            this.ajax.upload('files/uploadImage', file, 'file', null, loadingTarget).then((res: any) => {
                if (res.error.code === 0) {
                    this.toaster.success(res.error.message);
                    resolve(res);
                }
            });
        });
    }

    /**
     * Upload files to the api
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public async uploadFiles(files: Array<File>, endPoint: string, loadingTarget?: string): Promise<any> {
        const largeFiles: Array<File> = [];
        const smallFiles: Array<File> = [];

        for (const file of files) {
            (file.size > FILE_TRESHOLD ? largeFiles : smallFiles).push(file);
        }

        const res: Array<any> = [];

        const smallFileUploads: Array<Promise<unknown>> = [];
        for (const file of smallFiles) {
            smallFileUploads.push(this.ajax.upload(endPoint, file, 'file', null, loadingTarget));
        }
        res.push(...(await Promise.all(smallFileUploads)));

        for (const file of largeFiles) {
            res.push(await this.uploadFileChunked(file));
        }

        for (const result of res) {
            if (result.error.code !== 0) {
                this.toaster.error(result.error.message);
                throw res;
            }
        }
        this.toaster.success(res[0].error.message);
        return res;
    }

    /**
     *
     * Get a list of all the files that are currently stored on the server
     *
     * @author    Bas Hoppenbrouwers <bas@safira.nl>
     */
    public async getUserFiles(): Promise<any> {
        return await this.ajax.get('files/getUserFiles');
    }

    /**
     *
     * Download one of the files stored on the server in base64 format
     *
     * @author    Bas Hoppenbrouwers <bas@safira.nl>
     */
    public async downloadUserFile(fileId: string): Promise<unknown> {
        return await this.ajax.get('files/downloadFile/' + fileId);
    }

    /**
     * Method usage:
     * Call any endpoint function which handles logic and data
     * Endpoint function should start file download.
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     */
    public downloadLargeUserFile(url: string, data: any, loadingTarget?: string): Promise<any> {
        return this.ajax.postRaw(url, data, loadingTarget).then((res: HttpResponse<Blob>) => {
            if (res.body.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.body);

                return new Promise((resolve, reject) => {
                    reader.addEventListener('load', (event) => {
                        const msg: any = JSON.parse(<string>event.target.result).data;
                        this.toaster.error(msg.error.message);
                        reject(msg);
                    });
                });
            }
            const filename: string = res.headers
                .get('content-disposition')
                .split(';')[1]
                .split('filename')[1]
                .split('=')[1]
                .trim()
                .replace(/['"]+/g, '');
            saveAs(res.body, filename);
            return true;
        });
    }

    /**
     * Upload a file in chunks
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    private async uploadFileChunked(file: File): Promise<any> {
        const chunks: Array<Blob> = [];
        const slice: (start?: number, end?: number, contentType?: string) => Blob =
            'slice' in file ? file.slice.bind(file) : (file as any).webkitSlice.bind(file);

        for (let i: number = 0; i < file.size; i += CHUNK_SIZE) {
            chunks.push(slice.call(file, i, i + CHUNK_SIZE));
        }
        if (file.size === 0) {
            chunks.push(file);
        }

        const id: string = <string>await this.ajax.post('/files/startChunkedUpload', {
            name: file.name,
            size: file.size,
        });

        let lastResult: any = {};
        for (const chunk of chunks) {
            const postData: FormData = new FormData();

            postData.append('chunk', chunk);
            postData.append('id', id);

            lastResult = await this.ajax.post('/files/uploadChunk', postData);
        }

        if (lastResult.status === 'incomplete') {
            this.toaster.error('Kon volledig bestand niet uploaden');
            throw lastResult;
        }

        this.toaster.success(lastResult.error.message);
        return lastResult;
    }

    /**
     * getFilesZip
     *
     * gets zip of all files for this request
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      none
     * @returns    void
     */
    public getFilesZip(url): void {
        this.ajax.downloadZip(url).then((result) => {
            this.saveToBrowser(result['blob'], result['filename'], result['type']);
        });
    }

    /**
     * saveTobrowser
     *
     * Saves a file to the browser using file-saver
     *
     * @author    Eric van Doorn <Eric@safira.nl>
     * @param     blobData
     * @param     filename
     * @param     type
     * @Return    void
     */
    public saveToBrowser(blobData: Blob, filename: string, type: string): void {
        const blob: Blob = new Blob([blobData], { type: type });
        saveAs(blob, filename);
    }

    public downloadQrCode(name: string, extension: FileExtension) {
        this.qrcode.download({ name: name, extension: extension });
    }
}
