import { Injectable, Type } from '@angular/core';
import { AjaxService } from './ajax.service';
import { Router } from '@angular/router';

export class ProductGroup {
    title: string;
    link: string;
    groupId: number;
    images: { image: string; dimensionId: number };
}

export class Product {
    id: number;
    size: string;
    article_number: string;
    price: number;
    discount: number;
    m2_per_panel: number;
    quantity: string;
    subArticles: SubArticle[];
    images: { image: string; dimensionId: number };
    fields: any;
    amount: number;
    selectedType: number;
}

export class cartProduct {
    id: number;
    amount: number;
}

export class SubArticle {
    id: number;
    name: string;
    price: number;
    discount: number;
    m2_per_panel: number;
    images: { image: string; dimensionId: number };
    fields: any;
}
export class FilterGroup {
    title: string;
    filters: { title: string; groupId: number; checked: boolean }[];
}

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    public productGroups: ProductGroup[];
    public productGroupPromise: Promise<any>;
    public products: Product[];
    public filterGroups: FilterGroup[] = [];
    public activeGroupId: number = 0;
    public filtersOpen: boolean = false;
    public cart: cartProduct[];
    public cartPromise: Promise<any>;
    public totalDetails: { end_amount: 0; cost: 0 };

    constructor(private ajax: AjaxService, private router: Router) {}

    public getProductGroups(): void {
        if (this.productGroups) return;
        const promise = new Promise<any>((resolve) => {
            this.ajax.get('/product/getProductGroups').then((productGroups) => {
                this.productGroups = productGroups;
                resolve(true);
            });
        });
        this.productGroupPromise = promise;
    }

    public async getFilterGroups(groupId = this.activeGroupId, filters = this.filterGroups) {
        this.filterGroups = await this.ajax.post('/product/getFilterGroups', { groupId, filters });
    }

    public async getActiveGroupIdByType(type) {
        return await this.ajax.post('/product/getActiveGroupIdByType', { type });
    }

    public async getProductsByGroupId(groupId = this.activeGroupId, filters = this.filterGroups) {
        this.products = undefined;
        this.products = await this.ajax.post('/product/getProductsByGroupId', { groupId, filters });
        await this.cartPromise;
        this.syncProductsToCartAmount();
    }

    public async getProductsFromCart() {
        this.products = undefined;
        this.products = await this.ajax.get('/product/getProductsFromCart');
        await this.cartPromise;
        this.syncProductsToCartAmount();
    }

    public getCart(): void {
        if (this.cart) return;
        const promise = new Promise<any>((resolve) => {
            this.ajax.get('/order/getCart').then((cart) => {
                this.cart = cart;
                resolve(true);
            });
        });
        this.cartPromise = promise;
    }

    public async updateCart(id, amount) {
        await this.cartPromise;
        let cartProductIndex = this.cart.findIndex((cartProduct) => +cartProduct.id === +id);
        if (amount == 0) {
            if (cartProductIndex > -1) {
                this.cart.splice(cartProductIndex, 1);
            }
        } else if (cartProductIndex > -1) {
            this.cart[cartProductIndex].amount = amount;
        } else {
            this.cart.push({ id: +id, amount });
        }
        this.setCart();
    }

    public async setCart() {
        await this.cartPromise;
        return this.ajax.post('order/updateCart', { products: this.cart });
    }

    public getActiveProductGroup(): ProductGroup | object {
        if (!this.productGroups || this.activeGroupId === 0) return {};
        return this.productGroups.find((productGroup) => productGroup.groupId === this.activeGroupId);
    }

    public async setActiveProductGroup(groupId) {
        if (groupId == this.activeGroupId) return;
        this.activeGroupId = groupId;
        this.filtersOpen = false;
        this.filterGroups = [];
        await Promise.all([this.getProductsByGroupId(), this.getFilterGroups()]);
    }

    public syncProductToCartAmount(product: Product): void {
        let cartProduct = this.cart.find(
            (cartProduct) => cartProduct.id == (product.subArticles ? product.selectedType : product.id)
        );
        product.amount = cartProduct ? cartProduct.amount : 0;
    }

    public getCartTotal(cart = this.cart): number {
        let cartTotal = 0;
        if (!this.products) return 0;
        cart.forEach((cartProduct) => {
            let product = this.products.find(
                (product) => cartProduct.id == (product.subArticles ? product.subArticles[0].id : product.id)
            );
            if (product) {
                cartTotal +=
                    (product.subArticles && product.subArticles[0].price
                        ? product.subArticles[0].price
                        : product.price) *
                    (product.subArticles && product.subArticles[0].m2_per_panel
                        ? product.subArticles[0].m2_per_panel
                        : product.m2_per_panel) *
                    (1 -
                        (this.showDiscount()
                            ? (product.subArticles && product.subArticles[0].discount
                                  ? product.subArticles[0].discount
                                  : product.discount) / 100
                            : 0)) *
                    cartProduct.amount;
            }
        });
        return cartTotal;
    }

	public getCartTotalWithoutDiscount(cart = this.cart): number {
        let cartTotal = 0;
        if (!this.products) return 0;
        cart.forEach((cartProduct) => {
            let product = this.products.find(
                (product) => cartProduct.id == (product.subArticles ? product.subArticles[0].id : product.id)
            );
            if (product) {
                cartTotal +=
                    (product.subArticles && product.subArticles[0].price
                        ? product.subArticles[0].price
                        : product.price) *
                    (product.subArticles && product.subArticles[0].m2_per_panel
                        ? product.subArticles[0].m2_per_panel
                        : product.m2_per_panel) *
                    cartProduct.amount;
            }
        });
        return cartTotal;
    }

	public getCartTotalWithDiscount(cart = this.cart): number {
        let cartTotal = 0;
        if (!this.products) return 0;
        cart.forEach((cartProduct) => {
            let product = this.products.find(
                (product) => cartProduct.id == (product.subArticles ? product.subArticles[0].id : product.id)
            );
            if (product) {
                cartTotal +=
                    (product.subArticles && product.subArticles[0].price
                        ? product.subArticles[0].price
                        : product.price) *
                    (product.subArticles && product.subArticles[0].m2_per_panel
                        ? product.subArticles[0].m2_per_panel
                        : product.m2_per_panel) *
                    (1 -
                        (product.subArticles && product.subArticles[0].discount
                                  ? product.subArticles[0].discount
                                  : product.discount) / 100) *
                    cartProduct.amount;
            }
        });
        return cartTotal;
    }

    public getDiscountTotal(cart = this.cart): number {
        let discountTotal = 0;
        if (!this.products) return 0;
        cart.forEach((cartProduct) => {
            let product = this.products.find(
                (product) => cartProduct.id == (product.subArticles ? product.subArticles[0].id : product.id)
            );
            if (product) {
                discountTotal +=
                    (product.subArticles && product.subArticles[0].price
                        ? product.subArticles[0].price
                        : product.price) *
                    (product.subArticles && product.subArticles[0].m2_per_panel
                        ? product.subArticles[0].m2_per_panel
                        : product.m2_per_panel) *
                    (this.showDiscount()
                        ? (product.subArticles && product.subArticles[0].discount
                              ? product.subArticles[0].discount
                              : product.discount) / 100
                        : 0) *
                    cartProduct.amount;
            }
        });
        return discountTotal;
    }

    public getAllOrders() {
        return this.ajax.get('order/getAllOrders');
    }

    public getOrderDetail(orderId: number) {
        return this.ajax.post('order/getOrderDetail', { orderId });
    }

    public async downloadOrderInvoice(orderId: number) {
        let invoiceData = await this.ajax.post('order/downloadOrderInvoice', { orderId });
        saveAs(invoiceData['pdf'], invoiceData['name']);
    }

    public async getOrderTotalDetails() {
        this.totalDetails = await this.ajax.get('order/getOrderTotalDetails');
    }

    public getShippingDetails(zipcode: string) {
        return this.ajax.post('order/getShippingDetails', {zipcode});
    }

    public confirmOrder(shipping: object, address: object, extras: object) {
        return this.ajax.post('order/confirmOrder', { shipping, address, extras });
    }

    public getConfirmedOrder() {
        return this.ajax.get('order/getConfirmedOrder');
    }

    public showDiscount() {
        return this.router.url.includes('bestelling');
    }

    private syncProductsToCartAmount(): void {
        this.products.forEach((product) => {
            this.syncProductToCartAmount(product);
        });
    }
}
