import { Component, Input } from '@angular/core';
import { ClickAction } from '../../../../models/action.model';

@Component({
    selector: 'sClickAction',
    templateUrl: './click-action.component.html',
})
export class ClickActionComponent {
    @Input() action: ClickAction;
}
