import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from './storage.service';

export const themes = [
    { name: 'default', icon: 'sun' },
    { name: 'dark', icon: 'moon' },
] as const;

const colors: string[] = [
    'primary',
    'primary-light',
    'primary-lighter',
    'secondary',
    'secondary-light',
    'secondary-lighter',
    'neutral1',
    'neutral2',
    'neutral3',
    'neutral4',
    'neutral5',
    'black',
    'white',
    'background',
    'body-background',
    'font',
    'light-font',
    'disabled-font',
    'border',
    'error',
    'error-light',
    'success',
    'success-light',
    'alert',
    'alert-light',
    'notification',
    'notification-light',
];

export type Theme = typeof themes[number];

export type ThemeName = typeof themes[number]['name'];

@Injectable({
    providedIn: 'root',
})
export class ThemeService {

    public changed: Subject<void> = new Subject<void>();

    constructor(private storage: StorageService) {
        this.loadTheme();
    }

    get current(): Theme { return themes.find(theme => theme.name === (this.storage.getStorage('theme'))) ?? themes[0]; }

    get next(): Theme { return this.index + 1 < themes.length ? themes[this.index + 1] : themes[0]; }

    private get index() { return themes.findIndex(theme => theme.name === this.current.name); }

    public setTheme(theme: ThemeName) {

        if (theme === 'default') {
            this.storage.deleteStorage('theme');
            this.loadTheme();
            return;
        }
        if (theme === this.current.name) { return; }
        this.storage.setStorage('theme', theme);
        this.loadTheme();
    }

    private loadTheme() {
        for (const color of colors) {
            const styles = window.getComputedStyle(document.body);
            document.documentElement.style.setProperty('--' + color, styles.getPropertyValue('--' + color + '-' + this.current.name));
        }

        this.changed.next();
    }
}
