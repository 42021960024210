import { Component } from '@angular/core';

@Component({
    selector: 'sFooter',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less'],
})
export class FooterComponent {
    constructor() {}
}
