import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'order-progress',
    templateUrl: './order-progress.component.html',
    styleUrls: ['./order-progress.component.less'],
})
export class OrderProgressComponent {
    @Input() stage: number;

    constructor(public router: Router) {}
}
