import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemComponent } from './item.component';
import { RouterModule } from '@angular/router';
import { TranslationModule } from '../../../modules/translate.module';
import { FormItemModule } from './form-item/form-item.module';
import { PopupModule } from '../popup/popup.module';
import { FormModule } from '../form/form.module';
import { ActionComponent } from './action/action.component';
import { ClickActionComponent } from './action/click-action/click-action.component';
import { RouteActionComponent } from './action/route-action/route-action.component';
import { PopupActionComponent } from './action/popup-action/popup-action.component';
import { TextItemComponent } from './text-item/text-item.component';
import { GroupItemComponent } from './group-item/group-item.component';
import { DropdownActionComponent } from './action/dropdown-action/dropdown-action.component';
import { BoolItemComponent } from './bool-item/bool-item.component';
import { RowItemComponent } from './row-item/row-item.component';
import { AnchorActionComponent } from './action/anchor-action/anchor-action.component';

@NgModule({
    declarations: [
        ItemComponent,
        RowItemComponent,
        GroupItemComponent,
        TextItemComponent,
        BoolItemComponent,
        ActionComponent,
        AnchorActionComponent,
        RouteActionComponent,
        ClickActionComponent,
        PopupActionComponent,
        DropdownActionComponent,
    ],
    exports: [ItemComponent, ActionComponent, RowItemComponent],
    imports: [CommonModule, RouterModule, TranslationModule, FormItemModule, PopupModule, FormModule],
})
export class ItemModule {}
