import { Component, Input, ElementRef } from '@angular/core';

let id: number = 0;

@Component({
    selector: 'sToggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.less'],
})
export class ToggleComponent {
    //holds the name of the input
    @Input('name') public name: string;

    //holds the label
    @Input('label') public label: string;

    //holds the value
    @Input('checked') public checked: boolean = false;

    @Input('disabled') public disabled: boolean = false;

    //holds all the classes of the toggle button
    @Input('sClass') public sClass: string = '';

    @Input('labelBold') public labelBold: boolean = false;

    public id: number;

    public constructor(private elt: ElementRef) {
        id += 1;
        this.id = id;
    }
}
