import { Component, Input } from '@angular/core';
import { item } from '../../models/item.model';

@Component({
    selector: 'sItem',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.less'],
})
export class ItemComponent {
    @Input() item: item;
}
