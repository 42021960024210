import { FormModule } from './../form/form.module';
import { FormsModule } from '@angular/forms';
import { GoogleMapsComponent } from './googlemaps.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPopupModule } from '../info-popup/InfoPopup.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [GoogleMapsComponent],
    imports: [CommonModule, FormsModule, FormModule, RouterModule, InfoPopupModule],
    exports: [GoogleMapsComponent],
})
export class GooglemapsModule {}
