import { CrudModel } from './crud/crud.model';

export class TranslationModel extends CrudModel<TranslationModel> {
    languageId: number;
    itemModuleId: number;
    label: string;
    value: string;

    module: string;

    public readonly excluded: string[] = ['module'];
}
