import { OrderTotalTemplateComponent } from './order-total-template/order-total-template.component';
import { MyOrdersDetailComponent } from './my-orders-detail/my-orders-detail.component';
import { MyOrdersTemplateComponent } from './my-orders-template/my-orders-template.component';
import { MyOrdersOverviewComponent } from './my-orders-overview/my-orders-overview.component';
import { ShippingComponent } from './shipping/shipping.component';
import { OrderProgressComponent } from './order-progress/order-progress.component';
import { ProductsModule } from './../products/products.module';
import { orderRouting } from './order.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbModule } from 'src/app/core/components/breadcrumb/breadcrumb.module';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { TranslationModule } from '../translate.module';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { FooterModule } from 'src/app/core/components/footer/footer.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';

@NgModule({
    declarations: [OrderTotalTemplateComponent, OrderOverviewComponent, OrderProgressComponent, ShippingComponent, ConfirmationComponent, MyOrdersTemplateComponent, MyOrdersOverviewComponent, MyOrdersDetailComponent],
    imports: [
        orderRouting,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BreadcrumbModule,
        FormsModule, //required for [(ngModel)]
        FormModule, // Custom Safira form module
        TranslationModule,
        FooterModule,
        ProductsModule,
    ],
})
export class OrderModule {}
