import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormModule } from '../../../core/components/form/form.module';
import { GeneralComponent } from './user-form/general/general.component';
import { AddressComponent } from './user-form/address/address.component';
import { RightsComponent } from './user-form/rights/rights.component';
import { TabScrollModule } from '../../../core/directives/tab-scroll/tab-scroll.module';
import { UserListComponent } from './user-list/user-list.component';
import { TableModule } from '../../../core/components/table/table.module';
import { UserComponent } from './user.component';
import { ItemModule } from '../../../core/components/item/item.module';
import { ValidationModule } from '../../../core/directives/validation/validation.module';
import { UserFormComponent } from './user-form/user-form.component';
import { PaginatorModule } from '../../../core/components/paginator/paginator.module';
import { PopupModule } from 'src/app/core/components/popup/popup.module';
import { TranslationModule } from '../../translate.module';
import { BreadcrumbModule } from '../../../core/components/breadcrumb/breadcrumb.module';

@NgModule({
    declarations: [
        UserComponent,
        UserFormComponent,
        GeneralComponent,
        AddressComponent,
        RightsComponent,
        UserListComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule, //required for [(ngModel)]
        FormModule, // Custom Safira form module
        PopupModule,
        TranslationModule,
        TabScrollModule,
        TableModule,
        ItemModule,
        ValidationModule,
        PaginatorModule,
        BreadcrumbModule,
    ],
    exports: [GeneralComponent, AddressComponent, RightsComponent, UserFormComponent, UserListComponent],
    providers: [GeneralComponent, AddressComponent, RightsComponent],
})
export class UserModule {}
