import { ApiModel } from '../crud/api.model';

export class UserAddressModel extends ApiModel<UserAddressModel> {
    public userId: number;
    public userAddressId: number;
    public countryId: string;
    public address: string;
    public houseNumber: number;
    public houseNumberSuffix: string;
    public zipcode: string;
    public city: string;
    public latitude: number;
    public longitude: number;
    protected readonly excluded: string[] = [];

    constructor(input?: any) {
        super(input);
    }
}
