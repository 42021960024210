import { CameraDevice, Html5QrcodeResult } from 'html5-qrcode/esm/core';
import { UploadService } from './../../../../core/services/upload.service';
import { TranslateService } from './../../../../core/services/translate.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { Component } from '@angular/core';

@Component({
    selector: 'barcode',
    templateUrl: './blocks-barcode.component.html',
    styleUrls: ['./blocks-barcode.component.less'],
})
export class BlocksBarcodeComponent {
    public format: string = 'CODE39';
    public barcodeTypes: Array<string> = [
        'CODE39',
        'CODE128',
        'CODE128A',
        'CODE128B',
        'CODE128C',
        'EAN8',
        'EAN13',
        'UPC',
        'ITF',
        'ITF14',
        'MSI',
        'MSI10',
        'MSI11',
        'MSI1010',
        'MSI1110',
        'pharmacode',
        'codabar',
        'qrcode',
    ];

    public scanning: boolean = true;
    public cameras: Array<CameraDevice> = [];
    public lastResults: Array<string> = [];
    public scanData: string = '1337';
    public webcamOn: boolean = true;
    public selectedcam: string;

    public constructor(
        public toaster: ToasterService,
        public translate: TranslateService,
        public uploadService: UploadService
    ) {}
    public onScanSuccess(result: Html5QrcodeResult) {
        this.scanData = result.decodedText;
        this.toaster.success('itemMedia.scan_succes');
    }
    public turnOffWebcam() {
        if (this.cameras.length > 0) {
            this.webcamOn = !this.webcamOn;
        }
    }
    public onCameraFound(result: CameraDevice[]) {
        this.cameras = result;
        this.selectedcam = result[0].id;
    }
}
