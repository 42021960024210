import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PaginatorService {
    public currentPage: number;
    public totalPages: number;
    public totalItems: number;
    public itemsPerPage: number;
    public pages: number[];
    public maxPageButtons: number;
    public skip: number;
    public paginationChanged: Subject<void> = new Subject<void>();
    private pageObjects: any[];
    private paginationObjects: Object[];
    private initialLoad: boolean = true;

    /**
     * If pagination is in the front-end use this method to change the list
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public setPaginationObjects(objects: Object[]): void {
        if (this.initialLoad) {
            this.resetPaginator();
        }
        this.paginationObjects = objects;
        this.initPagination();
    }

    public resetPaginator() {
        this.currentPage = 1;
        this.totalPages = 1;
        this.totalItems = 0;
        this.itemsPerPage = 10;
        this.pages = [];
        this.maxPageButtons = 3;
        this.skip = 0;
        this.pageObjects = [];
        this.initialLoad = false;
        this.paginationChanged.next();
    }

    /**
     * If pagination is in the back-end use this method to change list size
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public setTotalItems(totalItems: number): void {
        this.initPagination(totalItems);
    }

    /**
     * Get the object of the current page
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public getCurrentPageObjects(): any[] {
        return this.pageObjects;
    }

    /**
     * sets the current page after page number click
     *
     * @author    Eric van Doorn <eric@safira.nl>
     */
    public setPage(page: number): void {
        if (this.currentPage === page) {
            return;
        }

        this.currentPage = page;
        this.skip = (this.currentPage - 1) * this.itemsPerPage;

        // initialize pagination again to make sure the correct buttons are showed, also resets the grid data
        this.initPagination(this.totalItems);
    }

    /**
     * Adds 1 to the current page
     *
     * @author    Eric van Doorn <eric@safira.nl>
     */
    public nextPage(): void {
        if (this.currentPage >= this.totalPages) {
            return;
        }

        this.currentPage = this.currentPage + 1;

        // initialize pagination again to make sure the correct buttons are showed, also resets the grid data
        this.initPagination(this.totalItems);
    }

    /**
     * Substracts 1 from the current page
     *
     * @author    Eric van Doorn <eric@safira.nl>
     */
    public previousPage(): void {
        if (this.currentPage <= 1) {
            return;
        }

        this.currentPage = this.currentPage - 1;

        // initialize pagination again to make sure the correct buttons are showed, also resets the grid data
        this.initPagination(this.totalItems);
    }

    public setItemsPerPage(itemsPerPage: number) {
        this.itemsPerPage = itemsPerPage < 0 ? this.totalItems : itemsPerPage;
        this.initPagination();
    }

    /**
     * @param         totalItems    $number    when filled, this number will be set as total items and wil be used to calculate the amount of pages. (use when pagination is in back end)
     *
     * @author        Mike van Os <mike@safira.nl>
     * @lastEditBy    Lars Meeuwsen <lars@safira.nl>
     */
    private initPagination(totalItems?: number): void {
        if (this.initialLoad) {
            this.resetPaginator();
        }

        // set total amount of items
        this.totalItems = totalItems > 0 ? totalItems : this.paginationObjects.length;

        // set total pages based on the length of the array and amount of items to show
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

        // for each page, create a number in pages array
        const firstPage: number = 2;
        this.pages = [];
        for (let i: number = firstPage; i < this.totalPages; i++) {
            this.pages.push(i);
        }

        // To make sure the buttons fit the page, calculate a slice to show on the page. start can't be below 0.
        // Reduce maxPageButtons by 2 because the first and last pages are always visible. The true minimum amount of buttons will be 3
        const maxPageButtons: number = Math.max(this.maxPageButtons - 2, 1);
        const beforeAfter: number = Math.floor(maxPageButtons / 2);
        const start: number = Math.max(
            Math.min(this.currentPage - firstPage - beforeAfter, this.pages.length - maxPageButtons),
            0,
        );
        const end: number = start + maxPageButtons;

        // create the slice
        this.pages = this.pages.slice(start, end);

        // check current page, if the current page is bigger then total pages, set the last page as current.
        if (this.currentPage > this.totalPages && this.totalPages > 0) {
            this.currentPage = this.totalPages;
        }

        this.skip = (this.currentPage - 1) * this.itemsPerPage;

        // create slice for currentPage
        this.pageObjects = this.paginationObjects.slice(
            this.currentPage * this.itemsPerPage - this.itemsPerPage,
            this.currentPage * this.itemsPerPage,
        );

        this.paginationChanged.next();
    }
}
