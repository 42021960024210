import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '../../../core/services/validation.service';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';
import { ConfigService } from '../../../core/services/config.service';
import { AuthorisationService } from '../../../core/services/auth/authorisation.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
    public window = window;

    // Login credentials
    public email: string = '';
    public password: string = '';

    // Forgot password
    public forgotPassword = false;

    // redirect url after login
    public nextUrl: string;

    // 2fa popup (Google)
    public showTfaModal = false;
    public showTfaBarcode = false;
    public showTfaCodeInput = false;
    // 2fa (email)
    public showTfaEmailCode: boolean = false;
    public loginCode: string = '';
    public rememberMe: boolean = false;
    @ViewChild('codeInput', { static: true }) private codeInput: ElementRef;

    public sunday = false;

    /*
     *	public constructor
     *
     * 	Default constructor
     */

    public constructor(
        public authenticate: AuthenticateService,
        public config: ConfigService,
        public router: Router,
        public route: ActivatedRoute,
        public validation: ValidationService,
        private auth: AuthorisationService,
        private cd: ChangeDetectorRef
    ) {
        this.authenticate = authenticate;
        const today = new Date();
        if (today.getDay() === 0 && !config.isSafira) {
            this.sunday = true;
        } else if (this.auth.canAccess()) {
            if (this.nextUrl) {
                this.router.navigate([this.nextUrl]);
            }
            this.router.navigate(['/producten']);
        }
    }

    ngOnInit(): void {
        let url = sessionStorage.getItem('redirectUrl');
        if (url != null && url !== '/login') {
            this.nextUrl = url;
            sessionStorage.removeItem('redirectUrl');
        }
    }

    ngAfterViewInit() {
        document.getElementById('login-input').getElementsByTagName('input')[0].focus();
    }

    /**
     * Login a user with the filled in e-mail address and password
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public submit(): void {
        let rememberMe = this.showTfaEmailCode ? this.rememberMe : this.config.rememberMe;
        this.authenticate
            .login(this.email, this.password, rememberMe, this.nextUrl, this.showTfaEmailCode, this.loginCode)
            .then((res) => {
                if (res['authentication_method'] == '2' && res['error']['code'] == 3) {
                    this.showTfaEmailCode = true;
                    this.rememberMe = this.config.rememberMe;
                }
                // loginService.sessionToken will be filled in if the user enabled 2FA by Google Authenticator
                else if (res['authentication_method'] == '1' && this.authenticate.sessionToken !== '') {
                    this.showTfaModal = true;
                    if (!this.authenticate.skipBarcode) {
                        this.showTfaBarcode = true;
                        this.showTfaCodeInput = false;
                    } else {
                        this.showCodeInput();
                    }
                }
            });
    }

    /**
     * Login with a Google account via Firebase
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public loginWithGoogle(): void {
        this.authenticate.loginWithGoogle('/producten').then((success: boolean) => {
            if (!success) {
                return;
            }

            // loginService.sessionToken will be filled in if the user enabled 2FA
            if (this.authenticate.sessionToken !== '') {
                this.showTfaModal = true;
                if (!this.authenticate.skipBarcode) {
                    this.showTfaBarcode = true;
                    this.showTfaCodeInput = false;
                } else {
                    this.showTfaBarcode = false;
                    this.showTfaCodeInput = true;
                }
            }
        });
    }

    /**
     * Hide the QR code/2FA code input popup
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public closeModal(): void {
        this.showTfaModal = false;
        this.showTfaBarcode = false;
        this.showTfaCodeInput = false;

        this.authenticate.googleCode = '';
    }

    /**
     * Show the 2FA code input popup
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public showCodeInput(): void {
        this.showTfaBarcode = false;
        this.showTfaCodeInput = true;

        this.cd.detectChanges();
        this.codeInput.nativeElement.focus();
    }

    /**
     * checkGoogleCode
     *
     * @author    Romain van Maanen <romain@safira.nl>
     */
    public async checkCode() {
        await this.authenticate.checkGoogleCode();
    }
}
