import { fromLonLat } from 'ol/proj';
import { Feature, Overlay } from 'ol';

import Map from 'ol/Map';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import BaseLayer from 'ol/layer/Base';

import { Coordinates } from '../../services/location/abstractLocation';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    NgZone,
    ViewChild,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { LocationService } from '../../services/location/location.service';
import { MapMarker } from '../../services/location/MapMarker.model';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'sOpenStreetMap',
    templateUrl: './openstreetmap.component.html',
    styleUrls: ['./openstreetmap.component.less'],
})
export class OpenStreetMapComponent implements AfterViewInit, OnDestroy {
    @ViewChild('map', { static: true }) private _map: ElementRef;
    @Input('mapHeight') public mapHeight: number;

    @Input('lon') public centerLongitude: number = -118.243683;
    @Input('lat') public centerLatitude: number = 34.052235;
    @Input('zoom') zoomLevel: number = 7;

    @Input('markers') public markers: Array<MapMarker> = [];
    @Input('pointerIcon') public pointerIcon: string = 'assets/custompointer.png';
    @Input('pointerAnchor') public pointerAnchor: Array<number> = [0.5, 1];
    @Input('pointerScale') public pointerScale: number = 0.5;

    @Input('centerOnCurrentPos') public centerOnCurrentPos: boolean = false;

    private overlay: Overlay;
    private mapView: View;
    private map: Map;
    private iconLayer: BaseLayer;

    public constructor(
        private zone: NgZone,
        private toaster: ToasterService,
        private changeRef: ChangeDetectorRef,
        public locationService: LocationService
    ) {}

    public ngAfterViewInit(): void {
        const mapLayer = new TileLayer({
            source: new XYZ({
                url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            }),
        });
        if (this.centerOnCurrentPos) {
            this.setCurrentPosition();
        }

        this.mapView = new View({
            center: fromLonLat([this.centerLongitude, this.centerLatitude]),
            zoom: this.zoomLevel,
        });
        this.zone.runOutsideAngular(() => {
            this.iconLayer = this.getIconLayer(this.markers);
            this.map = new Map({
                target: this._map.nativeElement,
                layers: [mapLayer, this.iconLayer],
                view: this.mapView,
            });
        });

        this.createPopup();

        this.map.on('singleclick', (evt) => {
            let markerName = this.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature.get('name');
            });

            if (markerName) {
                let marker = this.markers.find((marker) => marker.title == markerName);
                if (marker) {
                    this.locationService.activeMarker = JSON.parse(JSON.stringify(marker));
                    let _popupElement = document.getElementById('popup-container');
                    _popupElement.style.opacity = '0';
                    // force angular to update the view and notify all changes otherwise view is not updated
                    this.changeRef.detectChanges();
                    this.overlay.setPosition(fromLonLat([marker.longitude, marker.latitude]));
                    setTimeout(() => {
                        if (_popupElement) {
                            this.overlay.setOffset([
                                (_popupElement.getBoundingClientRect().width / 2) * -1,
                                (_popupElement.getBoundingClientRect().height + 10) * -1,
                            ]);
                            _popupElement.style.opacity = '1';
                        }
                    }, 100);
                }
            } else {
                this.closePopup();
            }
        });

        // listen to pointermove to add pointer over marker.
        this.map.on('pointermove', (evt) => {
            document.getElementById('map-canvas').style.cursor = this.map.forEachFeatureAtPixel(
                evt.pixel,
                function (feature) {
                    return feature.get('name');
                }
            )
                ? 'pointer'
                : 'default';
        });
    }

    /**
     *
     * Set map center to current position
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public setCurrentPosition() {
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                this.centerLatitude = pos.coords.longitude;
                this.centerLongitude = pos.coords.latitude;
                let marker: MapMarker = {
                    longitude: pos.coords.longitude,
                    latitude: pos.coords.latitude,
                    title: 'current-pos',
                };

                this.addMarkers([marker]);
                this.setPosition({ lat: pos.coords.latitude, lon: pos.coords.longitude });
            },
            () => {
                this.toaster.error('Geen toegang tot locatie');
            }
        );
    }

    /**
     *
     * Add new marker to map
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public addMarkers(markers: MapMarker[]): void {
        this.markers.push(...markers);
        let tmpLayer = this.getIconLayer(this.markers);
        this.map.addLayer(tmpLayer);
        this.map.removeLayer(this.iconLayer);
        this.iconLayer = tmpLayer;
    }

    /**
     *
     * Remove marker from map based on longitude and latitude
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public removeMarker(marker: MapMarker): void {
        let index = this.markers.findIndex(
            (mark) => mark.latitude == marker.latitude && mark.longitude == marker.longitude
        );

        if (index >= 0) {
            this.markers.splice(index, 1);
            let tmpLayer = this.getIconLayer(this.markers);
            this.map.addLayer(tmpLayer);
            this.map.removeLayer(this.iconLayer);
            this.iconLayer = tmpLayer;
        }
    }

    /**
     *
     * Set center op map
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public setPosition(coordinates: Coordinates): void {
        this.mapView.setCenter(fromLonLat([coordinates.lon, coordinates.lat]));
    }

    /**
     *
     * Return new layer with map markers which can be layed upon the base map layer
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    BaseLayer
     */
    private getIconLayer(markers: Array<MapMarker>): BaseLayer {
        const icons: Array<Feature> = [];
        for (const marker of markers) {
            const icon: Feature = new Feature({
                geometry: new Point(fromLonLat([marker.longitude, marker.latitude])),
                name: marker.title ? marker.title : '',
            });
            icons.push(icon);
        }
        const vectorSource: VectorSource = new VectorSource({
            features: icons,
        });

        const iconStyle: Style = new Style({
            image: new Icon({
                anchor: this.pointerAnchor,
                scale: this.pointerScale,
                src: this.pointerIcon,
            }),
        });

        return new VectorLayer({
            source: vectorSource,
            style: iconStyle,
        });
    }

    public createPopup() {
        let _window = document.getElementById('info-window');
        this.overlay = new Overlay({
            element: _window,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });
        this.map.addOverlay(this.overlay);
    }

    public closePopup() {
        this.overlay.setPosition(undefined);
        this.locationService.activeMarker = null;
    }

    ngOnDestroy(): void {
        this.locationService.activeMarker = null;
    }
}
