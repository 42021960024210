import { Component, Input } from '@angular/core';

@Component({
    selector: 'order-total-template',
    templateUrl: './order-total-template.component.html',
    styleUrls: ['./order-total-template.component.less'],
})
export class OrderTotalTemplateComponent {
    @Input() shippingCost;
	@Input() discountTotal;
    @Input() cartTotal;
	@Input() cartTotalWithoutDiscount;
    @Input() showCartTotal;

    constructor() {}
}
