import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProductService } from '../../services/product.service';

@Component({
    selector: 'dashboard-box',
    templateUrl: './dashboard-box.component.html',
    styleUrls: ['./dashboard-box.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DashboardBoxComponent),
            multi: true,
        },
    ],
})
export class DashboardBoxComponent {
    @Input('title') public title: string;

    @Input('description') public description: string;

    @Input('image') public image: string;

    @Input('link') public link: string;

    @Input('groupId') public groupId: number;

    public constructor(public product: ProductService) {}
}
