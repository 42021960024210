import { shareData, ShareService } from './../../services/share.service';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'sShare',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.less'],
})
export class ShareComponent {
    @Input() data: shareData;
    constructor(public shareService: ShareService) {}
}
