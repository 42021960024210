import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Product, ProductService, SubArticle } from '../../services/product.service';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductComponent),
            multi: true,
        },
    ],
})
export class ProductComponent {
    @Input('product') public product: Product;
    @Input() public showTotal: boolean = false;
    @Input() public type: string = 'overview';

    public constructor(public productService: ProductService, public util: UtilService) {}

    public getSubArticle() {
        if (this.product.subArticles) {
            let subArticle = this.product.subArticles.find(
                (subArticle) => subArticle.id == this.product.selectedType
            );
            return subArticle ? subArticle : new SubArticle();
        }
        return new SubArticle();
    }

	public getOriginalPrice() {
        return (this.getSubArticle().price || this.product.price || 0);
    }

	public getDiscount() {
        return (this.getSubArticle().discount || this.product.discount || 0);
    }

    public getPrice() {
        return (
            (this.getSubArticle().price || this.product.price || 0) *
            (1 - (this.getSubArticle().discount || this.product.discount || 0) / 100)
        );
    }

	public getOriginalFrom(product: Product) {
        if (!product || !product.subArticles) return false;
        let price = 0;
        for (let subArticle of product.subArticles) {
            const subArticlePrice = (subArticle.price || product.price);
            if (subArticle.price && subArticlePrice != product.price) {
                if (price === 0) {
                    price = product.price;
                }
                if (subArticlePrice < price) {
                    price = subArticlePrice;
                }
            }
        }
        return price;
    }

	public getDiscountFrom(product: Product) {
        if (!product || !product.subArticles) return false;
        let discount = 0;
        for (let subArticle of product.subArticles) {
			if (subArticle.discount && subArticle.discount != product.discount) {
                if (subArticle.discount > discount) {
                    discount = subArticle.discount;
                }
            }
        }
        return discount;
    }

    public getFrom(product: Product) {
        if (!product || !product.subArticles) return false;
        let price = 0;
        const productPrice = product.price * (1 - product.discount / 100);
        for (let subArticle of product.subArticles) {
            const subArticlePrice = (subArticle.price || product.price) * (1 - subArticle.discount / 100);
            if ((subArticle.price || subArticle.discount) && subArticlePrice != productPrice) {
                if (price === 0) {
                    price = productPrice;
                }
                if (subArticlePrice < price) {
                    price = subArticlePrice;
                }
            }
        }
        return price;
    }
}
