import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { DropdownAction } from '../../../../models/action.model';

@Component({
    selector: 'sDropdownAction',
    templateUrl: './dropdown-action.component.html',
    styleUrls: ['./dropdown-action.component.less'],
})
export class DropdownActionComponent {
    @Input() action: DropdownAction;

    constructor(private eRef: ElementRef) {}

    @HostListener('document:click', ['$event'])
    clickOut(event) {
        if (this.action.cancelNextClick) {
            this.action.cancelNextClick = false;
            return;
        }
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.action.open = false;
        }
    }
}
