import { ProductService } from '../../../core/services/product.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'my-orders-detail',
    templateUrl: './my-orders-detail.component.html',
    styleUrls: ['./my-orders-detail.component.less'],
})
export class MyOrdersDetailComponent implements OnInit {
    public orderDetail;

    constructor(
        public productService: ProductService,
        public route: ActivatedRoute,
        private router: Router
    ) {}

    async ngOnInit() {
        this.orderDetail = await this.productService.getOrderDetail(this.route.snapshot.params.orderId);
        if (!this.orderDetail) {
            this.router.navigateByUrl('/mijn-bestellingen');
        }
        this.productService.products = this.orderDetail.products;
    }
}
