import { Directive, HostListener } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UtilService } from 'src/app/core/services/util.service';

@Directive({
    selector: '[textOnlyValidation]',
})
export class TextOnlyValidationDirective {
    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $target.value;
        if (!this.textOnlyValidation(value)) {
            this.util.addClass($target, 'error');
            this.validation.errors.textOnlyValidation = true;
        } else if (this.textOnlyValidation(value)) {
            this.util.removeClass($target, 'error');
            this.validation.errors.textOnlyValidation = false;
        }
    }

    public constructor(private validation: ValidationService, private util: UtilService) {}

    /**
     * textOnlyValidation
     *
     * checks if the input only contains characters
     *
     * @author    Bart van Maarschalkerweerd <bart@safira.nl>
     */
    private textOnlyValidation(value: any): boolean {
        return typeof value === 'string' && value.match(/^[a-zA-Z_]+( [a-zA-Z_]+)*$/) && value != '';
    }
}
