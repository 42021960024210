import { UtilService } from 'src/app/core/services/util.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { AjaxService } from 'src/app/core/services/ajax.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { MapMarker } from './MapMarker.model';
import { HttpClient } from '@angular/common/http';
import { googleMapsStrategy } from './googleMaps.strategy';
import { Injectable } from '@angular/core';
import { openStreetMapsStrategy } from './openStreetMaps.strategy';
import { abstractLocation, Coordinates } from './abstractLocation';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    private locationStrategy: abstractLocation;
    private strategyMap: Map<string, abstractLocation>;
    private activeStrategy: string = '';
	public activeMarker: MapMarker = null;

    constructor(
        public http: HttpClient,
        util: UtilService,
        configService: ConfigService,
        private ajax: AjaxService,
        private toaster: ToasterService
    ) {
        this.strategyMap = new Map<string, abstractLocation>();

        // create available strategies
        this.strategyMap.set('osm', new openStreetMapsStrategy(http, util));
        this.strategyMap.set('google', new googleMapsStrategy(http, util, configService));
        // set default strategy
        this.setStrategy('osm');
    }

    /**
     * return the active abstractlocation strategy.
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     * @return    Promise<array<any>>
     */
    public getStrategy(): abstractLocation {
        return this.locationStrategy;
    }

    /**
     * set strategy by string indentifier
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     * @return    boolean
     */
    public setStrategy(mapType: string): boolean {
        if (this.strategyMap.has(mapType) && this.activeStrategy !== mapType) {
            this.locationStrategy = this.strategyMap.get(mapType);
            this.activeStrategy = mapType;
            return true;
        }
        return false;
    }

    /**
     * Switch between location strategies
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     * @return    void
     */
    public switchStrategy() {
        if (this.activeStrategy == 'google') {
            this.setStrategy('osm');
        } else if (this.activeStrategy == 'osm') {
            this.setStrategy('google');
        }
    }

    /**
     * geocode user address
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     * @return    void
     */
    public async geocode(
        zipCode: string,
        houseNumber: string | number,
        houseNumberSuffix: string,
        city: string,
        street: string,
        callback: (coordinates: Coordinates) => any
    ) {
        let coordPromise = await this.locationStrategy.geocode({
            address: street,
            houseNumber: houseNumber,
            houseNumberSuffix: houseNumberSuffix,
            zipcode: zipCode,
            city: city,
        });
        callback(coordPromise);
    }

    /**
     * get users within range
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     * @return    Promise<array<any>>
     */
    public getUsersWithinRange(range: number, longitude: number, latitude: number): Promise<Array<any>> {
        return this.ajax.post('/geolocation/getUsersWithinRange', {
            latitude: latitude,
            longitude: longitude,
            range: range.toString(),
        });
    }

    /**
     * return the user's current location if authorised by browser
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     * @return    Promise<array<any>>
     */
    public getCurrentLocation(callback: PositionCallback) {
        navigator.geolocation.getCurrentPosition(callback, (err) => {
            this.toaster.error('Locatie kon niet worden opgehaald.');
        });
    }
}
