import { Component, OnDestroy, OnInit } from '@angular/core';
import { color, RowItem } from '../../../../../core/models/item.model';
import { TableService } from '../../../../../core/services/table.service';
import { TableColumn } from '../../../../../core/models/table.model';
import { Breakpoint } from '../../../../../core/services/responsive.service';

@Component({
    selector: 'demo-table-list',
    templateUrl: './demo-table-list.component.html',
    styleUrls: ['./demo-table-list.component.less'],
})
export class DemoTableListComponent implements OnInit, OnDestroy {
    private demoRowItems: { name: string; email: string; date: string; iban: string; color: color }[] = [
        {
            name: 'Leandra Santiago',
            email: 'auctor.ullamcorper@egestasaliquam.co.uk',
            date: 'Apr 7, 2022',
            iban: 'EE917123529132481441',
            color: 'success',
        },
        {
            name: 'Oscar Whitehead',
            email: 'quam.a@commodoat.org',
            date: 'Jun 4, 2021',
            iban: 'ES8245654601668836224329',
            color: 'error',
        },
        {
            name: 'Basil Holden',
            email: 'odio.semper.cursus@posuereenimnisl.co.uk',
            date: 'Jul 22, 2022',
            iban: 'KW5815668301433533485322981346',
            color: 'alert',
        },
        {
            name: 'Nissim Parsons',
            email: 'suspendisse@cursusinteger.com',
            date: 'Oct 17, 2021',
            iban: 'GR2104553646373857724887760',
            color: 'success',
        },
        {
            name: 'Anjolie Dodson',
            email: 'neque.venenatis@sitametdiam.co.uk',
            date: 'Jun 6, 2022',
            iban: 'ME13232797246579738014',
            color: 'error',
        },
        {
            name: 'Tasha Schultz',
            email: 'malesuada@eulacus.org',
            date: 'May 19, 2021',
            iban: 'BA287138494222144406',
            color: 'success',
        },
        {
            name: 'Fleur Hubbard',
            email: 'et.rutrum.non@duiscursusdiam.com',
            date: 'Dec 2, 2020',
            iban: 'HR6243319636533415799',
            color: 'error',
        },
        {
            name: 'Dane Pratt',
            email: 'egestas.blandit.nam@sodalesat.edu',
            date: 'Dec 28, 2020',
            iban: 'TR314510944425432783401730',
            color: 'alert',
        },
        {
            name: 'Anika Poole',
            email: 'quam.a@egestasa.co.uk',
            date: 'Dec 15, 2021',
            iban: 'SE2964582497321614125995',
            color: 'success',
        },
        {
            name: 'Amber Alford',
            email: 'libero.mauris@posuerecubilia.ca',
            date: 'Mar 24, 2021',
            iban: 'PT25014593415636459354835',
            color: 'error',
        },
        {
            name: 'Linus England',
            email: 'amet.risus@maecenasmifelis.ca',
            date: 'Feb 6, 2022',
            iban: 'ME18242886228077101461',
            color: 'success',
        },
        {
            name: 'Celeste Sampson',
            email: 'molestie.arcu@hendreritnequein.ca',
            date: 'Jan 17, 2022',
            iban: 'HR1143923487559605057',
            color: 'error',
        },
        {
            name: 'Gannon Ballard',
            email: 'malesuada.id@magnanamligula.net',
            date: 'May 1, 2021',
            iban: 'MD0266265993661916942144',
            color: 'alert',
        },
        {
            name: 'Britanney Herring',
            email: 'quisque@enimetiam.com',
            date: 'Feb 12, 2021',
            iban: 'BA305495711662536286',
            color: 'success',
        },
        {
            name: 'Eliana Hart',
            email: 'arcu.sed@urnavivamus.co.uk',
            date: 'Feb 22, 2022',
            iban: 'SM2252287655034684433115988',
            color: 'error',
        },
        {
            name: 'Jermaine Mason',
            email: 'proin.vel.arcu@antedictumcursus.ca',
            date: 'Jul 13, 2021',
            iban: 'GR9041736636852629602176114',
            color: 'success',
        },
        {
            name: 'Daquan Edwards',
            email: 'eu.erat.semper@phasellus.org',
            date: 'Jan 29, 2021',
            iban: 'PK0924686212549257936352',
            color: 'error',
        },
        {
            name: 'James Mason',
            email: 'ac.eleifend.vitae@utquamvel.org',
            date: 'May 27, 2021',
            iban: 'BH49502383323812446754',
            color: 'alert',
        },
        {
            name: 'Cecilia Graham',
            email: 'arcu.et@eteuismod.com',
            date: 'Jun 3, 2021',
            iban: 'SA3510595426208947621873',
            color: 'success',
        },
        {
            name: 'Ferris Wiggins',
            email: 'aliquam.iaculis.lacus@liberomauris.net',
            date: 'Jul 6, 2022',
            iban: 'MC5954521042413865352752705',
            color: 'error',
        },
        {
            name: 'Leandra Santiago',
            email: 'auctor.ullamcorper@egestasaliquam.co.uk',
            date: 'Apr 7, 2022',
            iban: 'EE917123529132481441',
            color: 'success',
        },
        {
            name: 'Oscar Whitehead',
            email: 'quam.a@commodoat.org',
            date: 'Jun 4, 2021',
            iban: 'ES8245654601668836224329',
            color: 'error',
        },
        {
            name: 'Basil Holden',
            email: 'odio.semper.cursus@posuereenimnisl.co.uk',
            date: 'Jul 22, 2022',
            iban: 'KW5815668301433533485322981346',
            color: 'alert',
        },
        {
            name: 'Nissim Parsons',
            email: 'suspendisse@cursusinteger.com',
            date: 'Oct 17, 2021',
            iban: 'GR2104553646373857724887760',
            color: 'success',
        },
        {
            name: 'Anjolie Dodson',
            email: 'neque.venenatis@sitametdiam.co.uk',
            date: 'Jun 6, 2022',
            iban: 'ME13232797246579738014',
            color: 'error',
        },
        {
            name: 'Tasha Schultz',
            email: 'malesuada@eulacus.org',
            date: 'May 19, 2021',
            iban: 'BA287138494222144406',
            color: 'success',
        },
        {
            name: 'Fleur Hubbard',
            email: 'et.rutrum.non@duiscursusdiam.com',
            date: 'Dec 2, 2020',
            iban: 'HR6243319636533415799',
            color: 'error',
        },
        {
            name: 'Dane Pratt',
            email: 'egestas.blandit.nam@sodalesat.edu',
            date: 'Dec 28, 2020',
            iban: 'TR314510944425432783401730',
            color: 'alert',
        },
        {
            name: 'Anika Poole',
            email: 'quam.a@egestasa.co.uk',
            date: 'Dec 15, 2021',
            iban: 'SE2964582497321614125995',
            color: 'success',
        },
        {
            name: 'Amber Alford',
            email: 'libero.mauris@posuerecubilia.ca',
            date: 'Mar 24, 2021',
            iban: 'PT25014593415636459354835',
            color: 'error',
        },
        {
            name: 'Linus England',
            email: 'amet.risus@maecenasmifelis.ca',
            date: 'Feb 6, 2022',
            iban: 'ME18242886228077101461',
            color: 'success',
        },
        {
            name: 'Celeste Sampson',
            email: 'molestie.arcu@hendreritnequein.ca',
            date: 'Jan 17, 2022',
            iban: 'HR1143923487559605057',
            color: 'error',
        },
        {
            name: 'Gannon Ballard',
            email: 'malesuada.id@magnanamligula.net',
            date: 'May 1, 2021',
            iban: 'MD0266265993661916942144',
            color: 'alert',
        },
        {
            name: 'Britanney Herring',
            email: 'quisque@enimetiam.com',
            date: 'Feb 12, 2021',
            iban: 'BA305495711662536286',
            color: 'success',
        },
        {
            name: 'Eliana Hart',
            email: 'arcu.sed@urnavivamus.co.uk',
            date: 'Feb 22, 2022',
            iban: 'SM2252287655034684433115988',
            color: 'error',
        },
        {
            name: 'Jermaine Mason',
            email: 'proin.vel.arcu@antedictumcursus.ca',
            date: 'Jul 13, 2021',
            iban: 'GR9041736636852629602176114',
            color: 'success',
        },
        {
            name: 'Daquan Edwards',
            email: 'eu.erat.semper@phasellus.org',
            date: 'Jan 29, 2021',
            iban: 'PK0924686212549257936352',
            color: 'error',
        },
        {
            name: 'James Mason',
            email: 'ac.eleifend.vitae@utquamvel.org',
            date: 'May 27, 2021',
            iban: 'BH49502383323812446754',
            color: 'alert',
        },
        {
            name: 'Cecilia Graham',
            email: 'arcu.et@eteuismod.com',
            date: 'Jun 3, 2021',
            iban: 'SA3510595426208947621873',
            color: 'success',
        },
        {
            name: 'Ferris Wiggins',
            email: 'aliquam.iaculis.lacus@liberomauris.net',
            date: 'Jul 6, 2022',
            iban: 'MC5954521042413865352752705',
            color: 'error',
        },
        {
            name: 'Leandra Santiago',
            email: 'auctor.ullamcorper@egestasaliquam.co.uk',
            date: 'Apr 7, 2022',
            iban: 'EE917123529132481441',
            color: 'success',
        },
        {
            name: 'Oscar Whitehead',
            email: 'quam.a@commodoat.org',
            date: 'Jun 4, 2021',
            iban: 'ES8245654601668836224329',
            color: 'error',
        },
        {
            name: 'Basil Holden',
            email: 'odio.semper.cursus@posuereenimnisl.co.uk',
            date: 'Jul 22, 2022',
            iban: 'KW5815668301433533485322981346',
            color: 'alert',
        },
        {
            name: 'Nissim Parsons',
            email: 'suspendisse@cursusinteger.com',
            date: 'Oct 17, 2021',
            iban: 'GR2104553646373857724887760',
            color: 'success',
        },
        {
            name: 'Anjolie Dodson',
            email: 'neque.venenatis@sitametdiam.co.uk',
            date: 'Jun 6, 2022',
            iban: 'ME13232797246579738014',
            color: 'error',
        },
        {
            name: 'Tasha Schultz',
            email: 'malesuada@eulacus.org',
            date: 'May 19, 2021',
            iban: 'BA287138494222144406',
            color: 'success',
        },
        {
            name: 'Fleur Hubbard',
            email: 'et.rutrum.non@duiscursusdiam.com',
            date: 'Dec 2, 2020',
            iban: 'HR6243319636533415799',
            color: 'error',
        },
        {
            name: 'Dane Pratt',
            email: 'egestas.blandit.nam@sodalesat.edu',
            date: 'Dec 28, 2020',
            iban: 'TR314510944425432783401730',
            color: 'alert',
        },
        {
            name: 'Anika Poole',
            email: 'quam.a@egestasa.co.uk',
            date: 'Dec 15, 2021',
            iban: 'SE2964582497321614125995',
            color: 'success',
        },
        {
            name: 'Amber Alford',
            email: 'libero.mauris@posuerecubilia.ca',
            date: 'Mar 24, 2021',
            iban: 'PT25014593415636459354835',
            color: 'error',
        },
        {
            name: 'Linus England',
            email: 'amet.risus@maecenasmifelis.ca',
            date: 'Feb 6, 2022',
            iban: 'ME18242886228077101461',
            color: 'success',
        },
        {
            name: 'Celeste Sampson',
            email: 'molestie.arcu@hendreritnequein.ca',
            date: 'Jan 17, 2022',
            iban: 'HR1143923487559605057',
            color: 'error',
        },
        {
            name: 'Gannon Ballard',
            email: 'malesuada.id@magnanamligula.net',
            date: 'May 1, 2021',
            iban: 'MD0266265993661916942144',
            color: 'alert',
        },
        {
            name: 'Britanney Herring',
            email: 'quisque@enimetiam.com',
            date: 'Feb 12, 2021',
            iban: 'BA305495711662536286',
            color: 'success',
        },
        {
            name: 'Eliana Hart',
            email: 'arcu.sed@urnavivamus.co.uk',
            date: 'Feb 22, 2022',
            iban: 'SM2252287655034684433115988',
            color: 'error',
        },
        {
            name: 'Jermaine Mason',
            email: 'proin.vel.arcu@antedictumcursus.ca',
            date: 'Jul 13, 2021',
            iban: 'GR9041736636852629602176114',
            color: 'success',
        },
        {
            name: 'Daquan Edwards',
            email: 'eu.erat.semper@phasellus.org',
            date: 'Jan 29, 2021',
            iban: 'PK0924686212549257936352',
            color: 'error',
        },
        {
            name: 'James Mason',
            email: 'ac.eleifend.vitae@utquamvel.org',
            date: 'May 27, 2021',
            iban: 'BH49502383323812446754',
            color: 'alert',
        },
        {
            name: 'Cecilia Graham',
            email: 'arcu.et@eteuismod.com',
            date: 'Jun 3, 2021',
            iban: 'SA3510595426208947621873',
            color: 'success',
        },
        {
            name: 'Ferris Wiggins',
            email: 'aliquam.iaculis.lacus@liberomauris.net',
            date: 'Jul 6, 2022',
            iban: 'MC5954521042413865352752705',
            color: 'error',
        },
    ];

    constructor(private tableService: TableService) {}

    ngOnInit() {
        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [Breakpoint.MD], row: [Breakpoint.MD] },
            },
            this.mapToColumns.bind(this),
            this.mapUsersToTable.bind(this),
            this.demoRowItems
        );
    }

    ngOnDestroy(): void {
        this.tableService.destroyTable();
    }

    private mapToColumns(): TableColumn[] {
        return [
            {
                preItem: this.tableService.getSelectAll(),
                item: { type: 'text', text: 'itemBasic.name' },
                sortOn: 'name',
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 45,
                },
            },
            {
                item: { type: 'text', text: 'itemBasic.email' },
                sortOn: 'email',
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 45,
                },
            },
            {
                item: { type: 'text', text: 'itemBasic.date' },
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
            {
                item: { type: 'text', text: 'itemBasic.bank' },
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
            {
                item: { type: 'text', text: 'itemBasic.actions' },
                position: 'left',
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
        ];
    }

    /**
     * Map users to table format
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapUsersToTable(row: {
        name: string;
        email: string;
        date: string;
        iban: string;
        color: color;
    }): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                gap: 6,
                colspan: 2,
                openHeight: 121.2,
                closedHeight: 16.8,
            },
            items: [
                {
                    type: 'group',
                    items: [
                        this.tableService.getSelect(row),
                        { type: 'text', text: row.name, fontWeight: 900 },
                    ],
                },
                {
                    type: 'text',
                    text: row.email,
                    hover: true,
                    action: {
                        type: 'anchor',
                        route: 'mailto:' + row.email,
                        target: 'top',
                    },
                },
                { type: 'text', text: row.date, expansion: 'expandable' },
                {
                    type: 'group',
                    expansion: 'expandable',
                    items: [
                        {
                            type: 'bool',
                            icon: 'university',
                            bool: Math.random() < 0.5,
                            color: 'success',
                            falseColor: 'alert',
                        },
                        { type: 'text', text: row.iban },
                        {
                            type: 'text',
                            icon: 'caret-circle-down',
                            action: {
                                type: 'popup',
                                title: { type: 'text', text: 'itemBasic.example_popup' },
                                items: [{ type: 'text', text: 'itemBasic.example_popup_text' }],
                            },
                        },
                    ],
                },
                {
                    type: 'text',
                    icon: 'trash-alt',
                    expansion: 'expandable',
                    iconBorder: true,
                    hover: true,
                    action: {
                        type: 'popup',
                        title: {
                            type: 'text',
                            text: 'itemBasic.example_delete',
                        },
                        form: {
                            routeTo: './',
                            inputs: [
                                {
                                    type: 'form',
                                    inputType: 'checkbox',
                                    text: 'itemBasic.example_confirm_delete',
                                    name: 'confirm',
                                    data: null,
                                    required: true,
                                },
                            ],
                            data: this.tableService.findObject(row),
                            submit: (data, formData: Map<string, any>) => {
                                if (formData.get('confirm')) {
                                    this.demoRowItems.splice(data, 1);
                                    this.tableService.setObjects(this.demoRowItems);
                                    return true;
                                }
                                return false;
                            },
                        },
                    },
                },
            ],
        };
    }
}
