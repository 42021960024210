import { SignatureService } from './../../services/signature.service';
import { Component, OnInit, ViewChild, ElementRef, Input, ChangeDetectorRef, NgZone } from '@angular/core';
import { Options as SpOptions, default as SignaturePad, PointGroup } from 'signature_pad';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type Extension = 'jpg' | 'png' | 'svg';

const mimeTypes = {
    jpg: 'image/jpeg',
    png: 'image/png',
    svg: 'image/svg+xml',
};

@Component({
    selector: 'sSignature',
    templateUrl: '././signature.component.html',
    styleUrls: ['./signature.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SignatureComponent,
            multi: true,
        },
    ],
})
export class SignatureComponent implements OnInit, ControlValueAccessor {
    @ViewChild('canvas', { static: true }) private canvas: ElementRef;

    @Input('options') private options: SpOptions;

    @Input('format') private format: Extension = 'png';

    private pad: SignaturePad;

    private _onChange: (value: any) => void = (value: any) => {};
    private _onTouched: () => void = () => {};

    public constructor(
        private signatureService: SignatureService,
        private cd: ChangeDetectorRef,
        private zone: NgZone
    ) {}

    /**
     * Initializes the signature pad
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public ngOnInit(): void {
        setTimeout(() => this.resize(), 200);
        this.zone.runOutsideAngular(() => {
            this.pad = new SignaturePad(this.canvas.nativeElement, {
                ...this.options,
                onEnd: () => {
                    this._onChange(this.pad.toDataURL(mimeTypes[this.format]));
                    this._onTouched();
                },
            });
        });
    }

    /**
     * Adapts the signature pad to pixel ratio and clears it.
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public resize() {
        const canvas: HTMLCanvasElement = this.canvas.nativeElement;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
        canvas.style.width = '100%';
        canvas.style.height = '395px';

        const width: number = document.getElementById('canvas').offsetWidth;
        const scale: number = window.devicePixelRatio;
        const scaledWidth: number = Math.floor(width * scale);
        const scaledHeight: number = Math.floor(395 * scale);
        const data: ImageData = ctx.getImageData(0, 0, scaledWidth, scaledHeight);

        canvas.width = scaledWidth;
        canvas.height = scaledHeight;
        ctx.scale(scale, scale);

        ctx.putImageData(data, 0, 0);
    }

    /**
     * Clears the signature pad.
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public clear(): void {
        if (!this.pad.isEmpty()) {
            this.pad.clear();
            this._onChange('');
        }

        this._onTouched();
    }

    /**
     * Reverts a drawn line.
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public undo(): void {
        const lines: Array<PointGroup> = this.pad.toData();

        if (lines != null && lines.length > 0) {
            lines.pop();
            this.pad.fromData(lines);

            if (this.pad.isEmpty()) {
                this._onChange('');
            } else {
                this._onChange(this.pad.toDataURL(mimeTypes[this.format]));
            }
        }

        this._onTouched();
    }
    public sendSignature(): void {
        this.signatureService.sendSignature();
    }

    public writeValue(value: any): void {
        return;
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }
}
