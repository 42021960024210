import { Component } from '@angular/core';
import { AjaxService } from '../../services/ajax.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'sResetOpcache',
    templateUrl: './reset-opcache.component.html',
    styleUrls: ['./reset-opcache.component.less'],
})
export class ResetOpcacheComponent {
    public constructor(private ajax: AjaxService, private toaster: ToasterService) {}

    public resetOpcache() {
        if (!confirm('Wil je de PHP opcache resetten?')) return;

        this.ajax.post('/admin/resetOpcache', {}).then(() => this.toaster.success('De opcache is gereset.'));
    }
}
