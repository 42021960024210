import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { ModuleWithProviders } from '@angular/core';
import { ProductsComponent } from './products/products.component';

export const productsRoutes: Routes = [
    {
        path: 'producten/:productType',
        component: ProductsComponent,
        canActivate: [AuthGuardService],
    },
];

export const productsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(productsRoutes);
