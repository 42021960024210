import { Injectable } from '@angular/core';
import { AjaxService } from '../ajax.service';
import { Router } from '@angular/router';
import { ToasterService } from '../toaster.service';
import { CrudServiceModel } from '../../models/crud/crud-service.model';
import { UserModel } from '../../models/user/user.model';
import { UserAddressModel } from '../../models/user/user-address.model';
import { UserGroupModel } from '../../models/user/user-group.model';
import { UserGroupService } from './user-group.service';
import { CrudException } from '../../models/crud/crud-exception.model';
import { HttpStatusCode } from '../../models/http-status-code';

@Injectable({
    providedIn: 'root',
})
export class UserService extends CrudServiceModel<UserModel> {
    public defaultErrorMessage: string = 'Niet alle velden zijn ingevuld.';

    protected readonly create = UserModel;
    protected readonly module: string = 'user';

    constructor(
        protected ajax: AjaxService,
        protected toaster: ToasterService,
        private router: Router,
        private userGroupService: UserGroupService
    ) {
        super();
    }

    public validator(object: UserModel) {
        if (object.dayOfBirth === '') {
            object.dayOfBirth = null;
        }

        if (object.addresses) {
            const addressUrl = this.router.url.slice(0, this.router.url.lastIndexOf('/')) + '/address';
            for (const address of object.addresses) {
                if (address.deleted) {
                    continue;
                }
                if (address.address === null) {
                    this.router.navigateByUrl(addressUrl);
                    throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_address_required');
                }
                if (address.houseNumber <= 0) {
                    this.router.navigateByUrl(addressUrl);
                    throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_house_number_length');
                }
                if (address.countryId === undefined || address.countryId.length !== 2) {
                    this.router.navigateByUrl(addressUrl);
                    throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_country_required');
                }
                if (address.zipcode === null || address.zipcode.length < 6) {
                    this.router.navigateByUrl(addressUrl);
                    throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_zipcode_length');
                }
                if (address.city === null) {
                    this.router.navigateByUrl(addressUrl);
                    throw new CrudException(HttpStatusCode.BAD_REQUEST, 'itemUser.error_city_required');
                }
            }
        }
        return true;
    }

    protected validation(object: UserModel): boolean {
        return this.validator(object);
    }

    async get(userId: number, loadingTarget?: string): Promise<UserModel> {
        return super.get({ userId: userId }, loadingTarget);
    }

    async includeAddresses(): Promise<UserModel> {
        const addresses = await this.getEndpoint('getAddresses', { userId: this.object.userId });
        return (this.object.addresses = addresses.map((address) => {
            return new UserAddressModel(address);
        }));
    }

    async includeUserGroups(): Promise<UserModel> {
        const userGroups = await this.getEndpoint('getUserGroups', { userId: this.object.userId });
        return (this.object.userGroups = userGroups.map((userGroup) => {
            return new UserGroupModel(this.userGroupService.save, userGroup);
        }));
    }

    async getAllSubAccounts() {
        const users = await this.getEndpoint('getAllSubAccounts', { userId: this.object.userId });
        return users.map((user) => {
            return new this.create(this.save.bind(this), user);
        });
    }
}
