import { ProductService } from '../../../core/services/product.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/nl';
import { ToasterService } from 'src/app/core/services/toaster.service';
dayjs.extend(weekOfYear);

@Component({
    selector: 'shipping',
    templateUrl: './shipping.component.html',
    styleUrls: ['./shipping.component.less'],
})
export class ShippingComponent implements OnInit {
    public searchValue: string;
    public shippingDetails;
    public shippingWeek;
    public shippingDays = [];
    public selectedShippingDay: string;
    public selectedShippingOption = {};
    public selectedTab = 'Bezorgweek';
    public extras = { projectName: '', comments: '' };
    public getdayjs = dayjs;
    public editAddress = false;
    public addressValid = false;

    constructor(
        public productService: ProductService,
        private router: Router,
        private toaster: ToasterService
    ) {}

    async ngOnInit() {
        if (this.productService.getCartTotal() == 0) {
            this.router.navigateByUrl('/bestelling/overzicht');
        }
        this.getDays();
        await this.getShippingDetails();
        if (this.productService.totalDetails.cost === 0) {
            this.productService.getOrderTotalDetails();
        }
    }

    public async getShippingDetails() {
        this.addressValid = false;
        let address = { zipcode: undefined };
        if (this.shippingDetails) {
            address = this.shippingDetails.address;
        }
        let shippingDetails = await this.productService.getShippingDetails(address.zipcode);
        if (!shippingDetails.dates.errors) {
            this.shippingDetails = shippingDetails;
        } else {
            this.shippingDetails.address.zipcode = shippingDetails.address.zipcode;
            this.shippingDetails.dates = (await this.productService.getShippingDetails(undefined)).dates;
            this.toaster.error('Ongeldige postcode ingevuld, postcode is hersteld');
        }
        if (address.zipcode) {
            this.shippingDetails.address = address;
        }
        this.addressValid = true;
        this.selectFirstShippingDay();
        this.shippingDetails.weeks = this.sortByWeek(this.shippingDetails.weeks);
        this.shippingWeek = +this.shippingDetails.weeks[0];
    }

    public back() {
        this.router.navigateByUrl('bestelling/overzicht');
    }

    public getDayByNumber(number) {
        for (const timeslot of this.shippingDetails.dates.timeslots) {

            if (timeslot.dayNumber == number) {
                if (timeslot.block.length > 0) {
                    if ((parseInt(timeslot.dayNumber) < 12 && timeslot.month == 'augustus') ||
                    (parseInt(timeslot.dayNumber) > 28 && timeslot.month == 'juli')){
                        return false;
                    }
                    return timeslot;
                }
                return false;
            }
        }
        return false;
    }

    public scrollDays(side) {
        let calendar = document.getElementById('calendar');
        let scrollAmount = window.innerWidth < 800 ? window.innerWidth - 200 : 600;
        scrollAmount =
            side === 'left' ? calendar.scrollLeft + scrollAmount : calendar.scrollLeft - scrollAmount;
        calendar.scroll({ left: scrollAmount, behavior: 'smooth' });
    }

    public async confirmOrder() {
        if (!this.addressValid) return;
        if (
            !this.shippingDetails.address.address ||
            !this.shippingDetails.address.zipcode ||
            !this.shippingDetails.address.city
        ) {
            this.toaster.error('Adres niet volledig ingevuld');
            return;
        }
        await this.productService.confirmOrder(
            this.selectedTab === 'Bezorgweek'
                ? parseInt(this.shippingWeek)
                : this.getDayByNumber(this.selectedShippingDay).fulldate,
            this.shippingDetails['address'],
            this.extras
        );
        this.productService.cart = [];
        this.productService.setCart();
        this.router.navigateByUrl('bestelling/bevestigd');
    }

    private getDays() {
        let day = 0;
        while (day <= 21) {
            this.shippingDays.push({
                number: dayjs().add(day, 'day').format('DD'),
                letters: dayjs().add(day, 'day').locale('nl').format('dd'),
            });
            day++;
        }
    }

    private selectFirstShippingDay() {
        for (let day of this.shippingDays) {
            if (this.getDayByNumber(day.number)) {
                this.selectedShippingDay = day.number;
                this.selectedShippingOption = this.getDayByNumber(day.number).block[0];
                return;
            }
        }
    }

    private sortByWeek(weeks) {
        return weeks.sort((a, b) => {
            if (a > dayjs().week() && b > dayjs().week()) {
                return a - b;
            } else if (a < dayjs().week() && b < dayjs().week()) {
                return a - b;
            } else if (a > dayjs().week()) {
                return -1;
            } else {
                return 1;
            }
        });
    }
}
