import { Component, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'sPopup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.less'],
})
export class PopupComponent {
    @Input() hideCloseButton: boolean = false;

    public constructor(private erf: ElementRef) {}

    public closePopup(): void {
        this.erf.nativeElement.classList.add('hide');
        document.body.style.height = '';
        document.body.style.overflow = '';
    }
}
