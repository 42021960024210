import { Component, Input, OnInit } from '@angular/core';
import { Slide } from './carousel.interface';
import { trigger, transition, useAnimation } from '@angular/animations';

import {
    AnimationType,
    scaleIn,
    scaleOut,
    slideIn,
    slideOut,
    slideReverseIn,
    slideReverseOut,
    fadeIn,
    fadeOut,
    flipIn,
    flipOut,
    jackIn,
    jackOut,
} from './carousel.animations';

@Component({
    selector: 'sCarousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.less'],
    animations: [
        trigger('slideAnimation', [
            /* scale */
            transition('void => scale', [useAnimation(scaleIn, { params: { time: '500ms' } })]),
            transition('scale => void', [useAnimation(scaleOut, { params: { time: '500ms' } })]),

            /* slide */
            transition('void => slide', [useAnimation(slideIn, { params: { time: '500ms' } })]),
            transition('slide => void', [useAnimation(slideOut, { params: { time: '500ms' } })]),

            /* slideReverse */
            transition('void => slideReverse', [useAnimation(slideReverseIn, { params: { time: '500ms' } })]),
            transition('slideReverse => void', [
                useAnimation(slideReverseOut, { params: { time: '500ms' } }),
            ]),

            /* fade */
            transition('void => fade', [useAnimation(fadeIn, { params: { time: '500ms' } })]),
            transition('fade => void', [useAnimation(fadeOut, { params: { time: '500ms' } })]),

            /* flip */
            transition('void => flip', [useAnimation(flipIn, { params: { time: '500ms' } })]),
            transition('flip => void', [useAnimation(flipOut, { params: { time: '500ms' } })]),

            /* JackInTheBox */
            transition('void => jackInTheBox', [useAnimation(jackIn, { params: { time: '700ms' } })]),
            transition('jackInTheBox => void', [useAnimation(jackOut, { params: { time: '700ms' } })]),
        ]),
    ],
})
export class CarouselComponent implements OnInit {
    @Input() slides: Slide[];
    @Input() animationType = AnimationType.Scale;

    public currentSlide = 0;

    constructor() {}

    ngOnInit() {
        this.preloadImages();
    }

    public onPreviousClick() {
        if (this.animationType == AnimationType.Slide) {
            this.animationType = AnimationType.SlideReverse;
            // timeout to prevent weird animation
            setTimeout(() => {
                const previous = this.currentSlide - 1;
                this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
            });
            return;
        }
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    }

    public onNextClick() {
        if (this.animationType == AnimationType.SlideReverse) {
            this.animationType = AnimationType.Slide;
            setTimeout(() => {
                const next = this.currentSlide + 1;
                this.currentSlide = next === this.slides.length ? 0 : next;
            });
            return;
        }
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.slides.length ? 0 : next;
    }

    private preloadImages() {
        for (const slide of this.slides) {
            new Image().src = slide.src;
        }
    }
}
