import { CrudModel } from './crud/crud.model';

export class LanguageModel extends CrudModel<LanguageModel> {
    languageId: number;
    description: string;
    active: boolean;
    default: boolean;
    iso: string;
    isoCode: string;

    public readonly excluded: string[] = [];
}
