import { Directive, HostListener } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UtilService } from 'src/app/core/services/util.service';

@Directive({
    selector: '[zipcodeValidation]',
})
export class ZipcodeValidationDirective {
    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $target.value;
        if (!this.zipcodeValidation(value)) {
            this.util.addClass($target, 'error');
            this.validation.errors.zipcodeValidation = true;
        } else if (this.zipcodeValidation(value)) {
            this.util.removeClass($target, 'error');
            this.validation.errors.zipcodeValidation = false;
        }
    }

    public constructor(private validation: ValidationService, private util: UtilService) {}

    /**
     * zipcodeValidation
     *
     * checks if the input is a postal code
     */
    private zipcodeValidation(value: any): boolean {
        return typeof value === 'string' && /^(\d{4})(\s?)([a-zA-Z]{2})$/.test(value) && value != '';
    }
}
