import { NgModule } from '@angular/core';
import { PaginatorComponent } from './paginator.component';
import { PopupModule } from '../popup/popup.module';
import { TranslationModule } from '../../../modules/translate.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ItemModule } from '../item/item.module';
import { FormModule } from '../form/form.module';

@NgModule({
    declarations: [PaginatorComponent],
    imports: [CommonModule, RouterModule, TranslationModule, PopupModule, ItemModule, FormModule],
    exports: [PaginatorComponent],
})
export class PaginatorModule {}
