import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';
import { ToasterService } from '../../../core/services/toaster.service';

@Component({
    selector: 'activate',
    template: '',
})
export class ActivateComponent implements OnInit {
    private hash: string;

    public constructor(
        private authenticate: AuthenticateService,
        private route: ActivatedRoute,
        private toast: ToasterService,
        private router: Router
    ) {
        this.route.params.subscribe((params) => {
            this.hash = params['hash'];
        });
    }

    async ngOnInit() {
        const result = await this.authenticate.activateUser(this.hash);
        if (result['success'] === 0) {
            this.toast.error('De ingevoerde hash is niet geldig');
        } else {
            this.toast.success('Account geactiveerd');
        }
        await this.router.navigate(['/login']);
    }
}
