import {Injectable} from '@angular/core';
import {EmptyConfig} from './empty-config.enum';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    public constructor() {
    }

    /**
     * Creates an element
     */
    public createElement(type: string, className: string, content: string): HTMLElement {
        const $element: HTMLElement = document.createElement(type);
        $element.innerHTML = content;
        $element.className = className;

        return $element;
    }

    /**
     * Return an element by a selector
     */
    public getElement(selector: string): HTMLElement {
        if (selector.indexOf('.') >= 0) {
            return <HTMLElement>document.getElementsByClassName(selector.replace('.', ''))[0];
        } else {
            return document.getElementById(selector.replace('#', ''));
        }
    }

    /**
     * Return an element by a selector
     */
    public getElements(selector: string): HTMLElement[] {
        return <HTMLElement[]>Array.from(document.getElementsByClassName(selector.replace('.', '')));
    }

    /**
     * Return the parent of element with tag name.
     */
    public getParentByTagName($element: HTMLElement, tagname: string): HTMLElement {
        let $parent: HTMLElement;
        if ($element == null || tagname === '') {
            return;
        }
        $parent = <HTMLElement>$element.parentNode;
        tagname = tagname.toUpperCase();

        while ($parent.tagName !== 'HTML') {
            if ($parent.tagName === tagname) {
                return $parent;
            }
            $parent = <HTMLElement>$parent.parentNode;
        }

        return $parent;
    }

    /**
     * Return a specific child element by its parent element
     */
    public getChild($parentElement: HTMLElement, child: string): HTMLElement {
        for (let i: number = 0; i < $parentElement.childNodes.length; i++) {
            const $childNode: HTMLElement = <HTMLElement>$parentElement.childNodes[i];
            if ($childNode.className != null && $childNode.className.indexOf(child.replace('.', '')) >= 0) {
                return $childNode;
            }
        }
    }

    /**
     * Return a parent element with a specific class
     */
    public findParentWithClass($element: HTMLElement, className: string): HTMLElement {
        while (($element = $element.parentElement) && !$element.classList.contains(className)) {
        }
        return $element;
    }

    /**
     * Return a child elements with a specific class
     */
    public findChildrenWithClass($element: HTMLElement, className: string): Array<HTMLElement> {
        let childrenWithClass: Array<HTMLElement> = [];
        const children: HTMLCollection = $element.children;

        for (let i: number = 0; i < children.length; i++) {
            const child: HTMLElement = <HTMLElement>children[i];
            if (child.classList.contains(className)) {
                childrenWithClass.push(child);
            } else if (child.children) {
                childrenWithClass = childrenWithClass.concat(this.findChildrenWithClass(child, className));
            }
        }

        return childrenWithClass;
    }

    /**
     * OUTDATED DON'T USE
     * YOU CAN SIMPLIFY THIS
     *
     * Will add a class from an element
     *
     * @deprecated
     */
    public addClass($element: HTMLElement, className: string): void {
        $element.classList.add(className);
    }

    /**
     * OUTDATED DON'T USE
     * YOU CAN SIMPLIFY THIS
     *
     * Will remove a class from an element
     *
     * @deprecated
     */
    public removeClass($element: HTMLElement, className: string): void {
        $element.classList.remove(className);
    }

    /**
     * getOffset
     *
     * Calculates the top and left offset recursive
     */
    public getOffset($element: HTMLElement): { top: number; left: number } {
        let x: number = 0;
        let y: number = 0;
        while ($element && !isNaN($element.offsetLeft) && !isNaN($element.offsetTop)) {
            x += $element.offsetLeft - $element.scrollLeft;
            y += $element.offsetTop - $element.scrollTop;
            $element = <HTMLElement>$element.offsetParent;
        }
        return {top: y, left: x};
    }

    /**
     * sprintf
     *
     * pretty print strings with arguments.
     */
    public sprintf(str: string, args: any): string {
        let i: number = 0;
        return str.replace(/%((%)|s|d)/g, (match) => {
            let val: string | number = null;
            if (match[2]) {
                val = match[2];
            } else {
                val = args[i];
                switch (match) {
                    case '%d':
                        val = parseFloat(String(val));
                        if (isNaN(val)) {
                            val = 0;
                        }
                        break;
                }
                i++;
            }
            return String(val);
        });
    }

    public empty(variable: any, emptyConfig: EmptyConfig = EmptyConfig.All): boolean {
        if (emptyConfig == EmptyConfig.All) {
            return (
                variable === null ||
                variable === undefined ||
                (this.isString(variable) && variable.trim() == '')
            );
        }

        if (
            (emptyConfig & EmptyConfig.EmptyString) == EmptyConfig.EmptyString &&
            this.isString(variable) &&
            variable.trim() == ''
        ) {
            return true;
        }
        if ((emptyConfig & EmptyConfig.Null) == EmptyConfig.Null && variable === null) {
            return true;
        }
        return (emptyConfig & EmptyConfig.Undefined) == EmptyConfig.Undefined && variable === undefined;
    }

    /**
     * Returns if a value is a string
     */
    public isString(variable: any): boolean {
        return typeof variable === 'string' || variable instanceof String;
    }

    /**
     * show the right popup
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     * @deprecated There now is a sItem popup
     */
    public showPopup(popupName: string | HTMLElement): void {
        const popup: HTMLElement =
            typeof popupName === 'string' ? this.getElement('#' + popupName) : popupName;
        popup.classList.remove('hide');

        document.body.style.height = '100%';
        document.body.style.overflow = 'hidden';
    }

    /**
     * Compares two strings and returns accuracy of the match out of 100
     *
     * @author    Joery Hoegee <joery@safira.nl>
     * @param     inputString
     * @param     compareString
     * @param     exactMatch
     * @return    Number
     */
    public getMatchAccuracy(inputString: string, compareString: string, exactMatch: boolean = false): Number {
        // clean input and comparison for better accuracy if exactMatch is disabled
        if (!exactMatch) {
            inputString = inputString.toLowerCase().trim();
            compareString = compareString.toLowerCase();
        }

        if (compareString.includes(inputString)) {
            return 100;
        }

        if (this.empty(inputString) || this.empty(compareString)) {
            return 0;
        }

        // two rows
        const prevRow = new Array(compareString.length + 1);

        // initialise previous row
        for (var i = 0; i < prevRow.length; ++i) {
            prevRow[i] = i;
        }

        // calculate current row distance from previous row
        for (i = 0; i < inputString.length; ++i) {
            var nextCol = i + 1;

            for (var j = 0; j < compareString.length; ++j) {
                const curCol = nextCol;

                // substution
                nextCol = prevRow[j] + (inputString.charAt(i) === compareString.charAt(j) ? 0 : 1);
                // insertion
                let tmp = curCol + 1;
                if (nextCol > tmp) {
                    nextCol = tmp;
                }
                // deletion
                tmp = prevRow[j + 1] + 1;
                if (nextCol > tmp) {
                    nextCol = tmp;
                }

                // copy current col value into previous (in preparation for next iteration)
                prevRow[j] = curCol;
            }

            // copy last col value into previous (in preparation for next iteration)
            prevRow[j] = nextCol;
        }

        // returns rounded percentage with no negative number
        return Math.max(0, Math.round(100 - (nextCol / compareString.length) * 100));
    }
}
