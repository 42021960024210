import { GeoInfoComponent } from './interaction/geolocation/geo-info/geo-info.component';
import { GeoOverviewComponent } from './interaction/geolocation/geo-overview/geo-overview.component';
import { ExcelReadComponent } from './media/excel/excel-read/excel-read.component';
import { ExcelWriteComponent } from './media/excel/excel-write/excel-write.component';
import { CarouselCardComponent } from './interaction/carousel-card/carousel-card.component';
import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ColorsComponent } from './basic/colors/colors.component';
import { DragDropComponent } from './interaction/drag-en-drop/drag-en-drop.component';
import { FormsInputComponent } from './basic/forms-input/forms-input.component';
import { CropperComponent } from '../../core/components/cropper/cropper.component';
import { SearchComponent } from './basic/searchbox/searchbox.component';
import { DemoTableListComponent } from './basic/demo-table/demo-table-list/demo-table-list.component';
import { ExcelComponent } from './media/excel/excel/excel.component';
import { AuthGuardService } from '../../core/services/auth/auth-guard.service';
import { SMapsComponent } from '../../core/components/s-maps/s-maps.component';
import { BlocksComponent } from './blocks.component';
import { WebcamPhotoComponent } from './media/webcam-and-photo/webcam-and-photo.component';
import { BlocksBarcodeComponent } from './media/blocks-barcode/blocks-barcode.component';
import { ZipDownloadComponent } from './media/zip-download/zip-download.component';
import { BlocksBasicComponent } from './basic/blocks-basic.component';
import { BlocksInteractionComponent } from './interaction/blocks-interaction.component';
import { BlocksMediaComponent } from './media/blocks-media.component';
import { BlocksSignatureComponent } from './interaction/blocks-signature/blocks-signature.component';
import { BlocksWorkPlannerComponent } from './interaction/blocks-work-planner/blocks-work-planner.component';

export const blocksRoutes: Routes = [
    {
        path: 'blocks',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: BlocksComponent,
            },
            {
                path: 'basic',
                children: [
                    {
                        path: '',
                        component: BlocksBasicComponent,
                    },
                    {
                        path: 'colors',
                        component: ColorsComponent,
                    },
                    {
                        path: 'forms-input',
                        component: FormsInputComponent,
                    },
                    {
                        path: 'table',
                        component: DemoTableListComponent,
                    },
                    {
                        path: 'search',
                        component: SearchComponent,
                    },
                ],
            },
            {
                path: 'interaction',
                children: [
                    {
                        path: '',
                        component: BlocksInteractionComponent,
                    },
                    {
                        path: 'carousel',
                        component: CarouselCardComponent,
                    },
                    {
                        path: 'drag-drop',
                        component: DragDropComponent,
                    },
                    {
                        path: 'signature',
                        component: BlocksSignatureComponent,
                    },
                    {
                        path: 'work-planner',
                        component: BlocksWorkPlannerComponent,
                    },
                    {
                        path: 'geolocation',
                        children: [
                            {
                                path: '',
                                component: GeoOverviewComponent,
                            },
                            {
                                path: 's-maps',
                                component: SMapsComponent,
                            },
                            {
                                path: 'geo-info',
                                component: GeoInfoComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'media',
                children: [
                    {
                        path: '',
                        component: BlocksMediaComponent,
                    },
                    {
                        path: 'barcode',
                        component: BlocksBarcodeComponent,
                    },
                    {
                        path: 'cropper',
                        component: CropperComponent,
                    },
                    {
                        path: 'webcam-photo',
                        component: WebcamPhotoComponent,
                    },
                    {
                        path: 'zip-download',
                        component: ZipDownloadComponent,
                    },

                    {
                        path: 'excel',
                        children: [
                            {
                                path: '',
                                component: ExcelComponent,
                            },
                            {
                                path: 'read',
                                component: ExcelReadComponent,
                            },
                            {
                                path: 'write',
                                component: ExcelWriteComponent,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
export const blocksRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(blocksRoutes);
