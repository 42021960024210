import { CrudModel } from './crud/crud.model';

export class ModuleModel extends CrudModel<ModuleModel> {
    itemModuleId: number;
    title: string;
    descriptionTitle: string;
    description: string;
    active: boolean;
    icon: string;
    order: number;
    editWidth: number;

    public readonly excluded: string[] = [];
}
