import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowItem, TextItem } from '../../../core/models/item.model';
import { LanguageModel } from '../../../core/models/language.model';
import { TableColumn } from '../../../core/models/table.model';
import { Form } from 'src/app/core/models/form-item.model';
import { UtilService } from '../../../core/services/util.service';
import { Breakpoint, ResponsiveService } from '../../../core/services/responsive.service';
import { BreadcrumbService } from '../../../core/services/breadcrumb-service.service';
import { LanguageService } from '../../../core/services/language.service';
import { TableService } from '../../../core/services/table.service';

@Component({
    selector: 'language',
    templateUrl: './language.component.html',
})
export class LanguageComponent {
    button: TextItem;

    navigation: TextItem = {
        type: 'text',
        isButton: true,
        text: ['global_manage', ' ', 'itemTranslation.translation'],
        action: { type: 'route', route: '../translations' },
    };

    private language: LanguageModel;

    constructor(
        public util: UtilService,
        public breadCrumb: BreadcrumbService,
        private tableService: TableService,
        private languageService: LanguageService,
        private responsive: ResponsiveService
    ) {}

    async ngOnInit() {
        this.breadCrumb.pushPage('itemMenu.translations');
        this.setCreateButton();

        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [], row: [Breakpoint.MD] },
            },
            this.mapColumns.bind(this),
            this.mapRows.bind(this),
            await this.languageService.getAll()
        );

        this.tableService.addSearches([{ searchOn: 'description' }, { searchOn: 'isoCode' }]);
        this.tableService.addFilter([{ filterOn: 'active' }]);

        this.languageService.getDefault();
    }

    ngOnDestroy() {
        this.breadCrumb.popPage();
    }

    private mapColumns(): TableColumn[] {
        return [
            {
                item: { type: 'text', text: 'itemTranslation.description' },
                sortOn: 'description',
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 45,
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.iso_code' },
                sortOn: 'isoCode',
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 45,
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.active' },
                position: 'center',
                sortOn: 'active',
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
            {
                item: { type: 'text', text: 'itemTranslation.actions' },
                position: 'center',
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
        ];
    }

    /**
     * Map users to table format
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private mapRows(language: LanguageModel): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                colspan: 2,
                gap: 10,
                closedHeight: 16.8,
                openHeight: 83.6,
            },
            items: [
                { type: 'text', fontWeight: 600, text: language.description, expansion: 'hidden' },
                {
                    type: 'group',
                    gap: 6,
                    expansion: 'expandable',
                    breakpoint: { breakpoint: Breakpoint.MD, state: 'until' },
                    items: [
                        { type: 'text', fontWeight: 600, text: language.description },
                        { type: 'bool', bool: language.active, show: !language.default },
                        { type: 'text', icon: 'badge-check', color: 'success', show: language.default },
                    ],
                },
                {
                    type: 'text',
                    text: language.isoCode,
                },
                {
                    type: 'bool',
                    bool: language.active,
                    position: 'center',
                    icon: language.default ? 'badge-check' : 'check',
                    expansion: 'hidden',
                    breakpoint: { breakpoint: Breakpoint.MD, state: 'after' },
                },
                {
                    type: 'group',
                    position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                    expansion: 'expandable',
                    items: [
                        {
                            type: 'text',
                            icon: 'badge-check',
                            position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                            iconHover: true,
                            iconBorder: true,
                            hidden: language.default,
                            show: !language.default || this.responsive.isSize(Breakpoint.MD),
                            title: 'itemTranslation.set_default',
                            breakpoint: { breakpoint: Breakpoint.MD, state: 'after' },
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'text',
                                    text: ['itemTranslation.set_default'],
                                },
                                items: [{ type: 'text', text: 'itemTranslation.explain_set_default' }],
                                form: {
                                    routeTo: './',
                                    submitLabel: 'itemTranslation.submit_set_default',
                                    inputs: [],
                                    submit: async () => {
                                        await this.languageService.setDefault(language);
                                        this.tableService.setObjects(await this.languageService.getAll());
                                        await this.languageService.getAllActive();
                                        this.languageService.languageChange.next(
                                            this.languageService.current
                                        );
                                        return true;
                                    },
                                },
                            },
                        },
                        {
                            type: 'text',
                            icon: 'pen',
                            position: this.responsive.isSize(Breakpoint.MD) ? 'center' : 'left',
                            iconHover: true,
                            iconBorder: true,
                            title: 'itemTranslation.edit',
                            breakpoint: { breakpoint: Breakpoint.MD, state: 'after' },
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'text',
                                    text: ['itemTranslation.edit', ' ', language.description],
                                },
                                form: this.getLanguageFrom(language),
                            },
                        },
                        {
                            type: 'text',
                            icon: 'trash-alt',
                            iconBorder: true,
                            iconHover: true,
                            hidden: language.default || this.languageService.languages.length <= 1,
                            title: 'itemTranslation.delete',
                            action: {
                                type: 'popup',
                                title: {
                                    type: 'text',
                                    text: ['itemTranslation.delete', ' ', language.description],
                                },
                                form: {
                                    routeTo: './',
                                    submitLabel: 'global.global_delete',
                                    inputs: [
                                        {
                                            type: 'form',
                                            inputType: 'checkbox',
                                            text: 'itemTranslation.confirm_delete',
                                            name: 'confirm',
                                            data: null,
                                            required: true,
                                        },
                                    ],
                                    submit: async (data: any, formData: Map<string, any>) => {
                                        if (formData.get('confirm')) {
                                            await this.languageService.delete(language);
                                            this.tableService.setObjects(await this.languageService.getAll());
                                            await this.languageService.getAllActive();
                                            return true;
                                        }
                                        return false;
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    }

    private setCreateButton() {
        this.language = new LanguageModel(this.languageService.save.bind(this.languageService));
        this.button = {
            type: 'text',
            text: ['global_create', ' ', 'itemTranslation.language'],
            isButton: true,
            action: {
                type: 'popup',
                title: { type: 'text', text: ['global_create', ' ', 'itemTranslation.language'] },
                form: this.getLanguageFrom(this.language),
            },
        };
    }

    private getLanguageFrom(language: LanguageModel): Form {
        return {
            routeTo: './',
            inputs: [
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'description',
                    data: language.description,
                    name: 'description',
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'iso',
                    data: language.iso,
                    name: 'iso',
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'text',
                    text: 'iso_code',
                    data: language.isoCode,
                    name: 'isoCode',
                    required: true,
                },
                {
                    type: 'form',
                    inputType: 'checkbox',
                    show: !language.default,
                    text: 'active',
                    data: language.active,
                    name: 'active',
                },
            ],
            submit: async (data: any, formData: Map<string, any>): Promise<boolean> => {
                if (formData.get('description') === null || formData.get('description').length <= 3) {
                    return false;
                }
                if (formData.get('iso') === null || formData.get('iso').length <= 1) {
                    return false;
                }
                if (formData.get('isoCode') === null || formData.get('isoCode').length <= 4) {
                    return false;
                }
                if (formData.get('active') === null) {
                    return false;
                }
                language.description = formData.get('description');
                language.isoCode = formData.get('isoCode');
                language.active = formData.get('active');
                await this.languageService.save(new LanguageModel(null, language));
                this.setCreateButton();
                this.tableService.setObjects(await this.languageService.getAll());
                await this.languageService.getAllActive();
                this.languageService.languageChange.next(this.languageService.current);
                return true;
            },
        };
    }
}
