import { GeoInfoComponent } from './interaction/geolocation/geo-info/geo-info.component';
import { GeoOverviewComponent } from './interaction/geolocation/geo-overview/geo-overview.component';
import { DemoTableModule } from './basic/demo-table/demo-table.module';
import { CarouselCardComponent } from './interaction/carousel-card/carousel-card.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ColorsComponent } from './basic/colors/colors.component';
import { FormsInputComponent } from './basic/forms-input/forms-input.component';
import { TranslationModule } from '../translate.module';
import { DragDropComponent } from './interaction/drag-en-drop/drag-en-drop.component';
import { SearchComponent } from './basic/searchbox/searchbox.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ExcelModule } from './media/excel/excel.module';
import { SMapModule } from '../../core/components/s-maps/s-map.module';
import { DndModule } from '../../core/directives/dnd/dnd.module';
import { ToasterModule } from '../../core/components/toaster/toaster.module';
import { ValidationModule } from '../../core/directives/validation/validation.module';
import { FormModule } from '../../core/components/form/form.module';
import { BlocksComponent } from './blocks.component';
import { WebcamPhotoComponent } from './media/webcam-and-photo/webcam-and-photo.component';
import { ZipDownloadComponent } from './media/zip-download/zip-download.component';
import { blocksRouting } from './blocks.routes';
import { BarcodeComponent } from '../../core/components/barcode/barcode.component';
import { BarcodeScannerComponent } from '../../core/components/barcode-scanner/barcode-scanner.component';
import { BlocksBarcodeComponent } from './media/blocks-barcode/blocks-barcode.component';
import { QrcodeModule } from '../../core/components/qrcode/qrcode.module';
import { BlocksBasicComponent } from './basic/blocks-basic.component';
import { BlocksInteractionComponent } from './interaction/blocks-interaction.component';
import { BlocksMediaComponent } from './media/blocks-media.component';
import { BlocksSignatureComponent } from './interaction/blocks-signature/blocks-signature.component';
import { BlocksWorkPlannerComponent } from './interaction/blocks-work-planner/blocks-work-planner.component';
import { WorkPlannerModule } from '../../core/components/workplanner/workplanner.module';
import { CalendarComponent } from '../../core/components/delivery-calendar/delivery-calendar.component';
import { CardsearchComponent } from '../../core/components/cardsearch/cardsearch.component';
import { ShareComponent } from '../../core/components/share/share.component';
import { DragdropModule } from '../../core/components/dragdrop/dragdrop.module';

@NgModule({
    declarations: [
        CardsearchComponent,
        CalendarComponent,
        BlocksComponent,
        ColorsComponent,
        DragDropComponent,
        FormsInputComponent,
        SearchComponent,
        WebcamPhotoComponent,
        ZipDownloadComponent,
        CarouselCardComponent,
        GeoOverviewComponent,
        GeoInfoComponent,
        BarcodeComponent,
        BlocksSignatureComponent,
        BlocksWorkPlannerComponent,
        BarcodeScannerComponent,
        BlocksBarcodeComponent,
        BlocksBasicComponent,
        BlocksInteractionComponent,
        BlocksMediaComponent,
        ShareComponent,
    ],
    imports: [
        blocksRouting,
        CommonModule,
        FormsModule, //required for [(ngModel)]
        FormModule,
        DndModule,
        TranslationModule,
        ValidationModule,
        DashboardModule,
        DemoTableModule,
        SMapModule,
        ExcelModule,
        QrcodeModule,
        WorkPlannerModule,
        DragdropModule,
    ],
    exports: [ToasterModule],
})
export class BlocksModule {}
