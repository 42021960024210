import { Component } from '@angular/core';
import { UploadService } from '../../../../core/services/upload.service';

@Component({
    selector: 'zip-download',
    templateUrl: './zip-download.component.html',
})
export class ZipDownloadComponent {
    constructor(private uploadService: UploadService) {}

    downloadZip() {
        this.uploadService.getFilesZip('files/downloadSampleZip');
    }
}
