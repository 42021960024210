import { Component } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb-service.service';

@Component({
    selector: 'sBreadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.less'],
})
export class BreadcrumbComponent {
    showAll: boolean;

    constructor(public breadcrumb: BreadcrumbService) {}
}
