import { Directive, OnInit, NgZone } from '@angular/core';

declare var $R: any;

@Directive({
    selector: '[Redactor]',
})
export class RedactorDirective implements OnInit {
    public constructor(private zone: NgZone) {}

    public ngOnInit(): void {
        this.zone.runOutsideAngular(() => {
            $R('.redactor', {
                lang: 'nl',
                formatting: ['p', 'h1', 'h2', 'h3', 'h4'],
                buttons: ['format', 'bold', 'italic', 'underline', 'lists', 'link'],
                buttonsTextLabeled: false, //true: text only + false: icon only
                toolbarFixed: false,
                minHeight: '100px',
                maxHeight: '300px',
                structure: true,
                preClass: 'prettyprint linenums',
                plugins: ['alignment', 'table', 'fontsize', 'fontcolor', 'clips'],
                clips: [
                    [
                        'Lorem ipsum voorbeeld tekst',
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                    ],
                    ['Link knop', '<a rel="button" href="#">Knop tekst</a>'],
                ],
            });
        });
    }
}
