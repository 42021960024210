import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabScrollDirective } from './tab-scroll.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [TabScrollDirective],
    exports: [TabScrollDirective],
})
export class TabScrollModule {}
