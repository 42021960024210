import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginatorService } from '../../services/paginator.service';
import { item, TextItem } from '../../models/item.model';
import { Breakpoint, ResponsiveService } from '../../services/responsive.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sPaginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.less'],
})
export class PaginatorComponent implements OnInit, OnDestroy {
    button: TextItem;

    private readonly onPageNumbers: number[] = [10, 25, 50, 100, 250];
    private totalItems: number;
    private subscriptions: Subscription[] = [];

    constructor(public paginator: PaginatorService, private responsive: ResponsiveService) {
        this.setButton();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.paginator.paginationChanged.subscribe(() => {
                this.setButton();
            }),
            this.responsive.subscribeOnBreakpoints([Breakpoint.XS], this.setButton.bind(this)),
        );
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (subscription !== undefined) {
                subscription.unsubscribe();
            }
        }

        this.subscriptions = [];
    }

    setButton() {
        if (this.totalItems === undefined) {
            this.totalItems = this.paginator.totalItems;
        }

        const pageItems: item[] = [];

        for (const onPage of this.onPageNumbers) {
            if (
                onPage === this.paginator.itemsPerPage ||
                onPage > this.totalItems ||
                (onPage < 0 && this.paginator.itemsPerPage === this.totalItems)
            ) {
                continue;
            }

            pageItems.push({
                type: 'text',
                padding: '8px 16px',
                text: [onPage < 0 ? 'global_all' : onPage, ' ', 'global_items'],
                action: {
                    type: 'click',
                    data: onPage,
                    click: () => {
                        this.paginator.setItemsPerPage(onPage);
                    },
                },
            });
        }

        if (pageItems.length < 1) {
            return;
        }

        this.button = {
            type: 'text',
            text: this.paginator.itemsPerPage,
            action: {
                type: 'dropdown',
                width: this.responsive.isSize(Breakpoint.XS) ? 'full' : 'relative',
                indicator: {},
                openOnHover: true,
                items: pageItems,
            },
        };
    }
}
