export abstract class ApiModel<Model> {
    private static readonly WORD_DELIMITER = '_';

    public changed: boolean = false;
    public deleted: boolean = false;

    public created: string;
    public updated: string;

    protected abstract readonly excluded: string[];

    protected constructor(input?: Object) {
        this.deserialize(input);
    }

    /**
     * This method will turn any implementation of ApiModel into a plain object
     * Works recursively: Any child ApiModel implementations will be serialized as well
     *
     * @lastEditedBy    Lars Meeuwsen <lars@safira.nl>
     * @author          Mika Schipper <mika@safira.nl>
     */
    serialize(object: ApiModel<Model> = this, excluded: string[] = this.excluded): Object {
        this.excluded.push('save', 'changed', 'updated', 'created');
        let result = {};

        // Loop through all the keys of object
        Object.keys(object).forEach((key) => {
            // Check if the property name is not in our exclusion array, or if it's the exclusion array itself
            if (excluded.indexOf(key) >= 0 || key === 'excluded') {
                return;
            }
            // Check if the current object is an array
            if (Array.isArray(object[key])) {
                // Whatever turns out to be in the array, we want to start with an empty one
                result[key] = [];
                // Cast current object to array and loop through it
                (object[key] as any[]).forEach((obj) => {
                    // Check if the current object inside the array could be an ApiModel
                    if (this.isSerializable(obj)) {
                        // Cast our newly made property to an array in order to be able to push stuff into it
                        // Next, cast the current object inside the array to ApiModel, and serialize it (woo, recursion)
                        (result[key] as any[]).push((obj as ApiModel<any>).serialize());
                    }
                        // If current object inside array cannot be an ApiModel, add the object to the new
                    // array and hope for the best
                    else {
                        (result[key] as any[]).push(obj);
                    }
                });
            }
            // If not an array, check if the current object could be a single ApiModel
            else if (this.isSerializable(object[key])) {
                // Cast current object to ApiModel, serialize it, and add it to the result object
                result[key] = this.serialize(object[key], object[key]['excluded']);
            }
            // If neither an array or an ApiModel, add the current object to the result object without touching it
            else {
                result[key] = object[key];
            }
        });
        // Return the newly made plain JS object
        return result;
    }

    /**
     * Contains the default deserialization logic
     * Converts snake_case database fields to camelCase
     *
     * @lastEditedBy    Lars Meeuwsen <lars@safira.nl>
     * @author          Mika Schipper <mika@safira.nl>
     */
    protected deserialize(input: Object = {}): void {
        for (const key of Object.keys(input)) {
            if (
                typeof key !== 'string' ||
                key[0] === ApiModel.WORD_DELIMITER ||
                !key.includes(ApiModel.WORD_DELIMITER)
            ) {
                this[key] = input[key];
                continue;
            }
            let keyParts = key.split(ApiModel.WORD_DELIMITER);
            let newKey = keyParts[0];

            for (let index = 1; index < keyParts.length; index++) {
                newKey += keyParts[index][0].toUpperCase() + keyParts[index].slice(1);
            }
            this[newKey] = input[key];
        }
    }

    /**
     * Checks whether an object can serialized
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    private isSerializable(object: any): boolean {
        return object !== undefined && object !== null && Array.isArray(object['excluded']);
    }
}
