import { formItems } from '../../../models/form-item.model';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'sInputItem',
    templateUrl: './input-item.component.html',
})
export class InputItemComponent {
    @Input() input: formItems;
}
