import { Injectable } from '@angular/core';
import { ToasterService } from './toaster.service';

const pixelsPerCm: number = 37.7952755906;

@Injectable({
    providedIn: 'root',
})
export class PrintModalService {
    /**
     * modalContent
     *
     * Content of the label. HTML can be applied.
     */
    public modalContent: string;

    /**
     * active
     *
     * wether the modal is visible or not
     */
    public active: boolean = false;

    /**
     * modalWidth
     *
     * width of the modal. Standard of 1 centimeter
     */
    public modalWidth: number = 1 * pixelsPerCm;

    /**
     * modalWidthInCm
     *
     * width of the modal, in CM
     */
    public modalWidthInCm: number = 1 * pixelsPerCm;

    /**
     * modalHeight
     *
     * height of the modal. Standard of 1 centimeter
     */
    public modalHeight: number = 1 * pixelsPerCm;

    /**
     * modalHeightInCm
     *
     * height of the modal in CM
     */
    public modalHeightInCm: number = 1 * pixelsPerCm;

    /**
     * actionButtonsWidth
     *
     * Width of the action buttons div. When printing the
     * label we need to reduce the width because
     * the buttons aren't there
     */
    public actionButtonsWidth: number = 225;

    public constructor(private toast: ToasterService) {}

    /**
     * content
     *
     * Sets the content for the label
     */
    public content(content: string): void {
        this.modalContent = content;
    }

    /**
     * open
     *
     * opens the popup
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public open(): void {
        this.active = true;
    }

    /**
     * close
     *
     * Closes the popup
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public close(): void {
        this.active = false;
    }

    /**
     * width
     *
     * Sets the width for the label. Calculates the width in pixels
     * based of centimeters.
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public width(width: number): void {
        var widthInPixels: number = width * pixelsPerCm;
        this.modalWidthInCm = width;
        this.modalWidth = Math.ceil(widthInPixels) + this.actionButtonsWidth; //add 225 for the actions width
    }

    /**
     * height
     *
     * Sets the height for the label. Calculates the height in pixels
     * based of centimeters.
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public height(height: number): void {
        var heightInPixels: number = height * pixelsPerCm;
        this.modalHeightInCm = height;
        this.modalHeight = heightInPixels;
    }

    /**
     * printLabel
     *
     * Prints the label. Used when first showing the modal
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public printLabel(html?: string): void {
        //if no HTML passed we may assume
        //we're using the modal to print
        if (!html) {
            const printContents: string = document.getElementById('modal-content').innerHTML;
            html = printContents;
        }

        const popupWin: Window = window.open(
            '',
            '_blank',
            'scrollbars=0,resizable=0,top=0,left=0,height=' +
                this.modalHeight +
                ',width=' +
                (this.modalWidth - this.actionButtonsWidth)
        );
        popupWin.document.open();
        popupWin.document.write(`
      <html>
        <head>
          <title>Printen</title>
          <style>
          body { padding:10px; font-family: Arial; background: #fff; font-size: @fontSizeHeader; margin: 0; line-height: 31px; color: #333333; }
          p { margin: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
          .before, .after { display:none; }
          </style>
        </head>
      <body onload="window.print();window.close()">${html}</body>
      </html>`);
        popupWin.document.close();

        this.toast.success('Printen gestart');
    }
}
