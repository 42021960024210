import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../services/util.service';
import { LanguageService } from '../services/language.service';

@Injectable()
@Pipe({
    name: 'tableFilter',
    pure: false,
})
export class TableFilterPipe implements PipeTransform {
    public constructor(private language: LanguageService, private util: UtilService) {}

    private matches(language: string, haystack: any, needle: string): boolean {
        if (this.util.empty(haystack)) return false;

        if (haystack === parseInt(needle, 10)) return true;

        const lowerCaseHaystack: string = (String(haystack) as any).toLocaleLowerCase(language);
        const lowerCaseNeedle: string = (needle as any).toLocaleLowerCase(language);

        return lowerCaseHaystack.indexOf(lowerCaseNeedle) !== -1;
    }

    public transform(table: Array<any>, filter: string, ...fields: Array<string>): Array<any> {
        if (table.length === 0 || this.util.empty(filter)) {
            return table;
        }

        const language: string = this.language.current.iso;

        if (fields.length === 0) {
            fields = Object.keys(table[0]);
        }

        return table.filter((row: any): boolean => {
            if (fields.length === 0) {
                return this.matches(language, row, filter);
            }

            return fields.some((field: string): boolean => this.matches(language, row[field], filter));
        });
    }
}
