import { ProductService } from '../../../core/services/product.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'order-overview',
    templateUrl: './order-overview.component.html',
    styleUrls: ['./order-overview.component.less'],
})
export class OrderOverviewComponent implements OnInit {
    public searchValue: string;

    constructor(public productService: ProductService) {}

    async ngOnInit() {
        this.productService.getProductsFromCart();
        await this.productService.getOrderTotalDetails();
    }

    public back() {

    }

    public showCartTotal(): boolean {
        let returnValue = false;
        if (!this.productService.products || this.productService.products.length === 0) return false;
        this.productService.products.forEach((product) => {
            if (+product.amount > 0) returnValue = true;
        });
        return returnValue;
    }

    public getShippingCost(): number {
        return this.productService.totalDetails &&
            this.productService.totalDetails &&
            this.productService.totalDetails.end_amount >= this.productService.getCartTotalWithDiscount()
            ? this.productService.totalDetails.cost
            : 0;
    }
}
