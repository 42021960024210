import { AnimationType } from '../../../../core/components/carousel/carousel.animations';
import { CarouselComponent } from '../../../../core/components/carousel/carousel.component';
import { Slide } from '../../../../core/components/carousel/carousel.interface';
import { Component, ViewChild } from '@angular/core';

@Component({
    selector: 'carousel-card',
    templateUrl: './carousel-card.component.html',
    styleUrls: ['./carousel-card.component.less'],
})
export class CarouselCardComponent {
    @ViewChild(CarouselComponent) carousel: CarouselComponent;

    animationType = AnimationType.Scale;

    animationTypes = [
        {
            name: 'schaal',
            value: AnimationType.Scale,
        },
        {
            name: 'schuif',
            value: AnimationType.Slide,
        },
        {
            name: 'vervaag',
            value: AnimationType.Fade,
        },
        {
            name: 'flip',
            value: AnimationType.Flip,
        },
        {
            name: 'jack_in_de_box',
            value: AnimationType.JackInTheBox,
        },
    ];

    constructor() {}

    setAnimationType(type) {
        this.animationType = type.value;
        setTimeout(() => {
            this.carousel.onNextClick();
        });
    }
    images: Slide[] = [
        {
            headline: 'Gras',
            src: 'assets/gras.jpg',
        },
        {
            headline: 'Penguin',
            src: 'assets/index.jpg',
        },
        {
            headline: 'Auto',
            src: 'assets/car.jpg',
        },
    ];
}
