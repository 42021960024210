import { Component, Input } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'cardsearch',
    templateUrl: './cardsearch.component.html',
    styleUrls: ['./cardsearch.component.less'],
})
export class CardsearchComponent {
    @Input() searchInClass: boolean = false;

    public constructor(private util: UtilService) {}

    /**
     * search
     *
     * Search within an array
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public search(e: KeyboardEvent): void {
        const $element: HTMLInputElement = <HTMLInputElement>e.target;
        const value: string = $element.value;
        const $card: HTMLElement = this.util.findParentWithClass($element, 'cardsearch');
        const $listItems: Array<HTMLElement> = this.util.findChildrenWithClass($card, 'list-item');

        for (let i: number = 0; i < $listItems.length; i++) {
            let keepElement: boolean = false;

            if (this.searchInClass != null) {
                const $children: Array<HTMLElement> = this.util.findChildrenWithClass(
                    $listItems[i],
                    'search-field'
                );

                for (let x: number = 0; x < $children.length; x++) {
                    if (
						$children[x].innerText.toLowerCase().indexOf(value.toLowerCase()) !== -1
                    ) {
                        keepElement = true;
                    }
                }
            } else {
                if ($listItems[i].innerText.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    keepElement = true;
                }
            }

            if (!keepElement) {
                if (!$listItems[i].classList.contains('hide')) {
                    this.util.addClass($listItems[i], 'hide');
                }
            } else {
                if ($listItems[i].classList.contains('hide')) {
                    this.util.removeClass($listItems[i], 'hide');
                }
                keepElement = false;
            }
        }
    }

    /**
     * toggle
     *
     * Toggle the input field
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public toggle(e: Event): void {
        const $element: HTMLElement = <HTMLElement>e.target;
        const $parent: HTMLElement = this.util.findParentWithClass($element, 'card-search-wrapper');
        const childSearchInput: HTMLInputElement = <HTMLInputElement>(
            this.util.getChild($parent, '.card-search-input')
        );
        if (!childSearchInput.classList.contains('active')) {
            this.util.addClass(childSearchInput, 'active');
            childSearchInput.disabled = false;
            childSearchInput.focus();
        } else {
            this.util.removeClass(childSearchInput, 'active');
            childSearchInput.disabled = true;
        }
    }

    /**
     * checkInput
     *
     * check if the input field has a value
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    public checkInput(e: Event): void {
        if ((<HTMLInputElement>e.target).value === '') {
            this.toggle(e);
        }
    }
}
