import { MapMarker } from './MapMarker.model';
import { UtilService } from '../util.service';
import { OpenStreetMapComponent } from '../../components/openstreetmap/openstreetmap.component';
import { HttpClient } from '@angular/common/http';
import { abstractLocation, Address, Coordinates } from './abstractLocation';
import { ComponentRef, Type } from '@angular/core';
import { map } from 'rxjs/operators';

export class openStreetMapsStrategy implements abstractLocation {
    component: Type<any>;
    key: string = 'osm';
    componentRef: ComponentRef<OpenStreetMapComponent>;

    public markers: Array<MapMarker> = [
        {
            longitude: 5.29761,
            latitude: 51.87658,
            title: 'Tennisveld',
            redirectUrl: '/blocks/interaction/geolocation',
            img: '../assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Klik op onderstaande knop om naar de hoofdpagina van geolocatie te navigeren',
        },
        {
            longitude: 5.30562,
            latitude: 51.87579,
            title: 'Rotonde',
            redirectUrl: '',
            img: '../assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Omschrijving',
        },
        {
            longitude: 5.30232,
            latitude: 51.8752,
            title: 'Bouman',
            redirectUrl: '',
            img: '../assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Omschrijving',
        },
        {
            longitude: 5.29858,
            latitude: 51.87539,
            title: 'Weg',
            redirectUrl: '',
            img: '../assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Omschrijving',
        },
    ];

    constructor(public http: HttpClient, private util: UtilService) {
        // link component to strategy;
        this.component = OpenStreetMapComponent;
    }

    geocode(addres: Address): Promise<Coordinates> {
        const url: URL = new URL('https://nominatim.openstreetmap.org/search');
        const searchAddress: string = (
            (!this.util.empty(addres.houseNumber) ? addres.houseNumber.toString(10).trim() : '') +
            (!this.util.empty(addres.houseNumberSuffix) ? addres.houseNumberSuffix.trim() : '') +
            (!this.util.empty(addres.address) ? ' ' + addres.address.trim() : '')
        ).trim();

        url.searchParams.append('format', 'geocodejson');

        if (!this.util.empty(searchAddress)) {
            url.searchParams.append('street', searchAddress.trim());
        }
        if (!this.util.empty(addres.zipcode)) {
            url.searchParams.append('postalcode', addres.zipcode.trim());
        }
        if (!this.util.empty(addres.city)) {
            url.searchParams.append('city', addres.city.trim());
        }
        return this.http
            .get(url.toString())
            .pipe(
                map((value: any): Coordinates => {
                    try {
                        const type: string = value['features'][0]['properties']['geocoding']['type'];
                        if (type === 'house') {
                            return {
                                lat: value['features'][0]['geometry']['coordinates'][1],
                                lon: value['features'][0]['geometry']['coordinates'][0],
                            };
                        }
                    } catch (e) {}

                    return null;
                })
            )
            .toPromise();
    }

    // add information marker to google maps component
    public addMarker(marker: MapMarker): void {
        this.componentRef.instance.addMarkers([marker]);
    }

	public getMarkers(): MapMarker[] {
		return this.componentRef.instance.markers;
	}

    public removeMarker(markerData: MapMarker): void {
        this.componentRef.instance.removeMarker(markerData);
    }

    // set the refence to OSM component
    public setComponentReference(componentRef: ComponentRef<any>): void {
        if (componentRef != null && componentRef.instance instanceof OpenStreetMapComponent) {
            this.componentRef = componentRef;
        }
    }

    public setViewPosition(coordinates: Coordinates): void {
        this.componentRef.instance.setPosition(coordinates);
    }

    public loadComponentData(): void {
        // set default map values;
		this.componentRef.instance.centerLongitude = 5.2979789;
        this.componentRef.instance.centerLatitude = 51.826866;
        this.componentRef.instance.zoomLevel = 15;
        this.componentRef.instance.markers = this.markers;
        this.componentRef.instance.centerOnCurrentPos = true;
        this.componentRef.instance.mapHeight = 710;
    }

	setMapHeight(height: number): void{
		if(this.componentRef){
			this.componentRef.instance.mapHeight = height;
		}
	}
}
