import { Component, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sQuantity',
    templateUrl: './quantity.component.html',
    styleUrls: ['./quantity.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuantityComponent),
            multi: true,
        },
    ],
})
export class QuantityComponent implements ControlValueAccessor {
    //holds the value
    private _value: number = 0;
    public get value(): number {
        return this._value;
    }
    public set value(value: number) {
        this._value = value;
        this.cdRef.detectChanges();
    }

    // The value range
    @Input('minValue') public minValue: number = 0;
    @Input('maxValue') public maxValue: number = Infinity;

    //holds the name of the input
    @Input('name') name: string = '';

    //holds the label of the input
    @Input('label') label: string = '';

    //holds the id of the input
    @Input('id') id: string = '';

    //holds the disabled state
    @Input('disabled') disabled: string = null;

    //holds the registered Forms API functions
    public onChange: (val: any) => void = () => {};
    public onTouched: () => void = () => {};

    public constructor(private cdRef: ChangeDetectorRef) {}

    /**
     * Allows Angular to set this component's value
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public writeValue(value: any): void {
        this.value = value;
    }

    /**
     * Allows Angular to set the onChange hook
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    /**
     * Allows Angular to set the onTouched hook
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Add 1 to this element's value
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public qtyPlus(): void {
        if (!this.canAdd()) return;

        this.value++;

        this.onChange(this.value);
        this.onTouched();
    }

    /**
     * Substract 1 from this component's value
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public qtyMin(): void {
        if (!this.canSubstract()) return;

        this.value--;

        this.onChange(this.value);
        this.onTouched();
    }

    /**
     * If this component's value is more than the minimum
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public canSubstract() {
        return this.value > this.minValue;
    }

    /**
     * If this component's value is less than the maximum
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public canAdd() {
        return this.value < this.maxValue;
    }

    /**
     * When the user changed the value manually
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public changeQty(event: Event) {
        const val: number = Number.parseInt((event.target as HTMLInputElement).value);

        // To update the displayed value properly
        this.value = null;

        this.value = Number.isNaN(val)
            ? Math.max(this.minValue, 0)
            : Math.max(Math.min(val, this.maxValue), this.minValue);
        this.onChange(this.value);
    }
}
