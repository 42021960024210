import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/core/components/header/header.component';
import { PrintModalComponent } from 'src/app/core/components/printmodal/printmodal.component';
import { textareaDirective } from 'src/app/core/components/textarea/textarea.directive';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbModule } from 'src/app/core/components/breadcrumb/breadcrumb.module';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { dashboardRouting } from './dashboard.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TranslationModule } from '../translate.module';
import { TableFilterPipe } from 'src/app/core/pipe/table-filter.pipe';
import { ResetOpcacheComponent } from 'src/app/core/components/reset-opcache/reset-opcache.component';
import { SettingsComponent } from 'src/app/core/components/settings/settings.component';
import { SMapModule } from '../../core/components/s-maps/s-map.module';
import { FooterModule } from 'src/app/core/components/footer/footer.module';

@NgModule({
    declarations: [
        DashboardComponent,
        HeaderComponent,
        PrintModalComponent,
        textareaDirective,
        ResetOpcacheComponent,
        SettingsComponent,
        TableFilterPipe,
    ],
    imports: [
        dashboardRouting,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BreadcrumbModule,
        FormsModule, //required for [(ngModel)]
        FormModule, // Custom Safira form module
        TranslationModule,
        SMapModule,
		FooterModule
    ],
    exports: [TableFilterPipe, ResetOpcacheComponent],
})
export class DashboardModule {}
