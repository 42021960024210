import { RouterModule, Routes } from '@angular/router';
import { DemoTableListComponent } from './demo-table-list/demo-table-list.component';

export const demoTableRoutes: Routes = [
    {
        path: 'clients',
        component: DemoTableListComponent,
        pathMatch: 'full',
        data: {
            subject: 'tableClient',
        },
    },
];

export const demoTableRouting = RouterModule.forChild(demoTableRoutes);
